<section class="sections dark-blue">
  <div class="containers">
<div class="pricingBlue">
    <article>
    <h2>
        Everything your business needs to get it done.
    </h2>
    <p>
        Compare our business pricing plans, request demos and find customer reviews.
    </p>
</article>
<a [routerLink]="['/pricing']">
    Know Our Pricing
</a>
</div>
</div>
  </section>
