<div class="hero-inner support">
  <div class="containers">
  <h1>
    <small>
     <a href="support.html"> Support</a>
    </small>
    Welcome to the OfficeGaps <br> Support Center
  </h1>
 </div>
</div>

<div class="containers">
   <div class="floated-title centered">
       <h2>
           Find solutions, reach out to support, and stay updated
       </h2>
     <p>
       Access complete product documentation to learn how to get started and use OfficeGaps. Find answers to common questions, <br> as well as tips on how to troubleshoot common issues. Discuss problems and solutions with our experts.
     </p>
     </div>
</div>
<section class="sections nptop">
 <div class="sectitle_01">
   <h2>
     Hi, How Can We Help You?
   </h2>
 </div>
 <div class="searchbox">
   <input type="search" placeholder="Search Our Help Center.." name="searchSupport" id="">
   <button type="submit">Search</button>
 </div>
 <div class="containers">
   <div class="sectitle_02 dark">
     <h2>Popular topics</h2>
   </div>
   <div class="supportlist">
     <a  [routerLink]="['/help-center/getting-started']" class="listcolumn">
       <svg>
         <use xlink:href="../../assets/img/sprite.svg#getStart"></use>
       </svg>
       <h4>
         Getting Started
       </h4>
       <p>
         New around here? Here is everything you need to know to set up OfficeGaps for your business and get started right away
       </p>
      </a>
     <a [routerLink]="['/help-center/account-settings']" class="listcolumn">
       <svg>
         <use xlink:href="../../assets/img/sprite.svg#accountSet"></use>
       </svg>
       <h4>
         Account Settings
       </h4>
       <p>
         Check out how to customize your account according to your preferences that best fits your company's needs
       </p>
      </a>
     <a [routerLink]="['/help-center/integrations']" class="listcolumn">
       <svg>
         <use xlink:href="../../assets/img/sprite.svg#integration"></use>
       </svg>
       <h4>
         Integrations
       </h4>
      <p>
       Discover how to connect OfficeGaps to other business applications using APIs and help organizations synchronize data, collaborate more effectively
      </p>
    </a>
     <a [routerLink]="['/help-center/manage-your-team']" class="listcolumn">
       <svg>
         <use xlink:href="../../assets/img/sprite.svg#manageTeam"></use>
       </svg>
       <h4>
         Manage your Team
       </h4>
       <p>
         Learn how to organize your entire team, assign tasks and projects, set permissions, track performance, and collaborate seamlessly in one tool
       </p>
      </a>
     <a [routerLink]="['/help-center/verification']" class="listcolumn">
       <svg>
         <use xlink:href="../../assets/img/sprite.svg#verify"></use>
       </svg>
       <h4>
         Verification
       </h4>
       <p>
         Check out how advanced security features such as facial recognition and Captcha verification can be used to further secure your business data
       </p>
      </a>
     <a [routerLink]="['/help-center/unleashed']" class="listcolumn">
       <svg>
         <use xlink:href="../../assets/img/sprite.svg#unleashed"></use>
       </svg>
       <h4>
         OfficeGaps Unleashed
       </h4>
       <p>
         Access best practices that help businesses improve services, products, security, and productivity while closing deals faster using OfficeGaps
       </p>
      </a>
   </div>

   <div class="moreSupport">
     <div class="resources">
       <div class="sectitle_02 dark">
         <h2>More Resources</h2>
       </div>
       <p>
         Apart from the topics listed above, you can obtain help for your OfficeGaps subscription via many other channels. Additional product information links can be found below, along with a support request form for you to contact our technical support team directly.
       </p>
       <div class="supportCards aqua">
         <span>
           <svg>
             <use xlink:href="../../assets/img/sprite.svg#appStatus"></use>
           </svg>
         </span>
         <article>
           <h4>
             App Status
           </h4>
           <p>
             Monitor and stay informed about performance insights, server downtime and incident reports
           </p>
         </article>
       </div>
       <div class="supportCards blue">
         <span>
           <svg>
             <use xlink:href="../../assets/img/sprite.svg#newFeature"></use>
           </svg>
         </span>
         <article>
           <h4>
             New Features
           </h4>
           <p>
             Find out what's new with our latest updates, upcoming solutions, and additional features!
           </p>
         </article>
       </div>
       <div class="supportCards orange">
         <span>
           <svg>
             <use xlink:href="../../assets/img/sprite.svg#forDevelop"></use>
           </svg>
         </span>
         <article>
           <h4>
             For Developers
           </h4>
           <p>
             Build custom solutions and expand capabilities by leveraging powerful APIs
           </p>
         </article>
       </div>
     </div>
     <div class="supportform">
       <h3>
         Consult with Team
       </h3>
       <form action="">
         <div class="mb-4">
             <label for="name" class="form-label">Full Name</label>
             <input type="text" class="form-control form-control-lg" id="name" >
         </div>
         <div class="mb-4">
             <label for="email" class="form-label">Email</label>
             <input type="email" class="form-control form-control-lg" id="email" >
         </div>
         <div class="mb-4">
             <label for="phonenumber" class="form-label">Phone Number</label>
             <input type="number" class="form-control form-control-lg" id="phonenumber" >
         </div>
         <div class="mb-4">
             <label for="requirement" class="form-label">Requirement</label>
             <select class="form-select form-select-lg" name="" id="requirement">
               <option value="">
                 Requirement 1
               </option>
             </select>
         </div>
         <div class="mb-4">
             <label for="message" class="form-label">Your Message</label>
             <textarea class="form-control form-control-lg" id="message" rows="3"></textarea>
           </div>
           <button class="large-btn" type="submit">Save & Submit</button>
     </form>
     </div>
   </div>
<div class="contactSupport">
 <div class="txts">
 <h3>
   Contact Support
 </h3>
 <p>
   24/7 help from our support staff
 </p>
 <a [routerLink]="['/contact-us']">Contact</a>
</div>
</div>
 </div>
 </section>
