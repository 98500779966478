<div class="hero-inner industry_education">
  <div class="containers">
   <h1>
       <small>
         <a [routerLink]="['/industries']">Industries</a> <i class="bi bi-arrow-right"></i> Education
       </small>
       Automate and simplify student management
     </h1>
 </div>
</div>

<section class="sections">
<div class="containers">
   <div class="indus_details">
       <picture>
           <img src="../../assets/img/bg_education.jpg" alt="">
       </picture>
       <article>
           <h2>
               Education
           </h2>
           <p>
               OfficeGaps helps to manage the end-to-end requirements of all educational schools, colleges, and universities, resulting in an accurate view of the truth, enabling informed decision-making. The software automates every vital function of your institution, so whether you are in need of admissions, fees, HR, or administration, we've got you covered.
           </p><p>
Educational institutions and schools need to keep up with the latest technologies and tools that simplify the entire education process. The departments are highly interconnected, which means increased collaboration is needed in order to promote healthy relationships. Our  solutions allow teachers, parents, and students to receive important announcements by sharing data across all departments.
</p><p>
Educating society has long-term impacts that extend beyond a specific community, so it is imperative that operations keep up with the digital world to ensure efficient execution. In an educational institution, our advanced ERP solutions will integrate <strong> academics</strong>, administration, accounting, <strong>transportation, and other departments</strong> to automate operations, letting management maintain a comprehensive view of activities.
           </p>
       </article>
   </div>

   <div class="bordered_columns">
       <div class="box">
         <div class="numb">
             <span>1</span>
           </div>
           <h4>
               Student & Faculty Management
           </h4>
           <p>
               Automates and streamlines all internal processes of the institution to help allocate and operate academic resources more effectively
           </p>
       </div>
       <div class="box">
           <div class="numb">
               <span>2</span>
             </div>
             <h4>
               Finance & Accounting
             </h4>
             <p>
               Education industry solutions include payroll management, fee management, invoicing, income and expenditure accounts, and cash flow statements
             </p>
         </div>
         <div class="box">
           <div class="numb">
               <span>3</span>
             </div>
             <h4>
               Improved data management
             </h4>
             <p>
               Integrating the campus and all the branches into a single system simplifies the process of managing data
             </p>
         </div>
   </div>
</div>
</section>
<app-weeklyupdate></app-weeklyupdate>
<section class="sections">
 <div class="sectitle_01">
   <h2>
       Get to know us better
   </h2>
   <p>
       We'll do everything we can to make our next best project!
   </p>
 </div>

 <div class="containers">

   <div class="accordion accordion-flush" id="industrial">
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingOne">
         <button class="accordion-button collapse" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseOne" aria-expanded="false" aria-controls="faq-collapseOne">
           Enhances integration, maintenance, and efficiency of systems
         </button>
       </h2>
       <div id="faq-collapseOne" class="accordion-collapse collapse show" aria-labelledby="faq-headingOne" data-bs-parent="#industrial">
         <div class="accordion-body">
          <p>
           It allows teachers, staff, and students to share stored data across various departments to keep them up to date on important announcements. You'd have a better sense of control over information shared within your institution and never have to deal with a communication issue.
          </p>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingTwo">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseTwo" aria-expanded="false" aria-controls="faq-collapseTwo">
           Ensures data security and availability by eliminating resource constraints
         </button>
       </h2>
       <div id="faq-collapseTwo" class="accordion-collapse collapse" aria-labelledby="faq-headingTwo" data-bs-parent="#industrial">
         <div class="accordion-body">
           <p>
               Schools today are flooded with data and much more, unlike in the past. Education institutions handle sensitive data, so a safer and centralized platform is needed to facilitate the flow of data seamlessly. Additionally, it will make tasks such as student admission, fee payments, <strong> course enrollments, and registrations </strong> more convenient.
           </p>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingThree">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseThree" aria-expanded="false" aria-controls="faq-collapseThree">
           Our solutions enable cost-effective management, so staff can better serve students
         </button>
       </h2>
       <div id="faq-collapseThree" class="accordion-collapse collapse" aria-labelledby="faq-headingThree" data-bs-parent="#industrial">
         <div class="accordion-body">
          <p>
           In the basic methods of campus administration such as admission, payroll, fee collection, and others, the software reduced the number of manual processes resulting in time and money savings. These savings can go toward essential educational programs.
          </p>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingFour">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseFour" aria-expanded="false" aria-controls="faq-collapseFour">
           Reducing overall ownership costs helps you save capital for student, faculty, and campus expenditures
         </button>
       </h2>
       <div id="faq-collapseFour" class="accordion-collapse collapse" aria-labelledby="faq-headingFour" data-bs-parent="#industrial">
         <div class="accordion-body">
           <p>
               Our solutions optimize school or university operations by using fewer resources, creating time and money savings - benefits that can be passed along to students. Students and teachers benefit from this environment, which focuses them on learning. In the long run, this will allow the management to analyze and consider various aspects of the institution faster, resulting in greater planning efficiency.
           </p>
         </div>
       </div>
     </div>

   </div>

   </div>
   </section>

