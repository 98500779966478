<div class="hero-inner industry_healthcare">
  <div class="containers">
   <h1>
       <small>
        <a [routerLink]="['/industries']">Industries</a> <i class="bi bi-arrow-right"></i> Healthcare
       </small>
       Improve the quality of care and <br> efficiency of operations
     </h1>
 </div>
</div>

<section class="sections">
<div class="containers">
   <div class="indus_details">
       <picture>
           <img src="../../assets/img/bg_healthcare.jpg" alt="">
       </picture>
       <article>
           <h2>
               Healthcare
           </h2>
           <p>
               OfficeGaps can be a great fit in a variety of industries including healthcare. Integrating departments such as human resources, finance, billing, and inventory can help clients receive better service. As a result, information will be readily available throughout the organization and affiliated healthcare facilities.
</p><p>
               Although technology has advanced remarkably, there are still a number of outdated healthcare systems, which require expensive and challenging maintenance. We, therefore, provide solutions to make hospitals' operations more efficient.
           </p><p>
<strong>Our system makes it possible for the organization to store and transfer patient information throughout the various departments</strong>. In either case, all records will remain accessible and updated whenever they move to the office of a physician or other hospital departments.
           </p>
       </article>
   </div>

   <div class="bordered_columns">
       <div class="box">
         <div class="numb">
             <span>1</span>
           </div>
           <h4>
               Better Patient Care
           </h4>
           <p>
               As a result of easy access to patient records, doctors are able to provide quality patient care by making the right diagnoses and decisions regarding patients' welfare
           </p>
       </div>
       <div class="box">
           <div class="numb">
               <span>2</span>
             </div>
             <h4>
               Reduced operating costs
             </h4>
             <p>
               It reduces costly errors in administrative and inventory functions, as well as saves employees' time by automating distribution and purchasing functions
             </p>
         </div>
         <div class="box">
           <div class="numb">
               <span>3</span>
             </div>
             <h4>
               Adopt Best Practices
             </h4>
             <p>
               Ensure that all policies and procedures pertaining to member benefits, claims, enrollment, and providers' networks are up to date with best industry practices
             </p>
         </div>
   </div>
</div>
</section>
<app-weeklyupdate></app-weeklyupdate>
<section class="sections">
 <div class="sectitle_01">
   <h2>
       Get to know us better
   </h2>
   <p>
       We'll do everything we can to make our next best project!
   </p>
 </div>

 <div class="containers">

   <div class="accordion accordion-flush" id="industrial">
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingOne">
         <button class="accordion-button collapse" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseOne" aria-expanded="false" aria-controls="faq-collapseOne">
           Facilitates communication between providers and patients, improving patient safety
         </button>
       </h2>
       <div id="faq-collapseOne" class="accordion-collapse collapse show" aria-labelledby="faq-headingOne" data-bs-parent="#industrial">
         <div class="accordion-body">
         <p>
          Keep all your crucial patient information centralized. Our easy-to-use and innovative solutions help the healthcare professionals to focus on their key concerns—quality patient care and safety.
         </p>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingTwo">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseTwo" aria-expanded="false" aria-controls="faq-collapseTwo">
           Helps inform and expedite the decision-making process for patients
         </button>
       </h2>
       <div id="faq-collapseTwo" class="accordion-collapse collapse" aria-labelledby="faq-headingTwo" data-bs-parent="#industrial">
         <div class="accordion-body">
          <p>
           Our software helps healthcare providers to provide the patients with remote access to crucial databases and reports. This helps the patients to make informed and prompt decision regarding treatment and makes planning subsequent tests and medical procedures easier.
          </p>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingThree">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseThree" aria-expanded="false" aria-controls="faq-collapseThree">
           Ensures the security and confidentiality of patient and business data stored by hospitals and healthcare providers
         </button>
       </h2>
       <div id="faq-collapseThree" class="accordion-collapse collapse" aria-labelledby="faq-headingThree" data-bs-parent="#industrial">
         <div class="accordion-body">
         <p>
           Hospitals and healthcare providers store essential data on their business processes and their patients. The protection and confidentiality of all your information with the most advanced technology reduces the risk of information loss, as everything is stored in the cloud and any user can access it when needed.
         </p>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingFour">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseFour" aria-expanded="false" aria-controls="faq-collapseFour">
           Aids in evaluating the efficiency of healthcare systems so that better decisions can be made
         </button>
       </h2>
       <div id="faq-collapseFour" class="accordion-collapse collapse" aria-labelledby="faq-headingFour" data-bs-parent="#industrial">
         <div class="accordion-body">
           <p>
               Identify and improve areas for improvement easily, thus enhancing the overall effectiveness of the hospital. Our solutions are capable of measuring and assessing a hospital’s operational performance providing  information about budgeting, requisitions, and analysis of patient visitation.
           </p>
         </div>
       </div>
     </div>

   </div>

   </div>
   </section>
