<div class="trailForm">
  <div class="trailogo">
      <a  [routerLink]="['']">
          <img src="../../assets/img/OG-logo_black.svg" alt="">
        </a>
  </div>

  <button class="sec-back">
    <svg>
      <use xlink:href="../../assets/img/sprite.svg#backbtn"></use>
    </svg>
    Back
  </button>
  <div class="formColum">
      <div class="form-container">
          <h2>Get started absolutely free</h2>
          <p>Take advantage of our comprehensive suite of features for your business</p>
          <form action="">
            <div  class="formClassification">
              <div class="mb-4">
                  <label for="name" class="form-label">Full Name</label>
                  <input type="text" class="form-control form-control-lg" id="name">
              </div>
              <div class="mb-4">
                  <label for="name" class="form-label">Last Name</label>
                  <input type="email" class="form-control form-control-lg" id="email">
              </div>
              <div class="mb-4">
                  <label for="phonenumber" class="form-label">Phone Number</label>
                  <input type="number" class="form-control form-control-lg" id="phonenumber">
              </div>
              <div class="mb-4">
                  <label for="job" class="form-label">Job Title </label>
                  <input type="url" class="form-control form-control-lg" id="website">
              </div>
              <div class="mb-4">
                  <label for="company" class="form-label">Company Name </label>
                  <input type="url" class="form-control form-control-lg" id="website">
              </div>
              <div class="mb-4">
                <label for="domain" class="form-label">Subdomain </label>
                  <div class="dom-colum">
                      <div>
                          <input type="url" class="form-control form-control-lg" id="website">
                      </div>
                      <div>
                          <input type="url" class="form-control form-control-lg og-dom" id="website" value="@officegaps.com">
                      </div>
                  </div>

              </div>
              <div class="mb-4">
                  <label for="language" class="form-label">Language </label>
                  <select id="lng" name="Languages" class="form-control form-control-lg">
                      <option value="Eng">English</option>
                      <option value="Chi">Chinese</option>
                      <option value="Fr">French</option>
                  </select>
              </div>
              <div class="mb-4">
                  <label for="password" class="form-label">Password</label>
                  <input type="url" class="form-control form-control-lg" id="website">
              </div>
              <div>
                  <div class="mb-4">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                          I agree to the OfficeGaps agreement
                        </label>
                      </div>
                  </div>
                  <button class="large-btn" type="submit">Sign Up</button>
              </div>
            </div>
          </form>
      </div>
      <div class="right-bg">
          <img src="../../assets/img/person_r.png">
      </div>
  </div>


</div>
