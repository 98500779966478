import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

const faqtabs = <HTMLElement>document.querySelector(".faqwrap");
const faqCatBtn = Array.from(document.querySelectorAll(".catBtn"));
const catContent = Array.from(document.querySelectorAll(".catContents"));
if(faqtabs!=null){
faqtabs.onclick = e => {
  const target=e.target as HTMLElement;
  const id = target.dataset.id;
  if (id) {
    faqCatBtn.forEach(btn => {
      btn.classList.remove("active");
    });
    target.classList.add("active");

    catContent.forEach(content => {
      content.classList.remove("active");
    });
    const element = <HTMLElement>document.getElementById(id);
    element.classList.add("active");
  }
}
}

  }

}
