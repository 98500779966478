<div class="hero-inner dark">
  <div class="graybar">
      <div class="containers">
   <h1>
       <small>
        <a [routerLink]="['/support']">Support</a> <i class="bi bi-arrow-right"></i> Help Center
       </small>
     </h1>
  </div>
  </div>
     <div class="sectitle_01">
      <h2>
        Hi, How Can We Help You?
      </h2>
    </div>
    <div class="searchbox">
      <input type="search" placeholder="Search Our Help Center.." name="searchSupport" id="">
      <button type="submit">Search</button>
    </div>
 </div>
<section class="sections">
<div class="containers">
<div class="supportlist titles">
  <a class="listcolumn" routerLinkActive="active" [routerLink]="['/help-center/getting-started']" >
    <svg>
      <use xlink:href="../../assets/img/sprite.svg#getStart"></use>
    </svg>
    <h4>
      Getting Started
    </h4>
  </a>
  <a class="listcolumn" routerLinkActive="active" [routerLink]="['/help-center/account-settings']" >
    <svg>
      <use xlink:href="../../assets/img/sprite.svg#accountSet"></use>
    </svg>
    <h4>
      Account Settings
    </h4>
  </a>
  <a class="listcolumn" routerLinkActive="active" [routerLink]="['/help-center/integrations']" >
    <svg>
      <use xlink:href="../../assets/img/sprite.svg#integration"></use>
    </svg>
    <h4>
      Integrations
    </h4>
  </a>
  <a class="listcolumn" routerLinkActive="active" [routerLink]="['/help-center/manage-your-team']" >
    <svg>
      <use xlink:href="../../assets/img/sprite.svg#manageTeam"></use>
    </svg>
    <h4>
      Manage your Team
    </h4>
  </a>
  <a class="listcolumn" routerLinkActive="active" [routerLink]="['/help-center/verification']" >
    <svg>
      <use xlink:href="../../assets/img/sprite.svg#verify"></use>
    </svg>
    <h4>
      Verification
    </h4>
  </a>
  <a class="listcolumn" routerLinkActive="active" [routerLink]="['/help-center/unleashed']" >
    <svg>
      <use xlink:href="../../assets/img/sprite.svg#unleashed"></use>
    </svg>
    <h4>
      OfficeGaps Unleashed
    </h4>
  </a>
</div>
<router-outlet></router-outlet>
</div>
</section>
