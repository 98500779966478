<div class="hero-inner faq">
  <div class="containers">
  <h1>
    <small>
       FAQ
    </small>
    Feel free to ask us any questions <br> you may have
  </h1>
 </div>
</div>

<div class="containers">
  <div class="floated-title centered">
    <h2>
       Frequently Asked Questions
    </h2>
 <p>
  If you are just getting started or looking to improve your business, here are a few frequently asked questions to help you learn more about OfficeGaps and its features.
 </p>
  </div>
</div>

<section class="faqsection">
   <div class="container">

    <div class="faqwrap">
   <ul class="faqCats">
     <li class="catBtn active" data-id="General">General</li>
     <li class="catBtn" data-id="Secutity">Security</li>
     <li class="catBtn" data-id="Pricing">Pricing</li>
     <li class="catBtn" data-id="Support">Support</li>
     <li class="catBtn" data-id="Refunds">Payments & Refunds</li>
     <li class="catBtn" data-id="Features">Features</li>
   </ul>
   <div class="catContentWrap">
     <div class="catContents active" id="General">
       <div class="accordion" id="generalFAQ">
         <div class="accordion-item">
           <h2 class="accordion-header" id="generalOne">
             <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#generalAnswerOne" aria-expanded="true" aria-controls="generalAnswerOne">
                 How can I change my account information?
             </button>
           </h2>
           <div id="generalAnswerOne" class="accordion-collapse collapse show" aria-labelledby="generalOne" data-bs-parent="#generalFAQ">
             <div class="accordion-body">
               <ol type="a">
                 <li>You can change information of your OfficeGaps account such as Addresses, Payment methods, Name, Display Name, Password, and Avatar</li>
                 <li>To do this so, login into your OfficeGaps.com account with your username and password</li>
                 <li>Once you signed in, you should see your "My Account" page.  If not, go to the top right corner of your screen hover over your email and choose "My Account" from the drop-down menu</li>
                 <li>Once in "My Account" on your left-hand side, you will find a list of option, just click over the one you want to modify the information</li>
                 <li>After modifying, click on the confirmation button to save the changes</li>

               </ol>
             </div>
           </div>
         </div>
         <div class="accordion-item">
           <h2 class="accordion-header" id="generalTwo">
             <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#generalAnswerTwo" aria-expanded="false" aria-controls="generalAnswerTwo">
                 In order for OfficeGaps to function, what are the system requirements?
             </button>
           </h2>
           <div id="generalAnswerTwo" class="accordion-collapse collapse" aria-labelledby="generalTwo" data-bs-parent="#generalFAQ">
             <div class="accordion-body">
               <p>
                 Meeting these requirements is necessary to make sure that OfficeGaps Software perform correctly on any system.
                 </p>
                 <p>
                 Windows® Minimum Requirements
             </p>
             <ul class="arrowlist">
                 <li>Windows -Vista, 7, 8, 10 (32 and 64 bits) Enterprise, Ultimate, Pro, or Home Premium</li>
                 <li>4 GB of system RAM</li>
                 <li>An internet connection is required at all times</li>
             </ul>

             </div>
           </div>
         </div>
         <div class="accordion-item">
           <h2 class="accordion-header" id="generalThree">
             <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#generalAnswerThree" aria-expanded="false" aria-controls="generalAnswerThree">
                 How do I cancel my OfficeGaps subscription?
             </button>
           </h2>
           <div id="generalAnswerThree" class="accordion-collapse collapse" aria-labelledby="generalThree" data-bs-parent="#generalFAQ">
             <div class="accordion-body">
               <p>
                 If you would like to cancel your OfficeGaps subscription, please follow the steps below.
               </p>
               <ol>
                 <li>Login to OfficeGaps.com with the user account associated with your license administrator</li>
                 <li>Select “My Subscriptions” from the user menu</li>
                 <li>Choose the subscription you would like to cancel</li>
                 <li>Select CANCEL</li>
                 <li>Your subscription will then be set to a Pending Cancellation status until our team finalizes the cancellation</li>
                 <li>You can log out of your OfficeGaps.com account</li>
                 <li>That's all you need to do. We hope you will return to your OfficeGaps product. If you wish to reactivate your product, you can do so at any time</li>

               </ol>
             </div>
           </div>
         </div>
         <div class="accordion-item">
             <h2 class="accordion-header" id="generalFour">
               <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#generalAnswerFour" aria-expanded="false" aria-controls="generalAnswerFour">
                 How do I add additional features or user subscriptions to my current plan?
               </button>
             </h2>
             <div id="generalAnswerFour" class="accordion-collapse collapse" aria-labelledby="generalFour" data-bs-parent="#generalFAQ">
               <div class="accordion-body">
                   <ol>
                 <li>Login to OfficeGaps.com with the user account associated with your license administrator</li>
<li>Select My Plan from the user menu</li>
<li>Choose your plan number from the "Select an existing plan" dropdown menu</li>
<li>Choose "ADD TO EXISTING PLAN”</li>
<li>Select the feature or user subscriptions to your cart that you want to add to your plan</li>
<li>The item should have been added to your plan number. Now proceed to your cart</li>
<li>Review the information and proceed to check out</li>
</ol>
               </div>
             </div>
           </div>
           <div class="accordion-item">
             <h2 class="accordion-header" id="generalFive">
               <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#generalAnswerFive" aria-expanded="false" aria-controls="generalAnswerFive">
                 Is OfficeGaps good for manufacturing?
               </button>
             </h2>
             <div id="generalAnswerFive" class="accordion-collapse collapse" aria-labelledby="generalFive" data-bs-parent="#generalFAQ">
               <div class="accordion-body">
                 <p>
                     Yes! OfficeGaps helps you reduce operating costs, increase revenue, and better manage business and supply chain processes with a single integrated cloud solution.
                 </p>
               </div>
             </div>
           </div>
           <div class="accordion-item">
             <h2 class="accordion-header" id="generalSix">
               <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#generalAnswerSix" aria-expanded="false" aria-controls="generalAnswerSix">
                   Is it possible to join the OfficeGaps Solution Partner Program?
               </button>
             </h2>
             <div id="generalAnswerSix" class="accordion-collapse collapse" aria-labelledby="generalSix" data-bs-parent="#generalFAQ">
               <div class="accordion-body">
                 <p>
                   Yes! OfficeGaps is always interested to hear from like-minded companies looking to work together.
                 </p>
               </div>
             </div>
           </div>
           <div class="accordion-item">
             <h2 class="accordion-header" id="generalSeven">
               <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#generalAnswerSeven" aria-expanded="false" aria-controls="generalAnswerSeven">
                   Who is an organization administrator?
               </button>
             </h2>
             <div id="generalAnswerSeven" class="accordion-collapse collapse" aria-labelledby="generalSeven" data-bs-parent="#generalFAQ">
               <div class="accordion-body">
                 <p>
                   The admin of an organization is able to create organizations, claim domains, and manage all user accounts on those domains, including editing the account profile, e-mail address, and password. In addition, the organization admin can configure and enforce security policies via OfficeGaps.
                 </p>
               </div>
             </div>
           </div>
           <div class="accordion-item">
             <h2 class="accordion-header" id="generalEight">
               <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#generalAnswerEight" aria-expanded="false" aria-controls="generalAnswerEight">
                   Does JTSi have a team who can assist with on-site training, implementation, and/or customizations?
               </button>
             </h2>
             <div id="generalAnswerEight" class="accordion-collapse collapse" aria-labelledby="generalEight" data-bs-parent="#generalFAQ">
               <div class="accordion-body">
                 <p>
                   JTSi assists with on-site customer training, implementations, or software customizations. Standard and Enterprise licensees are of course entitled to support and assistance under the terms of their active software maintenance and JTSi will always endeavor to provide legendary support under those terms.
                 </p>
               </div>
             </div>
           </div>

           <div class="accordion-item">
             <h2 class="accordion-header" id="generalNine">
               <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#generalAnswerNine" aria-expanded="false" aria-controls="generalAnswerNine">
                 Can I reassign a license to a new owner?
               </button>
             </h2>
             <div id="generalAnswerNine" class="accordion-collapse collapse" aria-labelledby="generalNine" data-bs-parent="#generalFAQ">
               <div class="accordion-body">
                 <p>
                   To reassign your existing licenses to a new entity, log in to OfficeGaps.com and update the billing and technical contacts.
                 </p>
               </div>
             </div>
           </div>
           <div class="accordion-item">
             <h2 class="accordion-header" id="generalTen">
               <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#generalAnswerTen" aria-expanded="false" aria-controls="generalAnswerTen">
                 Does OfficeGaps process personal data?
               </button>
             </h2>
             <div id="generalAnswerTen" class="accordion-collapse collapse" aria-labelledby="generalTen" data-bs-parent="#generalFAQ">
               <div class="accordion-body">
                 <p>
                   Yes. Our privacy policies set forth how we use personal data in order to provide our products and services, and for other purposes.
                 </p>
               </div>
             </div>
           </div>

           <div class="accordion-item">
             <h2 class="accordion-header" id="generalEleven">
               <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#generalAnswerEleven" aria-expanded="false" aria-controls="generalAnswerEleven">
                 Where does OfficeGaps store and send my data?
               </button>
             </h2>
             <div id="generalAnswerEleven" class="accordion-collapse collapse" aria-labelledby="generalEleven" data-bs-parent="#generalFAQ">
               <div class="accordion-body">
                 <p>
                   We are dedicated to providing our customers with secure, fast, and reliable services. Our role as a global service provider entails the use of common operational practices and features across different jurisdictions. Currently, our data centers are located in the United States. Also, our employees and contractors located around the world may have access to certain data for purposes of product development and promotion, and customer and technical support.
                 </p>
               </div>
             </div>
           </div>

       </div>
     </div>
     <div class="catContents" id="Secutity">
       <div class="accordion" id="securityFAQ">
         <div class="accordion-item">
           <h2 class="accordion-header" id="securityOne">
             <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#securityAnswerOne" aria-expanded="true" aria-controls="securityAnswerOne">
               How do I reset my password for OfficeGaps?
             </button>
           </h2>
           <div id="securityAnswerOne" class="accordion-collapse collapse show" aria-labelledby="securityOne" data-bs-parent="#securityFAQ">
             <div class="accordion-body">
               <p>
                 If you have forgotten your password for your OfficeGaps account, you will need to reset it. In order to reset your password for OfficeGaps, you need to visit our <a href="#" target="_blank" rel="noopener noreferrer"> Reset your Password page</a>. A password reset email will be sent to you.
               </p><p>
                 If you cannot reset your password for some reason, please email <a href="mailto:support@officegaps.com">support@officegaps.com</a>  and we will help you.

               </p>
             </div>
           </div>
         </div>
         <div class="accordion-item">
           <h2 class="accordion-header" id="securityTwo">
             <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#securityAnswerTwo" aria-expanded="false" aria-controls="securityAnswerTwo">
               Does OfficeGaps adhere to information security standards?
             </button>
           </h2>
           <div id="securityAnswerTwo" class="accordion-collapse collapse" aria-labelledby="securityTwo" data-bs-parent="#securityFAQ">
             <div class="accordion-body">
               <ul class="arrowlist">
                 <li>ISO/IEC 27001 - JTSi and OfficeGaps have achieved ISO/IEC 27001 Certification</li>
<li>ISO/IEC 27018 - We have also achieved ISO/IEC 27018 certification for protection of personally identifiable information (PII) or personal data (PD) in our cloud environments</li>
<li>PCI  –OfficeGaps uses tokens with PCI DSS certified credit card processors; we never see or store your credit card details</li>
<li>FedRAMP – We provide FedRAMP-compliant cloud services upon request, making it easier than ever for government agencies to digitally transform legacy mission systems securely, efficiently, and effectively</li>

               </ul>
             </div>
           </div>
         </div>
         <div class="accordion-item">
           <h2 class="accordion-header" id="securityThree">
             <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#securityAnswerThree" aria-expanded="false" aria-controls="securityAnswerThree">
               Who has access to our data?
             </button>
           </h2>
           <div id="securityAnswerThree" class="accordion-collapse collapse" aria-labelledby="securityThree" data-bs-parent="#securityFAQ">
             <div class="accordion-body">
               <p>
                 For OfficeGaps Cloud Customers, we've outlined our approach in our <a href="privacy-policy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                 OfficeGaps encrypts customer data in transit and at rest.
                   </p><p>
                       All customer data stored within OfficeGaps is encrypted in transit over public networks using to protect it from unauthorized disclosure or modification.

               </p>
             </div>
           </div>
         </div>
         <div class="accordion-item">
           <h2 class="accordion-header" id="securityFour">
             <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#securityAnswerFour" aria-expanded="false" aria-controls="securityAnswerFour">
               Does OfficeGaps audit its cloud security?
             </button>
           </h2>
           <div id="securityAnswerFour" class="accordion-collapse collapse" aria-labelledby="securityFour" data-bs-parent="#securityFAQ">
             <div class="accordion-body">
               <p>
                 We have an extensive security program that includes ongoing testing of our hosted systems and products. Additionally, we conduct independent third party assessments of our Cloud solutions. All of our cloud products and servers are tested through our public bug bounty program.
               </p>
             </div>
           </div>
         </div>
         <div class="accordion-item">
           <h2 class="accordion-header" id="securityFive">
             <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#securityAnswerFive" aria-expanded="false" aria-controls="securityAnswerFive">
               How do you make sure US law enforcement/agencies won't access customer data?
             </button>
           </h2>
           <div id="securityAnswerFive" class="accordion-collapse collapse" aria-labelledby="securityFive" data-bs-parent="#securityFAQ">
             <div class="accordion-body">
               <p>
                 We respect your privacy and provide Customer Information only in response to law enforcement requests when we have a reasonable belief that we are legally required to do so. We thoroughly review every request to ensure compliance with the law and the rights of our customers.
               </p>
             </div>
           </div>
         </div>
         <div class="accordion-item">
           <h2 class="accordion-header" id="securitySix">
             <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#securityAnswerSix" aria-expanded="false" aria-controls="securityAnswerSix">
               What responsibilities does OfficeGaps maintain during a security incident?
             </button>
           </h2>
           <div id="securityAnswerSix" class="accordion-collapse collapse" aria-labelledby="securitySix" data-bs-parent="#securityFAQ">
             <div class="accordion-body">
               <p>
                 Here at JTSi, we try our best to ensure our customers don't experience an outage or a security incident. Security incidents are possible, but we are mindful of this fact.
               </p>
             </div>
           </div>
         </div>
       </div>
     </div>
     <div class="catContents" id="Pricing">
       <div class="accordion" id="pricingFAQ">
         <div class="accordion-item">
           <h2 class="accordion-header" id="pricingOne">
             <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#pricingAnswerOne" aria-expanded="true" aria-controls="pricingAnswerOne">
               Does OfficeGaps offer any discounts?
             </button>
           </h2>
           <div id="pricingAnswerOne" class="accordion-collapse collapse show" aria-labelledby="pricingOne" data-bs-parent="#pricingFAQ">
             <div class="accordion-body">
               <p>
                 Our cloud plans start free for up to 20 users and grow with you as your team scales. Our Standard and Premium cloud plans can be trialed for free at any time.
               </p><p>
                 We offer special pricing to registered charitable non-profits, collegiate-level or higher academic institutions, Open-Source projects, and certified OfficeGaps Partners.
               </p><p>
                 Customers interested in migrating from on-premises to the cloud may qualify for our special discounts
               </p>
             </div>
           </div>
         </div>
         <div class="accordion-item">
           <h2 class="accordion-header" id="pricingTwo">
             <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#pricingAnswerTwo" aria-expanded="false" aria-controls="pricingAnswerTwo">
               Do non-profits receive special pricing?
             </button>
           </h2>
           <div id="pricingAnswerTwo" class="accordion-collapse collapse" aria-labelledby="pricingTwo" data-bs-parent="#pricingFAQ">
             <div class="accordion-body">
               <p>
                 JTSi is happy to provide cloud subscriptions at 75% off the list price to registered charitable non-profit organizations that are non-government, non-academic, non-commercial in nature, non-political, and have no religious affiliation.
               </p>
             </div>
           </div>
         </div>
         <div class="accordion-item">
           <h2 class="accordion-header" id="pricingThree">
             <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#pricingAnswerThree" aria-expanded="false" aria-controls="pricingAnswerThree">
               Do governmental entities receive special pricing?
             </button>
           </h2>
           <div id="pricingAnswerThree" class="accordion-collapse collapse" aria-labelledby="pricingThree" data-bs-parent="#pricingFAQ">
             <div class="accordion-body">
               <p>
                 All governmental entities are required to purchase commercial licenses or subscriptions - we do not offer special pricing for governmental entities.
               </p>
             </div>
           </div>
         </div>
         <div class="accordion-item">
           <h2 class="accordion-header" id="pricingFour">
             <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#pricingAnswerFour" aria-expanded="false" aria-controls="pricingAnswerFour">
               Does JTSi offer discounts for migrating from server or Data Center to cloud?
             </button>
           </h2>
           <div id="pricingAnswerFour" class="accordion-collapse collapse" aria-labelledby="pricingFour" data-bs-parent="#supportFAQ">
             <div class="accordion-body">
               <p>
                 Yes, Customers migrating to our service are offered a variety of discounts. Please email us your requirements: <a href="mailto:support@officegaps.com">support@officegaps.com</a>
               </p>
             </div>
           </div>
         </div>
         <div class="accordion-item">
           <h2 class="accordion-header" id="pricingFive">
             <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#pricingAnswerFive" aria-expanded="false" aria-controls="pricingAnswerFive">
               How can I reduce my monthly bill?
             </button>
           </h2>
           <div id="pricingAnswerFive" class="accordion-collapse collapse" aria-labelledby="pricingFive" data-bs-parent="#supportFAQ">
             <div class="accordion-body">
               <p>
                 There are ways to lower your monthly bill if you're looking to do so:
               </p>
               <ul class="arrowlist">
                 <li>Convert to an annual subscription. Annual subscriptions currently offer a discount on an annual basis</li>
<li>Verify that all business modules in your organization have active users by contacting the site administrator. In addition to paying for access to business modules, site administrators also manage their users. It is then possible to disable OfficeGaps accounts in your organization that are no longer active</li>

               </ul>
             </div>
           </div>
         </div>
       </div>
     </div>
     <div class="catContents" id="Support">
       <div class="accordion" id="supportFAQ">
         <div class="accordion-item">
           <h2 class="accordion-header" id="supportOne">
             <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#supportAnswerOne" aria-expanded="true" aria-controls="supportAnswerOne">
               My license has expired/I don't have license. Will I receive support?
             </button>
           </h2>
           <div id="supportAnswerOne" class="accordion-collapse collapse show" aria-labelledby="supportOne" data-bs-parent="#supportFAQ">
             <div class="accordion-body">
               <p>
                 No. To assist on the case, the support engineers need your subscription details (even if it is an Evaluation license). You can renew your license by contacting our Sales Team. You will immediately be eligible for support after the renewal process is completed.
               </p>
             </div>
           </div>
         </div>
         <div class="accordion-item">
           <h2 class="accordion-header" id="supportTwo">
             <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#supportAnswerTwo" aria-expanded="false" aria-controls="supportAnswerTwo">
               I need support during the weekend. Will I receive it?
             </button>
           </h2>
           <div id="supportAnswerTwo" class="accordion-collapse collapse" aria-labelledby="supportTwo" data-bs-parent="#supportFAQ">
             <div class="accordion-body">
               <p>
                 We only offer weekend support to Enterprise customers. While it is commonly recommended that migrations or maintenance takes place at the weekend, you can only get assistance from OfficeGaps support if you hold an Enterprise license (regardless of priority). In order to receive assistance during the weekend, please contact the Sales Team and upgrade your license as soon as possible!
               </p>
             </div>
           </div>
         </div>
         <div class="accordion-item">
           <h2 class="accordion-header" id="supportThree">
             <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#supportAnswerThree" aria-expanded="false" aria-controls="supportAnswerThree">
               What about third-party systems/plugins? Are these supported?
             </button>
           </h2>
           <div id="supportAnswerThree" class="accordion-collapse collapse" aria-labelledby="supportThree" data-bs-parent="#supportFAQ">
             <div class="accordion-body">
               <p>
                 OfficeGaps integrates with a variety of technologies. JTSi will provide documentation for integrating with third-party applications but does not offer support for them. A failure in a third-party application cannot be supported by us.
               </p><p>
             JTSi offers support for certain third-party plugins as listed in our supported plugins list. Issues regarding unsupported plugins should be directed to the plugin's developer.

               </p>
             </div>
           </div>
         </div>
         <div class="accordion-item">
           <h2 class="accordion-header" id="supportFour">
             <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#supportAnswerFour" aria-expanded="false" aria-controls="supportAnswerFour">
               I'm not a technical person. Is this a problem?
             </button>
           </h2>
           <div id="supportAnswerFour" class="accordion-collapse collapse" aria-labelledby="supportFour" data-bs-parent="#supportFAQ">
             <div class="accordion-body">
               <p>
                 According to the Subscription, this will vary. Having an OfficeGaps Cloud account will solve any problem about not having technical skills. There are, however, some knowledge prerequisites that are necessary if you are hosting an On-Premises version of OfficeGaps.
               </p>
             </div>
           </div>
         </div>
       </div>
     </div>
     <div class="catContents" id="Refunds">
       <div class="accordion" id="RefundFAQ">
         <div class="accordion-item">
           <h2 class="accordion-header" id="RefundOne">
             <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#RefundAnswerOne" aria-expanded="true" aria-controls="RefundAnswerOne">
               Which payment methods are accepted, and what are your payment terms?
             </button>
           </h2>
           <div id="RefundAnswerOne" class="accordion-collapse collapse show" aria-labelledby="RefundOne" data-bs-parent="#RefundFAQ">
             <div class="accordion-body">
               <p>
                 JTSi accepts the following payment methods:
                 </p><p>

                  <strong>Credit card  — </strong> (MasterCard, Visa, Discovery or American Express) via our secure Quote & Order Form.
                 </p><p>
                 <strong>PayPal  — </strong> Automatically renew our cloud products and other payments
                 </p><p>
                 <strong>Bank transfer  — </strong> Clearance of bank transfers can take up to 14 business days. You can email your remittance advice to <a href="mailto:support@officegaps.com">support@officegaps.com</a>.
                 </p><p>
                 <strong>Mailed check — </strong> Payments can take between 7-14 business days to be received and processed by our financial department.

               </p>
              </div>
           </div>
         </div>
         <div class="accordion-item">
           <h2 class="accordion-header" id="RefundTwo">
             <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#RefundAnswerTwo" aria-expanded="false" aria-controls="RefundAnswerTwo">
               Can I get a refund?
             </button>
           </h2>
           <div id="RefundAnswerTwo" class="accordion-collapse collapse" aria-labelledby="RefundTwo" data-bs-parent="#RefundFAQ">
             <div class="accordion-body">
               <p>
                 For monthly subscriptions, refunds are available within the first month of payment after the trial period. For annual subscriptions, refunds are available within 30 days of payment. We cannot offer refunds after these periods.
               </p>
             </div>
           </div>
         </div>
         <div class="accordion-item">
           <h2 class="accordion-header" id="RefundThree">
             <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#RefundAnswerThree" aria-expanded="false" aria-controls="RefundAnswerThree">
               Can I sign up for automatic renewals?
             </button>
           </h2>
           <div id="RefundAnswerThree" class="accordion-collapse collapse" aria-labelledby="RefundThree" data-bs-parent="#RefundFAQ">
             <div class="accordion-body">
               <p>
                 Yes. Please note that cloud subscriptions that renew monthly are set up for automatic renewals by default.
               </p>
             </div>
           </div>
         </div>
         <div class="accordion-item">
           <h2 class="accordion-header" id="RefundFour">
             <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#RefundAnswerFour" aria-expanded="false" aria-controls="RefundAnswerFour">
               Does OfficeGaps have a reseller program?
             </button>
           </h2>
           <div id="RefundAnswerFour" class="accordion-collapse collapse" aria-labelledby="RefundFour" data-bs-parent="#RefundFAQ">
             <div class="accordion-body">
               <p>
                 OfficeGaps does not have an official reseller program. Resellers can purchase our products for their customers directly via our Quote & Order Form.
               </p><p>
                 You do not need to be an approved reseller to resell our products –OfficeGaps does not offer any discounts to resellers.
               </p>
             </div>
           </div>
         </div>
       </div>
       </div>
     <div class="catContents" id="Features">
       <div class="accordion" id="featureFAQ">
         <div class="accordion-item">
           <h2 class="accordion-header" id="featureOne">
             <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#featureAnserOne" aria-expanded="true" aria-controls="featureAnserOne">
               How do I customize my dashboard in OfficeGaps?
             </button>
           </h2>
           <div id="featureAnserOne" class="accordion-collapse collapse show" aria-labelledby="featureOne" data-bs-parent="#featureFAQ">
             <div class="accordion-body">
               <p>
                 Dashboards are one of the things that make OfficeGaps such a powerful ERP solution. To learn how to customize your dashboard to view just the right data, check out our tutorial video on OfficeGaps Dashboard Customization.
               </p>
             </div>
           </div>
         </div>
         <div class="accordion-item">
           <h2 class="accordion-header" id="featureTwo">
             <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#featureAnserTwo" aria-expanded="false" aria-controls="featureAnserTwo">
               How do I create an email template in OfficeGaps?
             </button>
           </h2>
           <div id="featureAnserTwo" class="accordion-collapse collapse" aria-labelledby="featureTwo" data-bs-parent="#featureFAQ">
             <div class="accordion-body">
               <p>
                 Creating email templates in OfficeGaps is usually the next step after creating automated PDFs, and the process of setting them up is similar. However, there are some key differences, particularly with regards to formatting. Check out our tutorial on creating OfficeGaps Email Templates to learn more.
               </p>
             </div>
           </div>
         </div>
         <div class="accordion-item">
           <h2 class="accordion-header" id="featureThree">
             <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#featureAnserThree" aria-expanded="false" aria-controls="featureAnserThree">
               How do I assign roles in OfficeGaps?
             </button>
           </h2>
           <div id="featureAnserThree" class="accordion-collapse collapse" aria-labelledby="featureThree" data-bs-parent="#featureFAQ">
             <div class="accordion-body">
               <p>
                 Having the right permissions and roles is essential to ensuring that the right data is accessible to everyone in your organization. Check out our tutorial video on setting up OfficeGaps Roles & Permissions to learn more.
               </p>
             </div>
           </div>
         </div>
         <div class="accordion-item">
           <h2 class="accordion-header" id="featureTwo">
             <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#featureAnserFour" aria-expanded="false" aria-controls="featureAnserFour">
               Does OfficeGaps have Inventory Management?
             </button>
           </h2>
           <div id="featureAnserFour" class="accordion-collapse collapse" aria-labelledby="featureTwo" data-bs-parent="#featureFAQ">
             <div class="accordion-body">
               <p>
                 Yes! OfficeGaps Inventory module provides a wide range of features to optimize inventory levels/availability and allows you to track and report performance in real-time through dashboards and analytics.
               </p>
             </div>
           </div>
         </div>
         <div class="accordion-item">
           <h2 class="accordion-header" id="featureThree">
             <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#featureAnserFive" aria-expanded="false" aria-controls="featureAnserFive">
               Does OfficeGaps have procurement?
             </button>
           </h2>
           <div id="featureAnserFive" class="accordion-collapse collapse" aria-labelledby="featureThree" data-bs-parent="#featureFAQ">
             <div class="accordion-body">
               <p>
                 By managing vendor relationships, overseeing source-to-pay processes, and controlling spending, OfficeGaps' Procurement module helps your company purchase goods and services at the right time and at the right price.
               </p>
             </div>
           </div>
         </div>
         <div class="accordion-item">
           <h2 class="accordion-header" id="featureTwo">
             <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#featureAnswerSix" aria-expanded="false" aria-controls="featureAnswerSix">
               Does OfficeGaps have an HR Module?
             </button>
           </h2>
           <div id="featureAnswerSix" class="accordion-collapse collapse" aria-labelledby="featureTwo" data-bs-parent="#featureFAQ">
             <div class="accordion-body">
               <p>
                 Yes! By offering integrated HR capabilities, OfficeGaps helps organizations eliminate time-consuming manual tasks and provide more efficient HR and workforce management that puts people first.
               </p>
             </div>
           </div>
         </div>
         <div class="accordion-item">
           <h2 class="accordion-header" id="featureThree">
             <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#featureAnswerSeven" aria-expanded="false" aria-controls="featureAnswerSeven">
               Is OfficeGaps software open source?
             </button>
           </h2>
           <div id="featureAnswerSeven" class="accordion-collapse collapse" aria-labelledby="featureThree" data-bs-parent="#featureFAQ">
             <div class="accordion-body">
               <p>
                 No, OfficeGaps products are proprietary software applications.
               </p>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 </div>
   </div>
</section>
<section class="sections">
<div class="containers">
 <div class="faqOrangeBox">
  <p>
    All the information and help you need to make your experience with our products and services as smooth as possible can be found on our support page. <br> Request assistance, schedule a training session, chat with a product expert, or learn more about our products.
   </p>
   <a [routerLink]="['/support']">
     Discover More
   </a>
 </div>
</div>
</section>
