<div class="hero-inner features">
  <div class="containers">
  <h1>
    <small>
       Features
    </small>
    Easily configure solutions that
    <br>
    suit your business
  </h1>
 </div>
</div>

<div class="containers">
  <div class="floated-title split">
      <article>
    <h2>
       Built-in features for creating business processes from the start
    </h2>
    <p>
       Create and implement custom solutions for your business to generate better insights and drive smarter decisions, thus cutting costs, increasing quality, and ensuring continuous improvement
    </p>
   </article>
   <picture>
       <img class="fromSides" src="../../assets/img/features-with-lap.png" alt="">
   </picture>
  </div>

<div class="bigtitle">
   Develop your business as you see fit
</div>
</div>
<section  class="sections npfull">
 <div class="containers">
<div class="featuresGrid">
   <article class="fromSides">
       <h2>
           Easy configuring of business functions to meet your daily changing demands
       </h2>
       <p>
           Adapt existing solutions or design new ones from scratch to meet your business requirements. Respond to changing needs and challenges within your organization by adding features on the fly.
       </p>
   </article>
   <picture class="fromSides">
       <img src="../../assets/img/features_01.jpg" alt="">
   </picture>
</div>
<div class="featuresGrid mrauto">
   <picture class="fromSides">
       <img src="../../assets/img/features_02.jpg" alt="">
   </picture>
   <article class="fromSides">
       <h2>
           Having trouble remembering passwords?
       </h2>
       <p>
           Access your account quickly, easily, and securely. Data security enhanced through facial recognition enabled by Microsoft Face API technology.
       </p>
   </article>

</div>
<div class="featuresGrid">
   <article class="fromSides">
       <h2>
           Interested in sending Business data by email right away?
       </h2>
       <p>
           Incorporate charts, graphs, key metrics, reports, etc. into your emails. Collaborate via email on business data, insights, reports, and set up notifications and reminders for upcoming events.
       </p>
   </article>
   <picture class="fromSides">
       <img src="../../assets/img/features_03.jpg" alt="">
   </picture>
</div>
<div class="featuresGrid mrauto">
   <picture class="fromSides">
       <img src="../../assets/img/features_04.jpg" alt="">
   </picture>
   <article class="fromSides">
       <h2>
           Finding it difficult to manage all your business documents?
       </h2>
       <p>
           Easily manage, share, and approve all your business documents and reports. Document versions can be managed based on user roles and approvals. Access to documents is possible from anywhere.
       </p>
   </article>

</div>
<div class="featuresGrid">
   <article class="fromSides">
       <h2>
           Difficulty implementing efficient and timely business processes?
       </h2>
      <p>
       Keep your business running smoothly and on schedule. Automate approval processes and assign roles based on hierarchy to eliminate inefficiencies and remove obstacles.
      </p>
   </article>
   <picture class="fromSides">
       <img src="../../assets/img/features_05.jpg" alt="">
   </picture>
</div>
<div class="featuresGrid mrauto">
   <picture class="fromSides">
       <img src="../../assets/img/features_06.jpg" alt="">
   </picture>
   <article class="fromSides">
       <h2>
        Your sensitive data will be handled securely
       </h2>
       <p>
           Control access to financial information and data about your business. Organize users into groups and assign permissions for data access.
       </p>
   </article>

</div>

<div class="featuresGrid">
   <article class="fromSides">
       <h2>
           How can I connect external data sources using API?
       </h2>
      <p>
       Provides access to data from other industry-specific applications. Integrate data across multiple systems to improve productivity and insight and create a single point of truth.
      </p>
   </article>
   <picture class="fromSides">
       <img src="../../assets/img/features_07.jpg" alt="">
   </picture>
</div>
<div class="featuresGrid mrauto">
   <picture class="fromSides">
       <img src="../../assets/img/features_08.jpg" alt="">
   </picture>
   <article class="fromSides">
       <h2>
           Seeking real-time, actionable business insights?
       </h2>
       <p>
           Create, configure, and manage custom dashboards based on user roles. Real-time access to metrics, daily tasks, notifications, etc. in order to generate actionable insights and assess performance.
       </p>
   </article>

</div>

<div class="featuresGrid">
   <article class="fromSides">
       <h2>
           Control how you want to manage your data
       </h2>
      <p>
       Maximize business data utilization. Data pertaining to various aspects of business operations can be stored and controlled to provide a broad range of access to key information
      </p>
   </article>
   <picture class="fromSides">
       <img src="../../assets/img/features_09.jpg" alt="">
   </picture>
</div>
<div class="featuresGrid mrauto">
   <picture class="fromSides">
       <img src="../../assets/img/features_10.jpg" alt="">
   </picture>
   <article class="fromSides">
       <h2>
           Enhance business capabilities with custom forms
       </h2>
       <p>
           Integrates data across diverse business functions. Provides functionality to various processes that rely on master data for their operations.
       </p>
   </article>

</div>

<div class="featuresGrid">
   <article class="fromSides">
       <h2>
           Establish user roles to control access to data
       </h2>
     <p>
       Access to the system is restricted to those who need it to perform their tasks. Several factors can determine access, including authority, responsibility, and job competency
     </p>
   </article>
   <picture class="fromSides">
       <img src="../../assets/img/features_11.jpg" alt="">
   </picture>
</div>
<div class="featuresGrid mrauto">
   <picture class="fromSides">
       <img src="../../assets/img/features_12.jpg" alt="">
   </picture>
   <article class="fromSides">
       <h2>
           Quick and easy business document uploads
       </h2>
       <p>
           Document capture, upload, management, and indexing. Data can now be uploaded directly into your system, eliminating the tedious and error-prone task of manual input.
       </p>
   </article>

</div>

<div class="featuresGrid">
   <article class="fromSides">
       <h2>
           Visualize and explore your data with graphs and charts
       </h2>
     <p>
       Get insight into business data and present it in a meaningful way. Communicate the findings of the data and identify trends quickly to make informed decisions.
     </p>
   </article>
   <picture class="fromSides">
       <img src="../../assets/img/features_13.jpg" alt="">
   </picture>
</div>
<div class="featuresGrid mrauto">
   <picture class="fromSides">
       <img src="../../assets/img/features_14.jpg" alt="">
   </picture>
   <article class="fromSides">
       <h2>
           Safeguard data from cyber threats, leaks, and theft
       </h2>
       <p>
           Easily identify what changes have been made to your system. Provides early detection of system problems before they become critical, such as outages or failures that can hinder productivity.
       </p>
   </article>

</div>

</div>
</section>
<app-pricingbluebar></app-pricingbluebar>
