<div class="hero-inner industry_realestate">
  <div class="containers">
   <h1>
       <small>
        <a [routerLink]="['/industries']">Industries</a> <i class="bi bi-arrow-right"></i> Real Estate
       </small>
       Assists with all phases of the <br> real estate process
     </h1>
 </div>
</div>

<section class="sections">
<div class="containers">
   <div class="indus_details">
       <picture>
           <img src="../../assets/img/bg_realestate.jpg" alt="">
       </picture>
       <article>
           <h2>
               Real Estate
           </h2>
           <p>
               OfficeGaps deals with all aspects of the buyer and seller with strong detailing and accounting tool for real estate. Monitor the creation and management of contracts, dynamic accelerations, CAM restructurings, master reviews, leasing engagements, and metering charges efficiently and effectively. Residents of housing societies benefit from early notification systems to remain engaged in maintenance and other projects.
           </p><p>
By automating and streamlining business processes, such as sales, HR, procurement, and inventory, using the latest technology, it's possible to receive insightful and meaningful data and information. Locating the right property is made easier thanks to our solutions. Also included are extensive features for original acquisition, space management, assignment, ground maintenance, and many others.
</p><p>
In the real estate and construction business sectors, it is designed to eliminate human errors and delays caused by human blunders. You can place an order, make deals, organize your schedule, receive updates on various stages of payments, commissions, etc. In addition, your team is less likely to become engrossed in time-consuming administrative tasks, so you don't have to hire additional workers, since the system handles several activities at once.
           </p>
       </article>
   </div>

   <div class="bordered_columns">
       <div class="box">
         <div class="numb">
             <span>1</span>
           </div>
           <h4>
               Property bank management
           </h4>
          <p>
           Centralized monitoring with a precise view of the business success of all assets under your management
          </p>
       </div>
       <div class="box">
           <div class="numb">
               <span>2</span>
             </div>
             <h4>
               Optimized Finance Management
             </h4>
            <p>
               Real estate industries can track direct financial information from marketing, sales, and purchasing departments in real time
            </p>
         </div>
         <div class="box">
           <div class="numb">
               <span>3</span>
             </div>
             <h4>
               Effective Tenant Retention
             </h4>
            <p>
               Provides features to help determine the tenant retention rate and then improve on it
            </p>
         </div>
   </div>
</div>
</section>
<app-weeklyupdate></app-weeklyupdate>
<section class="sections">
 <div class="sectitle_01">
   <h2>
       Get to know us better
   </h2>
   <p>
       We'll do everything we can to make our next best project!
   </p>
 </div>

 <div class="containers">

   <div class="accordion accordion-flush" id="industrial">
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingOne">
         <button class="accordion-button collapse" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseOne" aria-expanded="false" aria-controls="faq-collapseOne">
           Provide companies/agents with a comprehensive understanding of their clientele
         </button>
       </h2>
       <div id="faq-collapseOne" class="accordion-collapse collapse show" aria-labelledby="faq-headingOne" data-bs-parent="#industrial">
         <div class="accordion-body">
        <p>
           OfficeGaps aids the marketing and sales professionals to store and track all the data related to the customers on a real-time basis, and thereby deliver quality work and meet customer demands on time. It helps you maintain good relationships with your current and potential clients.
        </p>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingTwo">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseTwo" aria-expanded="false" aria-controls="faq-collapseTwo">
           Allows salespeople to follow prospects and track their progress from anywhere
         </button>
       </h2>
       <div id="faq-collapseTwo" class="accordion-collapse collapse" aria-labelledby="faq-headingTwo" data-bs-parent="#industrial">
         <div class="accordion-body">
          <p>
           With OfficeGaps in place, all the relevant data can be stored and updated in one centralized platform that is accessible to everyone. This eliminates manual tracking of the data, breaks the traditional silos within an organisation and improves the efficiency of the business processes.
          </p>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingThree">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseThree" aria-expanded="false" aria-controls="faq-collapseThree">
           Coordinate and manage planned maintenance requests with greater efficiency
         </button>
       </h2>
       <div id="faq-collapseThree" class="accordion-collapse collapse" aria-labelledby="faq-headingThree" data-bs-parent="#industrial">
         <div class="accordion-body">
         <p>
           Predict accurate maintenance expenditure and the timeline required to complete it. Ensure that the actual cost of the maintenance does not exceed much of the projected cost. In this way, the construction or the real estate companies can efficiently utilize the resources and manpower within the allotted budget and time frame.
         </p>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingFour">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseFour" aria-expanded="false" aria-controls="faq-collapseFour">
           Manages Leasing status, Payments plans, Collections, and Agents
         </button>
       </h2>
       <div id="faq-collapseFour" class="accordion-collapse collapse" aria-labelledby="faq-headingFour" data-bs-parent="#industrial">
         <div class="accordion-body">
           <p>
               You can now manage customized email marketing and SMS effortlessly as companies can feed in the complete details about tenants, which can ascertain when required. OfficeGaps manages the time-to-time rent clearance, enabling timely notifications to outstanding customers. These benefits will all result in higher occupancy rates.
           </p>
         </div>
       </div>
     </div>

   </div>

   </div>
   </section>
