<div class="hero-inner customizable">
  <div class="containers">
  <h1>
    <small>
      <a [routerLink]="['/solutions']">Solutions</a> <i class="bi bi-arrow-right"></i> Our Customizable Solutions
    </small>
    Designed Specifically to meet
     <br>
    Client's needs
  </h1>
 </div>
</div>

<div class="containers">
  <div class="floated-title split">
      <article>
    <h2>
       More Customizable Solutions
    </h2>
    <p>
       Our business software solutions help you meet your business needs by customizing existing business solutions or even creating new business module from scratch to fit your needs
    </p>
   </article>
   <picture>
       <img class="fromSides" src="../../assets/img/customizable-lap-banner.png" alt="">
   </picture>
  </div>
</div>

   <div class="containers">
       <div class="linked-icons">
           <a [routerLink]="['/solutions/customizable-solutions']" fragment="Accounting" class="iconbox">
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#accounting"></use>
                 </svg>
                 <h4>
                   Accounting and finance
                 </h4>
               </a>
           <a [routerLink]="['/solutions/customizable-solutions']" fragment="Realestatemanage" class="iconbox">
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#realestate2"></use>
                 </svg>
                 <h4>
                   Real Estate management
                 </h4>
               </a>

           <a [routerLink]="['/solutions/customizable-solutions']" fragment="Inventory" class="iconbox">
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#inventory"></use>
                 </svg>
                 <h4>
                   Inventory management
                 </h4>
               </a>

               <a [routerLink]="['/solutions/customizable-solutions']" fragment="Asset" class="iconbox">
                 <svg>
                     <use xlink:href="../../assets/img/sprite.svg#asset"></use>
                   </svg>
                   <h4>
                       Asset Management
                   </h4>
                 </a>

           <a [routerLink]="['/solutions/customizable-solutions']" fragment="Operations" class="iconbox">
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#operations"></use>
                 </svg>
                 <h4>
                   Operations Management
                 </h4>

               </a>

           <a [routerLink]="['/solutions/customizable-solutions']" fragment="Supplychain" class="iconbox">
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#supplychain"></use>
                 </svg>
                 <h4>
                   Supply Chain Management
                 </h4>
               </a>

           <a [routerLink]="['/solutions/customizable-solutions']" fragment="Gvnt" class="iconbox">
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#gvnt"></use>
                 </svg>
                 <h4>
                   Government Tender management
                 </h4>
               </a>

           <a [routerLink]="['/solutions/customizable-solutions']" fragment="School" class="iconbox">
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#school"></use>
                 </svg>
                 <h4>
                   School and College Information
                 </h4>
               </a>

       </div>

</div>


<section class="sections npbottom" id="Accounting">
   <div class="containers">
       <div class="solution-details">
           <figure class="fromSides">
               <img src="../../assets/img/Accounting-and-finance.jpg" alt="">
               <figcaption>
                   <h2>
                       <span>
                       <svg>
                           <use xlink:href="../../assets/img/sprite.svg#accounting"></use>
                         </svg>
                       </span>
                       Accounting and finance
                   </h2>
                   <p>
                       Communicates financial information clearly to internal teams and external partners in a more concise and clear manner
                   </p>
               </figcaption>
           </figure>
<article class="fromSides">

   <h3>
       Stay on top of your finances
   </h3>
   <ul class="arrowlist">
       <li>Designed to handle your most demanding budgeting and processing needs</li>
<li>Maximizing the efficiency and accuracy of your financial data</li>
<li>Easily track and control your cash flow</li>
<li>Record /track of all financial transactions</li>
<li>Track customer payments</li>
<li>Automate tasks like sending payment reminders</li>
<li>Automate the process of both buying and selling in foreign currencies</li>
<li>Provide a uniform collection of sales and taxes</li>
<li>Analyse and manage your organization’s financial risks</li>
<li>Automates the financial process and reduces manual work</li>
   </ul>
</article>

       </div>
   </div>
</section>

<section class="sections npbottom" id="Realestatemanage">
   <div class="containers">
       <div class="solution-details">
<article class="fromSides">
   <h3>
       Cloud based Property management solution
   </h3>
   <ul class="arrowlist">
       <li>Quick and wise management of property sales</li>
       <li>Smooth and quick processing of invoices</li>
       <li>Building maintenance</li>
       <li>Improved management of the land bank</li>
       <li>Improved cash flow</li>
       <li>Accurate overview of business finances</li>
       <li>Maintain updated records of all your properties</li>
       <li>Helps you to manage marketing campaigns effectively</li>
       <li>Digital preservation of the exchanges you have with each of your customers</li>
   </ul>
</article>
<figure class="fromSides">
   <img src="../../assets/img/Real-estate-Management.jpg" alt="">
   <figcaption>
       <h2>
           <span>
           <svg>
               <use xlink:href="../../assets/img/sprite.svg#realestate2"></use>
             </svg>
           </span>
           Real estate Management
       </h2>
      <p>
       Manage processes related to leasing, property sales, sub-contractor management and facilities management
      </p>
   </figcaption>
</figure>
       </div>
   </div>
</section>

<section class="sections npbottom" id="Inventory">
   <div class="containers">
       <div class="solution-details">
           <figure class="fromSides">
               <img src="../../assets/img/Inventory-management.jpg" alt="">
               <figcaption>
                   <h2>
                       <span>
                       <svg>
                           <use xlink:href="../../assets/img/sprite.svg#inventory"></use>
                         </svg>
                       </span>
                       Inventory management
                   </h2>
                   <p>Inventory Management solution that helps you reduce costs associated with inventory, giving you real-time visibility and tracking of your inventory as it moves from order to delivery

                   </p>
               </figcaption>
           </figure>
<article class="fromSides">

   <h3>
       Optimize storage and space utilization
   </h3>
   <ul class="arrowlist">
       <li>Optimizes and streamlines the entire inventory activities</li>
<li>Can see the stocks of multiple warehouses and their location</li>
<li>You can have audits of your inventory along with the result and analysis</li>
<li>Assists in analysing the items according to their cost, demand, and supply</li>
<li>Set up the validation methods along with expiring dates for each item</li>
<li>It can measure the items in different units</li>
<li>Efficiently tracks the goods using batch numbers along with other details</li>
<li>Handling material rejections</li>
<li>Alternate items for production planning</li>
   </ul>
</article>

       </div>

   </div>
</section>

<section class="sections npbottom" id="Asset">
   <div class="containers">
       <div class="solution-details">
           <figure class="fromSides">
               <img src="../../assets/img/Asset-Management.jpg" alt="">
               <figcaption>
                   <h2>
                       <span>
                       <svg>
                           <use xlink:href="../../assets/img/sprite.svg#asset"></use>
                         </svg>
                       </span>
                       Asset Management
                   </h2>
                   <p>
                    As part of Asset Management, you can track the purchase and sale of assets, schedule and track scheduled and preventive maintenance, and monitor depreciation and asset costs
                   </p>
               </figcaption>
           </figure>
<article class="fromSides">

   <h3>
       Optimize your asset lifecycle processes
   </h3>
   <ul class="arrowlist">
       <li>Lifecycle management tracks assets from acquisition through final disposition</li>
<li>Track asset maintenance records with instant access</li>
<li>One Centralised System</li>
<li>Identify all assets within the same network</li>
<li>Track, manage and control inventory all from a single dashboard</li>
<li>Tracking the whereabouts of assets from department to department</li>
<li>Perform regular maintenance on its assets</li>
<li>Allowing IT technicians to prioritise issues and respond accordingly</li>
<li>Setup is simplified through templates that import existing assets</li>
<li>Simplify financial reporting with automatic generation of business reports</li>

   </ul>
</article>

       </div>
   </div>
</section>

<section class="sections npbottom" id="Operations">
   <div class="containers">
       <div class="solution-details">
<article class="fromSides">
   <h3>
       Drives to achieve Operational Excellence
   </h3>
   <ul class="arrowlist">
       <li>Online maintenance and availability of procedures and work area instructions</li>
<li>Tracks and manages job related costs throughout the manufacturing process</li>
<li>Enables effective scheduling and load balancing of resources and work centres</li>
<li>Facilitates the orderly definition and management of related work order hierarchies</li>
<li>Allows direct linking between purchase order supply and work order operation</li>
<li>Tooling use and lifecycle management</li>
<li>Integrated, online corrective action requests</li>
<li>Linkage of procedures or work area instruction with processes and tools</li>
<li>Reduces inventory costs</li>
<li>Planning and production KPI monitoring lead to increased operational reliability</li>

   </ul>
</article>
<figure class="fromSides">
   <img src="../../assets/img/Operations-management.jpg" alt="">
   <figcaption>
       <h2>
           <span>
           <svg>
               <use xlink:href="../../assets/img/sprite.svg#operations"></use>
             </svg>
           </span>
           Operations management
       </h2>
      <p>
       Organizations can streamline their operations so that they invest in the right inventory, ensure quality, allocate resources and personnel more efficiently, and improve communication
      </p>
   </figcaption>
</figure>
       </div>
   </div>
</section>

<section class="sections npbottom" id="Supplychain">
   <div class="containers">
       <div class="solution-details">
           <figure class="fromSides">
               <img src="../../assets/img/Help_Desk.jpg" alt="">
               <figcaption>
                   <h2>
                       <span>
                       <svg>
                           <use xlink:href="../../assets/img/sprite.svg#supplychain"></use>
                         </svg>
                       </span>
                       Supply chain management
                   </h2>
                   <p>
                       Achieve a dynamic, resilient, and efficient supply chain using real-time supplier transaction insights, giving you complete visibility into your entire supply chain
                   </p>
               </figcaption>
           </figure>
<article class="fromSides">

   <h3>
       Manage a variety of supply chain processes
   </h3>
   <ul class="arrowlist">
       <li>Create centralized records for buyers, parts, and suppliers</li>
<li>Reduce transaction times with automated tools</li>
<li>Make smarter and more informed sourcing decisions</li>
<li>Optimize processes and improve efficiency</li>
<li>Efficiency, speed, and a consistent interface promote the rapid processing at every stage</li>
<li>Purchase orders with accuracy and visibility can improve business reliability</li>
<li>Reduces annual audit</li>
<li>Meeting customer expectations becomes easy with superior visibility</li>
<li>Visual tools make interacting with supply chain demand data efficient and easy</li>
<li>Forecasting and planning tools enables collaboration consolidation and aggregation</li>
   </ul>
</article>

       </div>
   </div>
</section>

<section class="sections npbottom" id="Gvnt">
   <div class="containers">
       <div class="solution-details">
<article class="fromSides">
   <h3>
       Streamline a variety of tender processes
   </h3>
   <ul class="arrowlist">
       <li>Handles pre-sales and sales activities of the organization</li>
<li>Prospect tracking through various stages</li>
<li>Marketing surveys for estimating the demand for various products</li>
<li>Target setting for executives</li>
<li>Order amendment history</li>
<li>Order scheduling over a period and tracking delivery schedule</li>
<li>Order tracking through status and transaction reports</li>
<li>Tracking sales returns</li>
<li>Flexibility to define customer-specific prices for products</li>
<li>Multiple dispatch locations for customers and dealers</li>
   </ul>
</article>
<figure class="fromSides">
   <img src="../../assets/img/Government_management.jpg" alt="">
   <figcaption>
       <h2>
           <span>
           <svg>
               <use xlink:href="../../assets/img/sprite.svg#gvnt"></use>
             </svg>
           </span>
           Government tender management
       </h2>
      <p>
        Tender management systems enable easy preparation of tender specifications, tender aggregation, evaluation, and placement of contracts
      </p>
   </figcaption>
</figure>
       </div>
   </div>
</section>

<section class="sections npbottom" id="School">
   <div class="containers">
       <div class="solution-details nbbottom">
           <figure class="fromSides">
               <img src="../../assets/img/customizable-education.jpg" alt="">
               <figcaption>
                   <h2>
                       <span>
                       <svg>
                           <use xlink:href="../../assets/img/sprite.svg#school"></use>
                         </svg>
                       </span>
                       School and college Information Management
                   </h2>
                  <p>
                   Simplifies the administrative operations to improve productivity, efficiency, and communication between your institution and parents, teachers, and students
                  </p>
               </figcaption>
           </figure>
<article class="fromSides">

   <h3>
       Plan, organize, and manage complex projects
   </h3>
   <ul class="arrowlist">
       <li>Saves time of teachers and administrators from regular admin works</li>
<li>Communicate with the parents quickly and efficiently</li>
<li>Takes care of Admission management, Staff management and payroll</li>
<li>Manages entire data of a student life cycle</li>
<li>Helps in creating and managing different types of timetables</li>
<li>Automate the examination management</li>
<li>Fee collection and receipt generation is simplified and digitized</li>
<li>Integrates perfectly with attendance and student-tracking devices</li>
<li>Access to the previous year’s expense and income data helps to manage budgeting</li>
<li>Library management processes is efficient</li>
   </ul>
</article>

       </div>
   </div>
</section>

<app-getstart></app-getstart>
