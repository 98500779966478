<div class="hero-inner solutions">
  <div class="containers">
  <h1>
    <small>
       Solutions
    </small>
    Our solutions improve day by day
    <br>
    to make your life easier
  </h1>
 </div>
</div>

<div class="containers">
   <div class="floated-title centered">
       <h2>
       Ready to Deploy Solutions
       </h2>
       <p>
           Get your business ready on schedule and on budget with our ready-to-deploy solutions.
       </p>
       <p>
           Faster to implement, it is a cost-effective and reliable approach.
       </p>
     </div>
</div>
<section class="sections pt-0">
<div class="containers">
   <div class="solutionlist">
       <div class="listbox">
           <span>
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#recruitment"></use>
                 </svg>
           </span>

           <h4>
               Recruitment
           </h4>
           <p>
               Helping HR find and hire the best employees for the job
           </p>
               <a class="textlink-2" [routerLink]="['/solutions/deployable-solutions']" fragment="Recruitment">Discover More &raquo;</a>

       </div>
       <div class="listbox">
           <span>
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#employeemanage"></use>
                 </svg>
           </span>

           <h4>
               Employee Management
           </h4>
           <p>
               Reduce employee management complexities
           </p>
               <a class="textlink-2" [routerLink]="['/solutions/deployable-solutions']" fragment="empmanage">Discover More &raquo;</a>

       </div>
       <div class="listbox">
           <span>
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#crm"></use>
                 </svg>
           </span>

           <h4>
               CRM
           </h4>
           <p>
               Acquiring and retaining customers effectively
           </p>
               <a class="textlink-2" [routerLink]="['/solutions/deployable-solutions']" fragment="crm">Discover More &raquo;</a>

       </div>
       <div class="listbox">
           <span>
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#procurement"></use>
                 </svg>
           </span>

           <h4>
               Procurement
           </h4>
           <p>
               Collaborate seamlessly with your suppliers
           </p>
               <a class="textlink-2" [routerLink]="['/solutions/deployable-solutions']" fragment="Procurement">Discover More &raquo;</a>

       </div>
       <div class="listbox">
           <span>
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#sales"></use>
                 </svg>
           </span>

           <h4>
               Sales
           </h4>
           <p>
               Enhance your business revenue streams
           </p>
               <a class="textlink-2" [routerLink]="['/solutions/deployable-solutions']" fragment="Sales">Discover More &raquo;</a>

       </div>
       <div class="listbox">
           <span>
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#helpdesk"></use>
                 </svg>
           </span>

           <h4>
               Help Desk
           </h4>
           <p>
               Effortlessly resolve customer complaints
           </p>
               <a class="textlink-2" [routerLink]="['/solutions/deployable-solutions']" fragment="Helpdesk">Discover More &raquo;</a>

       </div>
       <div class="listbox">
           <span>
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#projects"></use>
                 </svg>
           </span>

           <h4>
               Projects
           </h4>
           <p>
               Plan, organize, and manage complex projects
           </p>
               <a class="textlink-2" [routerLink]="['/solutions/deployable-solutions']" fragment="Projects">Discover More &raquo;</a>

       </div>
       <div class="listbox">
           <span>
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#reports"></use>
                 </svg>
           </span>

           <h4>
               Reports
           </h4>
           <p>
               Data-driven business intelligence for leaders
           </p>
               <a class="textlink-2" [routerLink]="['/solutions/deployable-solutions']" fragment="Reports">Discover More &raquo;</a>

       </div>
   </div>
</div>
</section>

<app-weeklyupdate></app-weeklyupdate>

<div class="containers">
<div class="floated-title centered notrans">
   <h2>
       More Customizable Solutions
   </h2>
   <p>
    Our business software solutions help you to meet your business needs by customizing existing business solutions <br> or even creating new business module from scratch to fit your needs.
   </p>
 </div>
</div>
<section class="sections">
<div class="containers">
   <div class="solutionlist">
       <div class="listbox">
           <span>
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#accounting"></use>
                 </svg>
           </span>

           <h4>
               Accounting and finance
           </h4>
           <p>
               Manage and streamline your financial operation
           </p>
               <a class="textlink-2" [routerLink]="['/solutions/customizable-solutions']" fragment="Accounting">Discover More &raquo;</a>

       </div>
       <div class="listbox">
           <span>
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#realestate2"></use>
                 </svg>
           </span>

           <h4>
               Real Estate management
           </h4>
           <p>
               Cloud based Property management solution
           </p>
               <a class="textlink-2" [routerLink]="['/solutions/customizable-solutions']" fragment="Realestatemanage">Discover More &raquo;</a>

       </div>
       <div class="listbox">
           <span>
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#inventory"></use>
                 </svg>
           </span>

           <h4>
               Inventory management
           </h4>
           <p>
               Optimize storage and space utilization
           </p>
               <a class="textlink-2" [routerLink]="['/solutions/customizable-solutions']" fragment="Inventory">Discover More &raquo;</a>

       </div>
       <div class="listbox">
           <span>
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#asset"></use>
                 </svg>
           </span>

           <h4>
               Asset Management
           </h4>
           <p>
               Minimize the cost of acquiring, maintaining and operating assets
           </p>
               <a class="textlink-2" [routerLink]="['/solutions/customizable-solutions']" fragment="Asset">Discover More &raquo;</a>

       </div>
       <div class="listbox">
           <span>
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#operations"></use>
                 </svg>
           </span>

           <h4>
               Operations Management
           </h4>
           <p>
               Drives to achieve Operational Excellence
           </p>
               <a class="textlink-2" [routerLink]="['/solutions/customizable-solutions']" fragment="Operations">Discover More &raquo;</a>

       </div>
       <div class="listbox">
           <span>
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#supplychain"></use>
                 </svg>
           </span>

           <h4>
               Supply Chain Management
           </h4>
           <p>
               Manage a variety of supply chain processes
           </p>
               <a class="textlink-2" [routerLink]="['/solutions/customizable-solutions']" fragment="Supplychain">Discover More &raquo;</a>

       </div>
       <div class="listbox">
           <span>
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#gvnt"></use>
                 </svg>
           </span>

           <h4>
               Government Tender management
           </h4>
           <p>
               Streamline a variety of tender processes
           </p>
               <a class="textlink-2" [routerLink]="['/solutions/customizable-solutions']" fragment="Gvnt">Discover More &raquo;</a>

       </div>
       <div class="listbox">
           <span>
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#school"></use>
                 </svg>
           </span>

           <h4>
               School and College Information
           </h4>
           <p>
               Manage your Institution’s Online Portal Efficiently
           </p>
               <a class="textlink-2" [routerLink]="['/solutions/customizable-solutions']" fragment="School">Discover More &raquo;</a>

       </div>
   </div>
</div>
</section>

<app-pricingbluebar></app-pricingbluebar>

<app-blogslider></app-blogslider>
