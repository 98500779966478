import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

     // scroll to top

     var scrollToTopBtn = <HTMLElement>document.querySelector(".scrollToTopBtn");
     var rootElement = <HTMLElement>document.documentElement;

     function handleScroll() {
       // Do something on scroll
       var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
       if (rootElement.scrollTop / scrollTotal > 0.22) {
         // Show button
         scrollToTopBtn.classList.add("showBtn");
       } else {
         // Hide button
         scrollToTopBtn.classList.remove("showBtn");
       }
     }

     function scrollToTop() {
       // Scroll to top logic
       rootElement.scrollTo({
         top: 0,
         behavior: "smooth"
       });
     }
     scrollToTopBtn.addEventListener("click", scrollToTop);
     document.addEventListener("scroll", handleScroll);


//cookies

  var infoBar = <HTMLElement>document.querySelector(".cookies-infobar");
  var btnAccept = <HTMLElement>document.querySelector("#cookies-infobar-accept");

  // Check if user has already accepted the notification
  if(wasAccepted()) {
    hideInfobar();
    return;
  }

  //listen for the click event on Accept button
  btnAccept.addEventListener("click", function (e) {
    e.preventDefault();
    hideInfobar();
    saveAcceptInCookies(7);
  });

  //hide cookie info bar
  function hideInfobar () {
    infoBar.className = infoBar.classList.value + " cookies-infobar_accepted";
  }

  // Check if user has already accepted the notification
  function wasAccepted () {
    return checkCookie() === "1";
  }

  // get cookie
  function checkCookie () {
    var name = "cookieInfoHidden=";
    var cookies = document.cookie.split(';');

    for(var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        while (cookie.charAt(0)==' ') {
          cookie = cookie.substring(1);
        }

        if (cookie.indexOf(name) === 0) {
          return cookie.substring(name.length, cookie.length);
        }
    }
    return "";
  };

  //save cookie
  function saveAcceptInCookies (daysOfValidity:any) {
    let now = new Date();
    let time = now.getTime() + (daysOfValidity * 24 * 60 * 60 * 1000);
    let newTime = new Date(now.setTime(time));

    let newTimestr = newTime.toUTCString();

    document.cookie = "cookieInfoHidden=1; expires=" + newTimestr + "; path=/";
  }

}
}
