<div class="hero-inner industry_government">
  <div class="containers">
   <h1>
       <small>
        <a [routerLink]="['/industries']">Industries</a> <i class="bi bi-arrow-right"></i> Government
       </small>
       Digitize strategic citizen initiatives
     </h1>
 </div>
</div>

<section class="sections">
<div class="containers">
   <div class="indus_details">
       <picture>
           <img src="../../assets/img/bg_gov.jpg" alt="">
       </picture>
       <article>
           <h2>
               Government
           </h2>
           <p>
               OfficeGaps empowers federal, state and local government with the technology they need to improve budget formation, allocation of resources, and how they respond to demand. A variety of solutions are included for <strong> fiscal management, procurement, asset management</strong>, human resources, <strong>tax management</strong>, and payroll.
               </p><p>
               Using OfficeGaps, the public sector can take advantage of world-class security and threat protection services, meeting critical compliance standards and exceeding government regulatory requirements. With all functions occurring on one single, centralized system, several departments can now participate in cross-departmental processes in a way that preserves consistency and improves the quality of government data.
                   </p><p>
Unlike other providers, we combine our modular solutions with secure cloud infrastructure and extensive integration  capabilities to deliver flexible, seamless solutions. Having provided ERP solutions to government for more than 17 years, our track record of success includes Government, Aerospace and Defense sectors.

           </p>
       </article>
   </div>

   <div class="bordered_columns">
       <div class="box">
         <div class="numb">
             <span>1</span>
           </div>
           <h4>
               Intuitive citizen experience
           </h4>
           <p>
               Human-centric design that improves user experience, minimizes training and meets accessibility needs
           </p>
       </div>
       <div class="box">
           <div class="numb">
               <span>2</span>
             </div>
             <h4>
               Secure Software
             </h4>
             <p>
               Secure your data with the most stringent controls and FedRamp standards, as well as smart monitoring tools and processes
             </p>
         </div>
         <div class="box">
           <div class="numb">
               <span>3</span>
             </div>
             <h4>
               Government Compliant
             </h4>
             <p>
               Stays current with shifting or new regulatory requirements and meets all federal government compliance mandates
             </p>
         </div>
   </div>
</div>
</section>
<app-weeklyupdate></app-weeklyupdate>
<section class="sections">
 <div class="sectitle_01">
   <h2>
       Get to know us better
   </h2>
   <p>
       We'll do everything we can to make our next best project!
   </p>
 </div>

 <div class="containers">

   <div class="accordion accordion-flush" id="industrial">
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingOne">
         <button class="accordion-button collapse" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseOne" aria-expanded="false" aria-controls="faq-collapseOne">
           Scalable management solutions that enable cost-effective mission execution
         </button>
       </h2>
       <div id="faq-collapseOne" class="accordion-collapse collapse show" aria-labelledby="faq-headingOne" data-bs-parent="#industrial">
         <div class="accordion-body">
           <p>
               With all functions occurring on one single, centralized system, several departments can now participate in cross-departmental processes in a way that preserves consistency and improves the quality of government data.
           </p>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingTwo">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseTwo" aria-expanded="false" aria-controls="faq-collapseTwo">
           Highly configurable, multi-functional solutions with FedRAMP compliant hosting
         </button>
       </h2>
       <div id="faq-collapseTwo" class="accordion-collapse collapse" aria-labelledby="faq-headingTwo" data-bs-parent="#industrial">
         <div class="accordion-body">
           <p>
               For state and local governments, reducing cybersecurity risks is a top priority. Using OfficeGaps, the public sector is able to take advantage of world-class security and threat protection services, meeting critical compliance standards and exceeding government regulatory requirements.
           </p>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingThree">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseThree" aria-expanded="false" aria-controls="faq-collapseThree">
           Ownership costs and up-front investments can be reduced to benefit public services
         </button>
       </h2>
       <div id="faq-collapseThree" class="accordion-collapse collapse" aria-labelledby="faq-headingThree" data-bs-parent="#industrial">
         <div class="accordion-body">
          <p>
           Cloud computing provides a more secure environment than on-premises systems, and it allows an organization to stay current with its technology without spending a lot of money on hardware and software upgrades.
          </p>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingFour">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseFour" aria-expanded="false" aria-controls="faq-collapseFour">
           Analyze your organization in real time for deeper insights
         </button>
       </h2>
       <div id="faq-collapseFour" class="accordion-collapse collapse" aria-labelledby="faq-headingFour" data-bs-parent="#industrial">
         <div class="accordion-body">
           <p>
               Data is a critical asset for any government. It offers the opportunity to deliver insights into citizen requirements and behaviors, functioning of related agencies etc. all of which enable evidence-based decision making.
           </p>
         </div>
       </div>
     </div>

   </div>

   </div>
   </section>

