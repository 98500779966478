import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blogslider',
  templateUrl: './blogslider.component.html',
  styleUrls: ['./blogslider.component.scss']
})
export class BlogsliderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
