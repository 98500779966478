import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BlogsliderComponent } from './blogslider/blogslider.component';
import { WeeklyupdateComponent } from './weeklyupdate/weeklyupdate.component';
import { PricingbluebarComponent } from './pricingbluebar/pricingbluebar.component';
import { SolutionsComponent } from './solutions/solutions/solutions.component';
import { DeployableComponent } from './solutions/deployable/deployable.component';
import { CustomizableComponent } from './solutions/customizable/customizable.component';
import { GetstartComponent } from './getstart/getstart.component';
import { IndustriesComponent } from './industries/industries/industries.component';
import { GovernmentComponent } from './industries/government/government.component';
import { EducationComponent } from './industries/education/education.component';
import { HealthcareComponent } from './industries/healthcare/healthcare.component';
import { ProfessionalComponent } from './industries/professional/professional.component';
import { FinancialComponent } from './industries/financial/financial.component';
import { RealestateComponent } from './industries/realestate/realestate.component';
import { HospitalityComponent } from './industries/hospitality/hospitality.component';
import { ItesComponent } from './industries/ites/ites.component';
import { FeaturesComponent } from './features/features.component';
import { PricingComponent } from './pricing/pricing.component';
import { ContactComponent } from './contact/contact.component';
import { FaqComponent } from './faq/faq.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { DesclaimerComponent } from './desclaimer/desclaimer.component';
import { BlogComponent } from './blog/blog/blog.component';
import { SupportComponent } from './support/support/support.component';
import { BlogcontentsComponent } from './blogcontents/blogcontents.component';
import { CookiepolicyComponent } from './cookiepolicy/cookiepolicy.component';
import { HelpcenterComponent } from './helpcenter/helpcenter/helpcenter.component';
import { AccountsComponent } from './helpcenter/accounts/accounts.component';
import { IntegrationComponent } from './helpcenter/integration/integration.component';
import { ManageteamComponent } from './helpcenter/manageteam/manageteam.component';
import { VerificationComponent } from './helpcenter/verification/verification.component';
import { UnleashedComponent } from './helpcenter/unleashed/unleashed.component';
import { BloglinksComponent } from './bloglinks/bloglinks.component';
import { GettingstartedComponent } from './helpcenter/gettingstarted/gettingstarted.component';
import { TrailformComponent } from './trailform/trailform.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    BlogsliderComponent,
    WeeklyupdateComponent,
    PricingbluebarComponent,
    SolutionsComponent,
    DeployableComponent,
    CustomizableComponent,
    GetstartComponent,
    IndustriesComponent,
    GovernmentComponent,
    EducationComponent,
    HealthcareComponent,
    ProfessionalComponent,
    FinancialComponent,
    RealestateComponent,
    HospitalityComponent,
    ItesComponent,
    FeaturesComponent,
    PricingComponent,
    ContactComponent,
    FaqComponent,
    PrivacyComponent,
    DesclaimerComponent,
    BlogComponent,
    SupportComponent,
    BlogcontentsComponent,
    CookiepolicyComponent,
    HelpcenterComponent,
    AccountsComponent,
    IntegrationComponent,
    ManageteamComponent,
    VerificationComponent,
    UnleashedComponent,
    BloglinksComponent,
    GettingstartedComponent,
    TrailformComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
