<div id="heroSlides" class="heroSlides carousel slide carousel-fade" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item slide_001 active">
      <div class="container">
      <h2>
        Bridge your business gaps <span> with our SaaS Solution</span>
      </h2>
      <button class="underline-links" data-bs-toggle="modal" data-bs-target="#producTour">
        Free Product Tour
      </button>
    </div>
    </div>
    <div class="carousel-item slide_002">
      <div class="container">
        <h2>
          Start up? Have an idea?   <span> 90 days with OfficeGaps</span>
        </h2>
      <button class="underline-links" data-bs-toggle="modal" data-bs-target="#producTour">
        Free Product Tour
      </button>
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#heroSlides" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#heroSlides" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>


  <section class="sections">
    <div class="sectitle_01">
      <h2>
        Highly customizable solutions designed with quick & easy <span>deployment in mind to meet the specific needs of any industry</span>
      </h2>
    </div>

    <div class="containers">
      <div class="homecards cardyellow">

        <div class="txts">
          <h2>
            Benefits of <span> OfficeGaps</span> for your business
          </h2>
  <ul class="starlist">
    <li>Rapid Deployment in 90 Days or Less</li>
<li>Wide range of Ready to deploy and Customizable solutions</li>
<li>Secure data access with facial recognition and captcha-verified logins</li>
<li>Automation of business processes in the cloud</li>

  </ul>
</div>
        <picture>
          <img src="../../assets/img/benefits_char.png" alt="">
        </picture>
        </div>
    </div>
    </section>

    <section class="sections light-pink-2">
      <div class="containers">
<div class="homerecruitment">
  <div class="sectitle_01">
    <h2>
      Recruitment
    </h2>
    <p>
      Boost your employee hiring success by sourcing, tracking, and hiring top talent that aligns with your business on a centralized, automated platform.
    </p>

      <a  class="underline-links black" [routerLink]="['/solutions/deployable-solutions']" fragment="Recruitment">Learn More</a>

  </div>
  <div class="recruitboxes">
    <div class="box">
      <span class="violets">
        <svg>
          <use xlink:href="../../assets/img/sprite.svg#requisitionew"></use>
        </svg>
      </span>
      <h4>
        Requisition
      </h4>
      <p>
        Create requests for job vacancies
      </p>
    </div>
    <div class="box">
      <span class="pinks">
        <svg>
          <use xlink:href="../../assets/img/sprite.svg#recruitmentnew"></use>
        </svg>
      </span>
      <h4>
        Recruitment
      </h4>
      <p>
        Hiring- Source, interview, and shortlist candidates
      </p>
    </div>
    <div class="box">
      <span class="greens">
        <svg>
          <use xlink:href="../../assets/img/sprite.svg#joboffer"></use>
        </svg>
      </span>
      <h4>
        Job Offers
      </h4>
      <p>
        Contracts- Streamline job offers and documentation
      </p>
    </div>
    <div class="box">
      <span class="aquas">
        <svg>
          <use xlink:href="../../assets/img/sprite.svg#databasenew"></use>
        </svg>
      </span>
      <h4>
        Database
      </h4>
      <p>
        Prospects- Store qualified resumes for future hiring
      </p>
    </div>


  </div>
</div>
        </div>
      </section>

      <section class="sections">
        <div class="sectitle_01">
          <h2>
            Employee Management
          </h2>
          <p>
            Stay organized at all times with an intuitive module that provides all employee related information readily available at your fingertips.
          </p>
          <a  class="underline-links black center" [routerLink]="['/solutions/deployable-solutions']" fragment="empmanage">Learn More</a>
        </div>
        <div class="containers">

          <div class="employboxes">
            <div class="box">
              <span class="oranges">
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#profiledatanew"></use>
                </svg>
              </span>
              <div class="text">
              <h4>
                Profile/Data
              </h4>
              <p>
                Manage details of each employee
              </p>
            </div>
            </div>
            <div class="box">
              <span class="blues">
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#todolistnew"></use>
                </svg>
              </span>
              <div class="text">
              <h4>
                To Do List
              </h4>
              <p>
                Track progress of projects and tasks
              </p>
            </div>
            </div>
            <div class="box">
              <span class="greens">
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#timesheetnew"></use>
                </svg>
              </span>
              <div class="text">
              <h4>
                Timesheet
              </h4>
              <p>
                Record time utilization on the job
              </p>
            </div>
            </div>
            <div class="box">
              <span class="violets">
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#expensenew"></use>
                </svg>
              </span>
              <div class="text">
              <h4>
                Expenses
              </h4>
              <p>
                Submit expense claims for approval
              </p>
            </div>
            </div>


          </div>
        </div>
        </section>

        <section class="sections bluewhite">
          <div class="containers">
    <div class="homecrm">
      <div class="sectitle_01">
        <h2>
          CRM
        </h2>
        <p>
          Manage, automate, and analyse all marketing, prospects, opportunities, sales, customer service and existing customer retention processes.
        </p>

          <a  class="underline-links black" [routerLink]="['/solutions/deployable-solutions']" fragment="crm">Learn More</a>

      </div>
      <div class="crmboxes">
        <div class="box">
          <span class="">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#leadsnew"></use>
            </svg>
          </span>
          <div class="texts">
          <h4>
            Leads
          </h4>
          <p>
            Acquire, manage, and nurture leads
          </p>
        </div>
      </div>
        <div class="box">
          <span class="">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#oppertunitynew"></use>
            </svg>
          </span>
          <div class="texts">
          <h4>
            Opportunities
          </h4>
          <p>
            Identify and convert sales potential
          </p>
        </div>
      </div>
        <div class="box">
          <span class="">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#prposalnew"></use>
            </svg>
          </span>
          <div class="texts">
          <h4>
            Proposals
          </h4>
          <p>
            Create, track, and manage proposals
          </p>
        </div>
      </div>
        <div class="box">
          <span class="">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#closuresnew"></use>
            </svg>
          </span>
          <div class="texts">
          <h4>
            Closures
          </h4>
          <p>
            Sign off and handover completed projects
          </p>
        </div>
      </div>

      </div>
    </div>
            </div>
          </section>

        <section class="sections">
          <div class="sectitle_01">
            <h2>
              Procurement
            </h2>
            <p>
              Optimize procurement processes and patterns while improving quality along with reducing procurement time and costs to gain a competitive edge
            </p>
            <a  class="underline-links black center" [routerLink]="['/solutions/deployable-solutions']" fragment="Procurement">Learn More</a>
          </div>
          <div class="containers">

            <div class="procurementboxes">
              <div class="box">
                <span class="blues">
                  <svg>
                    <use xlink:href="../../assets/img/sprite.svg#purchasenew"></use>
                  </svg>
                </span>
                <div class="text">
                <h4>
                  Purchases
                </h4>
                <p>
                  Acquire external goods and services
                </p>
              </div>
              </div>
              <div class="box">
                <span class="pinks">
                  <svg>
                    <use xlink:href="../../assets/img/sprite.svg#vendorsnew"></use>
                  </svg>
                </span>
                <div class="text">
                <h4>
                  Vendors
                </h4>
                <p>
                  Manage and collaborate with vendors
                </p>
              </div>
              </div>
              <div class="box">
                <span class="violets">
                  <svg>
                    <use xlink:href="../../assets/img/sprite.svg#quotesnew"></use>
                  </svg>
                </span>
                <div class="text">
                <h4>
                  Quotes
                </h4>
                <p>
                  Create, track, and manage purchase quotes
                </p>
              </div>
              </div>
              <div class="box">
                <span class="aquas">
                  <svg>
                    <use xlink:href="../../assets/img/sprite.svg#assetsnew"></use>
                  </svg>
                </span>
                <div class="text">
                <h4>
                  Assets
                </h4>
                <p>
                  Simplify asset management processes
                </p>
              </div>
              </div>


            </div>
          </div>
          </section>

    <div class="industry-wave-pattern forHome">
      <div class="containers">
        <div class="sectitle_02 white">
          <h2>Our Industries </h2>
          <hr>
        </div>
          <div class="linked-icons">
              <a [routerLink]="['/industries/government']" class="iconbox">
                  <svg>
                      <use xlink:href="../../assets/img/sprite.svg#gvnt"></use>
                    </svg>
                    <h4>
                      Government
                    </h4>
                  </a>
              <a [routerLink]="['/industries/professional-services']" class="iconbox">
                  <svg>
                      <use xlink:href="../../assets/img/sprite.svg#professional"></use>
                    </svg>
                    <h4>
                      Professional Services
                    </h4>
                  </a>

              <a [routerLink]="['/industries/education']" class="iconbox">
                  <svg>
                      <use xlink:href="../../assets/img/sprite.svg#education"></use>
                    </svg>
                    <h4>
                      Education
                    </h4>
                  </a>

                  <a [routerLink]="['/industries/healthcare']" class="iconbox">
                    <svg>
                        <use xlink:href="../../assets/img/sprite.svg#health"></use>
                      </svg>
                      <h4>
                        Healthcare
                      </h4>
                    </a>

              <a [routerLink]="['/industries/financial-services']" class="iconbox">
                  <svg>
                      <use xlink:href="../../assets/img/sprite.svg#finance"></use>
                    </svg>
                    <h4>
                      Financial services
                    </h4>

                  </a>

              <a [routerLink]="['/industries/real-estate']" class="iconbox">
                  <svg>
                      <use xlink:href="../../assets/img/sprite.svg#realestate"></use>
                    </svg>
                    <h4>
                      Real estate
                    </h4>
                  </a>

              <a [routerLink]="['/industries/travel-and-hospitality']" class="iconbox">
                  <svg>
                      <use xlink:href="../../assets/img/sprite.svg#hospitality"></use>
                    </svg>
                    <h4>
                      Travel and hospitality
                    </h4>
                  </a>

              <a [routerLink]="['/industries/IT-and-ITES']" class="iconbox">
                  <svg>
                      <use xlink:href="../../assets/img/sprite.svg#itites"></use>
                    </svg>
                    <h4>
                      IT and ITES
                    </h4>
                  </a>

          </div>
          <div class="justflex center">
            <a class="large-btn" [routerLink]="['/industries']">Learn More</a>
        </div>
  </div>
     </div>
     <!-- <section class="sections npfull">
     <div class="containers">
       <div class="solutionotes">
         <article>
           <h1>
            Flexible solutions for adapting to your business needs
           </h1>
         </article>
         <article class="fromSides">
          <h5>
            Ready to Deploy Solutions
          </h5>
          <p>
            With our ready to deploy solutions, your business can be up and running as per schedule and on budget within 45 days. It is a cost effective and reliable approach
          </p>
        </article>
        <article class="fromSides">
          <h5>
            More Customizable Solutions
          </h5>
          <p>
            Customizing ready-to-deploy business solutions to address your specific needs or developing them entirely from the ground up based on your requirements within 90 days is what we do
          </p>
        </article>
       </div>
     </div>
</section> -->
     <!-- <section class="sections">
      <div class="containers">
        <div class="sectitle_02">
          <h2>Deployable Solutions</h2>
          <hr>
        </div>
        <div class="homethumbs">
          <figure>
            <img src="../../assets/img/d_recruitment.jpg" alt="">
            <figcaption>
              <p>
                Deployable Solution
              </p>
              <h4>
                Recruitment
              </h4>
              <a [routerLink]="['/solutions/deployable-solutions']" fragment="Recruitment">Learn More</a>
            </figcaption>
          </figure>

          <figure>
            <img src="../../assets/img/d_employee_management.jpg" alt="">
            <figcaption>
              <p>
                Deployable Solution
              </p>
              <h4>
                Employee Management
              </h4>
              <a [routerLink]="['/solutions/deployable-solutions']" fragment="empmanage">Learn More</a>
            </figcaption>
          </figure>

          <figure>
            <img src="../../assets/img/d_CRM.jpg" alt="">
            <figcaption>
              <p>
                Deployable Solution
              </p>
              <h4>
                CRM
              </h4>
              <a [routerLink]="['/solutions/deployable-solutions']" fragment="crm">Learn More</a>
            </figcaption>
          </figure>

          <figure>
            <img src="../../assets/img/d_procurement.jpg" alt="">
            <figcaption>
              <p>
                Deployable Solution
              </p>
              <h4>
                Procurement
              </h4>
              <a [routerLink]="['/solutions/deployable-solutions']" fragment="Procurement">Learn More</a>
            </figcaption>
          </figure>

          <figure>
            <img src="../../assets/img/d_sales.jpg" alt="">
            <figcaption>
              <p>
                Deployable Solution
              </p>
              <h4>
                Sales
              </h4>
              <a [routerLink]="['/solutions/deployable-solutions']" fragment="Sales">Learn More</a>
            </figcaption>
          </figure>

          <figure>
            <img src="../../assets/img/d_help_desk.jpg" alt="">
            <figcaption>
              <p>
                Deployable Solution
              </p>
              <h4>
                Help Desk
              </h4>
              <a [routerLink]="['/solutions/deployable-solutions']" fragment="Helpdesk">Learn More</a>
            </figcaption>
          </figure>

          <figure>
            <img src="../../assets/img/d_projects.jpg" alt="">
            <figcaption>
              <p>
                Deployable Solution
              </p>
              <h4>
                Projects
              </h4>
              <a [routerLink]="['/solutions/deployable-solutions']" fragment="Projects">Learn More</a>
            </figcaption>
          </figure>

          <figure>
            <img src="../../assets/img/d_reports.jpg" alt="">
            <figcaption>
              <p>
                Deployable Solution
              </p>
              <h4>
                Reports
              </h4>
              <a [routerLink]="['/solutions/deployable-solutions']" fragment="Reports">Learn More</a>
            </figcaption>
          </figure>

        </div>
        </div>
        </section>

        <section class="">
          <div class="containers">
            <div class="sectitle_02">
              <h2>Customizable Solutions</h2>
              <hr>
            </div>
            <div class="homethumbs">
              <figure>
                <img src="../../assets/img/c_accounting_finance.jpg" alt="">
                <figcaption>
                  <p>
                    Customizable Solution
                  </p>
                  <h4>
                    Accounting &#38; Finance
                  </h4>
                  <a [routerLink]="['/solutions/customizable-solutions']" fragment="Accounting">Learn More</a>
                </figcaption>
              </figure>

              <figure>
                <img src="../../assets/img/real_estate_management.jpg" alt="">
                <figcaption>
                  <p>
                    Customizable Solution
                  </p>
                  <h4>
                    Real estate Management
                  </h4>
                  <a [routerLink]="['/solutions/customizable-solutions']" fragment="Realestatemanage">Learn More</a>
                </figcaption>
              </figure>

              <figure>
                <img src="../../assets/img/c_inventory_management.jpg" alt="">
                <figcaption>
                  <p>
                    Customizable Solution
                  </p>
                  <h4>
                    Inventory management
                  </h4>
                  <a [routerLink]="['/solutions/customizable-solutions']" fragment="Inventory">Learn More</a>
                </figcaption>
              </figure>

              <figure>
                <img src="../../assets/img/c_asset_management.jpg" alt="">
                <figcaption>
                  <p>
                    Customizable Solution
                  </p>
                  <h4>
                    Asset Management
                  </h4>
                  <a [routerLink]="['/solutions/customizable-solutions']" fragment="Asset">Learn More</a>
                </figcaption>
              </figure>

              <figure>
                <img src="../../assets/img/c_operations_management.jpg" alt="">
                <figcaption>
                  <p>
                    Customizable Solution
                  </p>
                  <h4>
                    Operations management
                  </h4>
                  <a [routerLink]="['/solutions/customizable-solutions']" fragment="Operations">Learn More</a>
                </figcaption>
              </figure>

              <figure>
                <img src="../../assets/img/c_supply_management.jpg" alt="">
                <figcaption>
                  <p>
                    Customizable Solution
                  </p>
                  <h4>
                    Supply chain management
                  </h4>
                  <a [routerLink]="['/solutions/customizable-solutions']" fragment="Supplychain">Learn More</a>
                </figcaption>
              </figure>

              <figure>
                <img src="../../assets/img/c_government_tender-.jpg" alt="">
                <figcaption>
                  <p>
                    Customizable Solution
                  </p>
                  <h4>
                    Government tender management
                  </h4>
                  <a [routerLink]="['/solutions/customizable-solutions']" fragment="Gvnt">Learn More</a>
                </figcaption>
              </figure>

              <figure>
                <img src="../../assets/img/c_school_college.jpg" alt="">
                <figcaption>
                  <p>
                    Customizable Solution
                  </p>
                  <h4>
                    School &#38; college Information Management
                  </h4>
                  <a [routerLink]="['/solutions/customizable-solutions']" fragment="School">Learn More</a>
                </figcaption>
              </figure>

            </div>
            </div>
            </section> -->

            <section class="sections">

              <div class="containers">
            <div class="homecards cardblue fromSides">
            <picture>
            <img src="../../assets/img/feature_link_bg.png" alt="">
            </picture>
            <article>
            <h3>
              Automate complex processes and enjoy advanced security features
            </h3>
            <a class="underline-links" [routerLink]="['/features']">
              Learn More
            </a>
            </article>
            </div>

            <div class="homecards spited">
            <div class="cardorange fromSides">
              <article>
              <h3>
                Cloud based solutions that will transform your business at a fraction of the cost
              </h3>
              <a class="underline-links" [routerLink]="['/solutions']">
                Learn More
              </a>
            </article>
            </div>

            <div class="cardocean fromSides">
              <article>
              <h3>
                Could OfficeGaps benefit my business?
              </h3>
              <a class="underline-links" [routerLink]="['/pricing']">
                Learn More
              </a>
            </article>
            </div>

            </div>

            </div>
              </section>

            <section class="pricingOrange">
              <div class="containers">
                <div class="homePricing">

                  <article>
                    <h3>
                      Easy to implement and cost-effective <span>solutions for your business</span>
                    </h3>
                    <p>
                      Compare our business pricing plans, request demos and find customer reviews
                    </p>
                    <a [routerLink]="['/pricing']">
                      Know Our Pricing
                    </a>
                  </article>
                  <picture>
                    <img src="../../assets/img/pricing_chr.png" alt="">
                  </picture>
                  </div>
                  </div>
            </section>

<app-blogslider></app-blogslider>
