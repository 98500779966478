<div class="hero-inner contact">
  <div class="containers">
  <h1>
    <small>
       Contact Us
    </small>
    We are dedicated to helping you
    <br>
    grow your business
  </h1>
 </div>
</div>

<div class="containers">
   <div class="floated-title centered">
       <h2>
           Get in touch with us
       </h2>
     <p>Let us know what requirements or current issues you are experiencing with your business or how we can potentially <br> help your business with our range of solutions. We are happy to Help!</p>
     </div>
</div>
<section class="sections pt-0">
   <div class="containers">
       <div class="contactus">
               <address>
               <h2>
                   Contact Information
               </h2>
               <h4>
                 Address
               </h4>
               <p>
                   Johnson Technology Systems Inc.
                   <br>
                   11490 Commerce Park Dr. #410, Reston, Virginia 20191
               </p>
               <h4>
                   Phone
                 </h4>
                 <p>
                   Washington DC Tel: 703.949.9100
                   <br>
                   New Jersey Tel: 973.328.8000
                 </p>
                 <h4>
                   E-mail
                 </h4>
                 <p>
                   Business Development: bd@jtsusa.com
                   <br>
                   Support: support@jtsusa.com
                   <br>
                   Generals Enquiries: info@jtsusa.com
                 </p>
                 <hr>
                 <h5>
                   Don’t Miss To Follow Us On Our Social Network Accounts
                 </h5>
                 <ul class="socialinks colored">
                   <li>
                     <a class="facebook" href="#">
                       <svg>
                         <use xlink:href="../../assets/img/sprite.svg#faceBooks"></use>
                       </svg>
                     </a>
                   </li>
                   <li>
                     <a class="twitter" href="#">
                       <svg>
                         <use xlink:href="../../assets/img/sprite.svg#twitter"></use>
                       </svg>
                     </a>
                   </li>
                   <li>
                     <a class="linkedin" href="#">
                       <svg>
                         <use xlink:href="../../assets/img/sprite.svg#linkedIn"></use>
                       </svg>
                     </a>
                   </li>
                 </ul>
           </address>
           <div class="formside">
               <h2>
                   Contact Us
               </h2>
               <p>
                   Please fill out your information below and a JTSi Customer Representative will contact you shortly
               </p>
                   <form action="">
                       <div class="mb-4">
                           <label for="name" class="form-label">Full Name</label>
                           <input type="text" class="form-control form-control-lg" id="name" >
                       </div>
                       <div class="mb-4">
                           <label for="email" class="form-label">Email</label>
                           <input type="email" class="form-control form-control-lg" id="email" >
                       </div>
                       <div class="mb-4">
                           <label for="phonenumber" class="form-label">Phone Number</label>
                           <input type="number" class="form-control form-control-lg" id="phonenumber" >
                       </div>
                       <div class="mb-4">
                           <label for="website" class="form-label">Website</label>
                           <input type="url" class="form-control form-control-lg" id="website" >
                       </div>
                       <div class="mb-4">
                           <label for="message" class="form-label">Your Message</label>
                           <textarea class="form-control form-control-lg" id="message" rows="3"></textarea>
                         </div>
                         <button class="large-btn" type="submit">Submit</button>
                   </form>
           </div>
       </div>
   </div>
</section>
<app-pricingbluebar></app-pricingbluebar>
<app-blogslider></app-blogslider>
