<div class="weeklyUpdate">
  <div class="containers">
     <div class="subscribecolumns">
         <div class="txts">

             <h2>
                 <svg>
                     <use xlink:href="../../assets/img/sprite.svg#letter"></use>
                   </svg>
                 Sign up to get weekly updates.
             </h2>
             <p>
                 Stay up to date with our latest news and updates. Sign up to get weekly updates.
             </p>
         </div>
         <div class="subform">
             <form class="subgroup">
             <input type="email" placeholder="email address">
             <button type="submit">Subscribe Now</button>
         </form>
         </div>
     </div>
  </div>
  </div>
