import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookiepolicy',
  templateUrl: './cookiepolicy.component.html',
  styleUrls: ['./cookiepolicy.component.scss']
})
export class CookiepolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
