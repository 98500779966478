<div class="hero-inner deployable">
  <div class="containers">
  <h1>
    <small>
      <a [routerLink]="['/solutions']">Solutions</a> <i class="bi bi-arrow-right"></i> Our Deployable Solutions
    </small>
    Faster to Implement
  </h1>
 </div>
</div>

<div class="containers">
  <div class="floated-title split">
      <article>
    <h2>
       Ready to Deploy Solutions
    </h2>
    <p>
       Get your business ready on schedule and on budget with our ready-to-deploy solutions. Faster to implement, it is a cost-effective and reliable approach.
    </p>
   </article>
   <picture>
       <img class="fromSides" src="../../assets/img/deploy_lap_banner.png" alt="">
   </picture>
  </div>
</div>

   <div class="containers">
       <div class="linked-icons">
           <a [routerLink]="['/solutions/deployable-solutions']" fragment="Recruitment" class="iconbox">
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#recruitment"></use>
                 </svg>
                 <h4>
                   Recruitment
                 </h4>
               </a>
           <a [routerLink]="['/solutions/deployable-solutions']" fragment="empmanage" class="iconbox">
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#employeemanage"></use>
                 </svg>
                 <h4>
                   Employee Management
                 </h4>
               </a>

           <a [routerLink]="['/solutions/deployable-solutions']" fragment="crm" class="iconbox">
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#crm"></use>
                 </svg>
                 <h4>
                   CRM
                 </h4>
               </a>

               <a [routerLink]="['/solutions/deployable-solutions']" fragment="Procurement" class="iconbox">
                 <svg>
                     <use xlink:href="../../assets/img/sprite.svg#procurement"></use>
                   </svg>
                   <h4>
                       Procurement
                   </h4>
                 </a>

           <a [routerLink]="['/solutions/deployable-solutions']" fragment="Sales" class="iconbox">
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#sales"></use>
                 </svg>
                 <h4>
                   Sales
                 </h4>

               </a>

           <a [routerLink]="['/solutions/deployable-solutions']" fragment="Helpdesk" class="iconbox">
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#helpdesk"></use>
                 </svg>
                 <h4>
                   Help Desk
                 </h4>
               </a>

           <a [routerLink]="['/solutions/deployable-solutions']" fragment="Projects" class="iconbox">
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#projects"></use>
                 </svg>
                 <h4>
                   Projects
                 </h4>
               </a>

           <a [routerLink]="['/solutions/deployable-solutions']" fragment="Reports" class="iconbox">
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#reports"></use>
                 </svg>
                 <h4>
                   Reports
                 </h4>
               </a>

       </div>

</div>


<section class="sections npbottom" id="Recruitment">
   <div class="containers">
       <div class="solution-details">
           <figure class="fromSides">
               <img src="../../assets/img/Recruitment.jpg" alt="">
               <figcaption>
                   <h2>
                       <span>
                       <svg>
                           <use xlink:href="../../assets/img/sprite.svg#recruitment"></use>
                         </svg>
                       </span>
                         Recruitment
                   </h2>
                   <p>
                       Boost your employee hiring success by sourcing, tracking, and hiring top talent that aligns with your business - on a centralized, automated platform.
                   </p>
               </figcaption>
           </figure>
<article class="fromSides">

   <h3>
       Find and hire the best employees for the job
   </h3>
   <ul class="arrowlist">
       <li>Create job openings requisitions with ease</li>
<li>Improved recruiting process</li>
<li>Resumes collected can be saved for future references</li>
<li>Streamlined screening and interview process</li>
<li>Customize entire hiring process according to your requirements</li>
<li>Notifies you about recruitment updates and alarms about any scheduled activities</li>
<li>Streamlines entire documentation pertaining to on boarding and hiring of employees</li>
<li>Built-in employee referral module that paves way for quality and faster hiring</li>
<li>Candidate follow-up is no more a hurdle</li>
<li>know where each candidate is in the hiring process</li>

   </ul>
</article>

       </div>
   </div>
</section>

<section class="sections npbottom" id="empmanage">
   <div class="containers">
       <div class="solution-details">
<article class="fromSides">
   <h3>
       Reduce employee management complexities
   </h3>
   <ul class="arrowlist">
       <li>Allows employees to handle their own HR needs</li>
<li>Employee details are well managed and secured</li>
<li>Automate admin tasks and manage them more efficiently</li>
<li>Cloud storage allows you to access staff information on the go</li>
<li>Standard metric system to have an overall view of employee performance</li>
<li>The timesheet management features allow for easy decision-making</li>
<li>A timely and efficient payroll process</li>
<li>Data-rich salary slips, and downloadable reports improve workforce retention</li>
<li>Easily generate working hours reports</li>
<li>Portals for employees to access pay slips, leaves, salaries, and HR data</li>

   </ul>
</article>
<figure class="fromSides">
   <img src="../../assets/img/EmployeeManagement.jpg" alt="">
   <figcaption>
       <h2>
           <span>
           <svg>
               <use xlink:href="../../assets/img/sprite.svg#employeemanage"></use>
             </svg>
           </span>
           Employee Management
       </h2>
      <p>
       Keeps you organized all the time with an intuitive module that readily makes all employee information available at your fingertips
      </p>
   </figcaption>
</figure>
       </div>
   </div>
</section>

<section class="sections npbottom" id="crm">
   <div class="containers">
       <div class="solution-details">
           <figure class="fromSides">
               <img src="../../assets/img/CRM.jpg" alt="">
               <figcaption>
                   <h2>
                       <span>
                       <svg>
                           <use xlink:href="../../assets/img/sprite.svg#crm"></use>
                         </svg>
                       </span>
                       CRM
                   </h2>
                   <p>
                       Manage, automate, and analyses all marketing, sales, customer service, prospects, opportunities, and existing customer retention processes.
                   </p>
               </figcaption>
           </figure>
<article class="fromSides">

   <h3>
       Acquiring and retaining customers effectively
   </h3>
   <ul class="arrowlist">
       <li>Keeps track on potential customers and deals</li>
       <li>Monitors the complete sales cycle</li>
       <li>Improves servicing of existing customers</li>
       <li>Complete visibility of ALL customer interactions</li>
       <li>Complete sales, support, purchase, and shipping history</li>
       <li>Customer/Supplier service tracking and answer book</li>
       <li>Integrated event calendar and alert system</li>
       <li>Complete support and call centre functionality</li>
       <li>Mail Merge, Calendar, and Alert integrations</li>
       <li>Centralized Storage of Information</li>

   </ul>
</article>

       </div>

   </div>
</section>

<section class="sections npbottom" id="Procurement">
   <div class="containers">
       <div class="solution-details">
           <figure class="fromSides">
               <img src="../../assets/img/Procurement.jpg" alt="">
               <figcaption>
                   <h2>
                       <span>
                       <svg>
                           <use xlink:href="../../assets/img/sprite.svg#procurement"></use>
                         </svg>
                       </span>
                       Procurement
                   </h2>
                   <p>
                       Enables a business to optimize its procurement process and patterns, improving quality and reducing procurement cost/time and gaining a distinct competitive edge.
                   </p>
               </figcaption>
           </figure>
<article class="fromSides">

   <h3>
       Collaborate seamlessly with your suppliers
   </h3>
   <ul class="arrowlist">
       <li>Streamlines purchase and process cycles</li>
<li>Detailed Supplier/Subcontractor/Service Provider database</li>
<li>Capture materials requirement</li>
<li>Recording Payment terms in PO</li>
<li>Excise consideration in Purchase and Process Orders</li>
<li>PO authorization</li>
<li>PO amendments with complete amendment history</li>
<li>Order cancellation and order closing</li>
<li>MIS for vendor evaluation based on quality, price & delivery time</li>
<li>Multiple indents for multiple items in a single PO</li>

   </ul>
</article>

       </div>
   </div>
</section>

<section class="sections npbottom" id="Sales">
   <div class="containers">
       <div class="solution-details">
<article class="fromSides">
   <h3>
       Enhance your business revenue streams
   </h3>
   <ul class="arrowlist">
       <li>Handles pre-sales and sales activities of the organization</li>
<li>Prospect tracking through various stages</li>
<li>Marketing surveys for estimating the demand for various products</li>
<li>Target setting for executives</li>
<li>Order amendment history</li>
<li>Order scheduling over a period and tracking delivery schedule</li>
<li>Order tracking through status and transaction reports</li>
<li>Tracking sales returns</li>
<li>Flexibility to define customer-specific prices for products</li>
<li>Multiple dispatch locations for customers and dealers</li>

   </ul>
</article>
<figure class="fromSides">
   <img src="../../assets/img/Sales.jpg" alt="">
   <figcaption>
       <h2>
           <span>
           <svg>
               <use xlink:href="../../assets/img/sprite.svg#sales"></use>
             </svg>
           </span>
           Sales
       </h2>
      <p>
       Increase accountability and profitability of the business by efficiently managing sales function and complete visibility into the order-to-payment cycle.
      </p>
   </figcaption>
</figure>
       </div>
   </div>
</section>

<section class="sections npbottom" id="Helpdesk">
   <div class="containers">
       <div class="solution-details">
           <figure class="fromSides">
               <img src="../../assets/img/Help_Desk.jpg" alt="">
               <figcaption>
                   <h2>
                       <span>
                       <svg>
                           <use xlink:href="../../assets/img/sprite.svg#helpdesk"></use>
                         </svg>
                       </span>
                       Help Desk
                   </h2>
                   <p>
                       Organizes all customer support interactions in one place, improving productivity and improving your customer satisfaction
                   </p>
               </figcaption>
           </figure>
<article class="fromSides">

   <h3>
       Effortlessly resolve customer complaints
   </h3>
   <ul class="arrowlist">
       <li>Managing the knowledge base</li>
<li>Problem resolution and escalation procedures</li>
<li>Adhering to Service Level Agreements (SLAs)</li>
<li>Self-Service options for end-users</li>
<li>Behave as a single point of contact (SPOC) for IT Support</li>
<li>Employ a tracking solution for all incoming incidents</li>
<li>Automated ticket tracking, email notifications, and routing</li>
<li>Basic Incident Management and Problem Management</li>
<li>Offers limited integrations with other IT Service Management Processes</li>
<li>Some applications/areas supported by specialty groups outside the help desk</li>

   </ul>
</article>

       </div>
   </div>
</section>

<section class="sections npbottom" id="Projects">
   <div class="containers">
       <div class="solution-details">
<article class="fromSides">
   <h3>
    Plan, organize, and manage complex projects
   </h3>
   <ul class="arrowlist">
    <li>Identify and record key resources associated with your project</li>
    <li>Specify user-level access rights to ensure security and prevent unauthorized access</li>
    <li>Pre-plan to avoid wasting resources and time on the project</li>
    <li>Develop a sequential execution plan for multiple tasks of a project/sub-project</li>
    <li>Track and report on progress, resources, and milestones, effectively</li>
    <li>Assess the resource performance against the expected results</li>
    <li>Identify and analyze the project's risks and challenges, and act accordingly</li>
    <li>Establish the cost centers and non-billable attributes of your project</li>
    <li>Assess the cost of the overall project as well as costs attributed to tasks, milestones, and resources</li>
    <li>Expenses, revenue, and profitability are compared to forecasts to determine actual profits</li>
   </ul>
</article>
<figure class="fromSides">
   <img src="../../assets/img/Projects.jpg" alt="">
   <figcaption>
       <h2>
           <span>
           <svg>
               <use xlink:href="../../assets/img/sprite.svg#projects"></use>
             </svg>
           </span>
           Projects
       </h2>
      <p>
        Automate the project management cycle while keeping track of associated costs to meet the requirements for effective project management and drive operational excellence.
      </p>
   </figcaption>
</figure>
       </div>
   </div>
</section>

<section class="sections npbottom" id="Reports">
   <div class="containers">
       <div class="solution-details nbbottom">
           <figure class="fromSides">
               <img src="../../assets/img/Reports.jpg" alt="">
               <figcaption>
                   <h2>
                       <span>
                       <svg>
                           <use xlink:href="../../assets/img/sprite.svg#reports"></use>
                         </svg>
                       </span>
                       Reports
                   </h2>
                   <p>
                     Integrated reporting functions of OfficeGaps can pull raw data from databases into reports that contain actionable data, which allows management to take informed decisions
                   </p>
               </figcaption>
           </figure>
<article class="fromSides">

   <h3>
     Data-driven business intelligence for leaders
   </h3>
   <ul class="arrowlist">
     <li>Schedule reports to run as needed</li>
     <li>Generate signed PDF documents</li>
     <li>Customize reports with your own branding and images</li>
     <li>Create reports in real time and apply filters</li>
     <li>Localize data used by a report if multilingual support of user input is enabled</li>
     <li>Plan a head of the project and avoid resource leakage and time wastage</li>
     <li>Define and record all key resources</li>
     <li>Define access rights for people involved in the project</li>
     <li>Effectively define multiple tasks of a project in sequence for execution</li>
     <li>Some applications/areas supported by specialty groups outside the help desk</li>
   </ul>
</article>

       </div>
   </div>
</section>

<app-getstart></app-getstart>
