import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-weeklyupdate',
  templateUrl: './weeklyupdate.component.html',
  styleUrls: ['./weeklyupdate.component.scss']
})
export class WeeklyupdateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
