import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    const navbtn = document.querySelectorAll(".mobilenav");
    const mobilemenunav = <HTMLElement>document.getElementById("navmob");

    navbtn.forEach(button =>
      button.addEventListener("click", _ => {
        mobilemenunav.classList.toggle("showMenu");
      })
    );

    // const closeber = document.querySelectorAll(".closeBar");

    // closeber.forEach(span =>
    //   span.addEventListener("click", _ => {
    //     document.getElementById("navmob").classList.remove("open");
    //   })
    // );




  }

}
