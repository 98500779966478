<header class="siteHeader">
  <div class="topbar">

        <div class="leftbar">
           <a class="hiddenid" href="mailto:info@officegaps.com"><i class="bi bi-envelope"></i> info@officegaps.com</a>
           <a href="tel:+12024645874"><i class="bi bi-telephone"></i> +1(202)464-JTSi(5874) </a>
        </div>
        <div class="rightbar">
          <a [routerLink]="['/blog']"> Blog</a> | <a [routerLink]="['/support']">Support</a>
          <div class="languages">
            <svg>
              <use xlink:href="../../assets/img/sprite.svg#usflag"></use>
            </svg>
            <i class="bi bi-caret-down-fill"></i>
          </div>
        </div>

  </div>

    <div class="menubar">
      <a class="logo" [routerLink]="['']">
<img src="../../assets/img/OG-logo.svg" alt="">
      </a>
<nav class="topnav" id="navmob">
<ul class="navlinks">
<li class="dropdown" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
  <a [routerLink]="['/solutions']">
    Solutions <i class="bi bi-caret-down-fill"></i>
  </a>
  <div class="dropDownBox">
      <ul>
        <li><a [routerLink]="['/solutions/deployable-solutions']">Deployable Solutions</a></li>
        <li><a [routerLink]="['/solutions/customizable-solutions']">Customizable Solutions</a></li>
    </ul>
  </div>
</li>
<li class="dropdown" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
  <a [routerLink]="['/industries']">
    Industries
    <i class="bi bi-caret-down-fill"></i>
  </a>

  <div class="dropDownBox twoColumn">
    <ul>
    <li><a [routerLink]="['/industries/government']">Government</a></li>
    <li><a [routerLink]="['/industries/education']">Education</a></li>
    <li><a [routerLink]="['/industries/healthcare']">Healthcare</a></li>
    <li><a [routerLink]="['/industries/professional-services']">Professional Services</a></li>
  </ul>
  <ul>
    <li><a [routerLink]="['/industries/financial-services']">Financial services</a></li>
    <li><a [routerLink]="['/industries/real-estate']">Real estate</a></li>
    <li><a [routerLink]="['/industries/travel-and-hospitality']">Travel and hospitality</a></li>
    <li><a [routerLink]="['/industries/IT-and-ITES']">IT and ITES</a></li>
  </ul>
</div>
</li>
<li><a routerLinkActive="active" [routerLink]="['/features']">Features</a>
</li>
<li><a routerLinkActive="active" [routerLink]="['/pricing']">pricing</a></li>
<li><a routerLinkActive="active" [routerLink]="['/contact-us']">Contact</a></li>
<li>
  <button  class="nav-btn nav-btn-line" data-bs-toggle="modal" data-bs-target="#producTour">Free Product Tour</button>
  <button class="nav-btn nav-btn-text" data-bs-toggle="modal" data-bs-target="#loginModal">Login</button>
</li>
</ul>
</nav>
<button class="mobilenav">
<svg>
  <use xlink:href="../../assets/img/sprite.svg#navlist"></use>
</svg>
</button>
    </div>

</header>
