<div class="hero-inner industries">
  <div class="containers">
  <h1>
    <small>
       Industries
    </small>
    Business management on
    <br>
    the cloud
  </h1>
 </div>
</div>

<div class="containers">
  <div class="floated-title split">
      <article>
    <h2>
       Designed specifically for your industry
    </h2>
    <p>
       We are committed to solving your specific challenges through our expert industry knowledge, decades of experience, and an in-house team devoted to helping you succeed.
    </p>
   </article>
   <picture>
       <img class="fromSides" src="../../assets/img/industries_lap.png" alt="">
   </picture>
  </div>
</div>
  <div class="industry-wave-pattern">
   <div class="containers">
       <div class="linked-icons">
           <a [routerLink]="['/industries/government']" class="iconbox">
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#gvnt"></use>
                 </svg>
                 <h4>
                   Government
                 </h4>
               </a>
           <a [routerLink]="['/industries/professional-services']" class="iconbox">
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#professional"></use>
                 </svg>
                 <h4>
                   Professional Services
                 </h4>
               </a>

           <a [routerLink]="['/industries/education']" class="iconbox">
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#education"></use>
                 </svg>
                 <h4>
                   Education
                 </h4>
               </a>

               <a [routerLink]="['/industries/healthcare']" class="iconbox">
                 <svg>
                     <use xlink:href="../../assets/img/sprite.svg#health"></use>
                   </svg>
                   <h4>
                     Healthcare
                   </h4>
                 </a>

           <a [routerLink]="['/industries/financial-services']" class="iconbox">
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#finance"></use>
                 </svg>
                 <h4>
                   Financial services
                 </h4>

               </a>

           <a [routerLink]="['/industries/real-estate']" class="iconbox">
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#realestate"></use>
                 </svg>
                 <h4>
                   Real estate
                 </h4>
               </a>

           <a [routerLink]="['/industries/travel-and-hospitality']" class="iconbox">
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#hospitality"></use>
                 </svg>
                 <h4>
                   Travel and hospitality
                 </h4>
               </a>

           <a [routerLink]="['/industries/IT-and-ITES']" class="iconbox">
               <svg>
                   <use xlink:href="../../assets/img/sprite.svg#itites"></use>
                 </svg>
                 <h4>
                   IT and ITES
                 </h4>
               </a>

       </div>

</div>
  </div>

<section class="sections" id="Government">
   <div class="containers">
       <div class="industry-details">
<article class="fromSides">
   <h2>
       Government
   </h2>
   <p>
       <strong>OfficeGaps</strong> is a comprehensive suite of applications designed for government agencies. A variety of solutions are included for fiscal management, procurement, asset management, human resources, tax management, and payroll.
   </p>
</article>
<picture class="fromSides">
   <img src="../../assets/img/Government.jpg" alt="">
</picture>
       </div>
       <ul class="checklist-lg twocol">
           <li>Scalable management solutions that enable cost-effective mission execution</li>
           <li>Analyze your organization in real time for deeper insights</li>
<li>Reduce costs and save money by reducing your total cost of ownership and up-front investment, so you can use capital for public-benefit expenditures</li>
<li>Combining critical ERP functionality, configuration capabilities, and mobile capabilities for maximum efficiency, while protecting your data with our industry-leading solutions</li>

       </ul>
       <div class="justflex center">
           <a class="large-btn" [routerLink]="['/industries/government']">Learn More</a>
       </div>
   </div>
</section>
<section class="sections light-gray" id="Professional">
   <div class="containers">
       <div class="industry-details picleft">
           <picture class="fromSides">
               <img src="../../assets/img/Professional-Services.jpg" alt="">
           </picture>
<article class="fromSides">
   <h2>
       Professional Services
   </h2>
  <p>
   Integrated lifecycle management platform designed specifically for services businesses to manage and optimize the sale and billing of projects and services. Provides greater flexibility and automation in aspects like project management, resource management, and billing, which makes it possible for services firms to meet the requirements of diverse clients.
  </p>
</article>

       </div>
       <ul class="checklist-lg twocol">
           <li>Ensures that the project lifecycle is managed in a consistent manner, from planning, to management, to monitoring</li>
           <li>Offers a range of project management, time tracking, invoicing, resource planning, business intelligence, and collaboration tools</li>
<li>Improve your ability to plan projects quickly and nimbly with greater visibility into your employees' schedules, availability, and work assignments</li>
<li>Automate invoicing, quoting, timesheet generation, report generation, resource matching, and financial insight</li>

       </ul>
       <div class="justflex center">
           <a class="large-btn" [routerLink]="['/industries/professional-services']">Learn More</a>
       </div>
   </div>
</section>

<section class="sections" id="Education">
   <div class="containers">
       <div class="industry-details">
<article class="fromSides">
   <h2>
       Education
   </h2>
   <p>
       Manages and integrates all key academic, financial, administrative, and infrastructure processes of an educational institution. Managing student information, exam records, fees accounts, web portals, and management information systems (MIS) becomes simple by facilitating communication among various organizational departments.
   </p>
</article>
<picture class="fromSides">
   <img src="../../assets/img/Education.jpg" alt="">
</picture>
       </div>
       <ul class="checklist-lg twocol">
           <li>Enhance integration, maintenance, and efficiency of systems</li>
           <li>Ensure data security and availability by eliminating resource constraints</li>
           <li>Equipped to scale across solutions that enable cost-effective management, so that staff can better serve students</li>
           <li>Increase campus service offerings and reduce costs with a lower total cost of ownership, which enables you to save capital for expenditures directly impacting students, faculty, and staff</li>
       </ul>
       <div class="justflex center">
           <a class="large-btn" [routerLink]="['/industries/education']">Learn More</a>
       </div>
   </div>
</section>
<section class="sections light-gray" id="Healthcare">
   <div class="containers">
       <div class="industry-details picleft">
           <picture class="fromSides">
               <img src="../../assets/img/Healthcare.jpg" alt="">
           </picture>
<article class="fromSides">
   <h2>
       Healthcare
   </h2>
 <p>
   With <strong>OfficeGaps</strong>, organizations have clinical interoperability, financials management, and human resource management integrated with their enterprise software. Clinical care can be consistently delivered even in the face of new challenges.
 </p>
</article>

       </div>
       <ul class="checklist-lg twocol">
           <li>Facilitates communication between providers and patients, improving patient safety</li>
           <li>Analyze how well a healthcare system operates. Improve your business knowledge for better decision-making</li>
<li>Provides hospitals and healthcare providers with tools to store essential data on their business processes and patients and ensuring their privacy and confidentiality</li>
<li>Helps inform and expedite the decision-making process for patients</li>

       </ul>
       <div class="justflex center">
           <a class="large-btn" [routerLink]="['/industries/healthcare']">Learn More</a>
       </div>
   </div>
</section>

<app-weeklyupdate></app-weeklyupdate>

<section class="sections" id="Financial">
   <div class="containers">
       <div class="industry-details">
<article class="fromSides">
   <h2>
       Financial services
   </h2>
   <p>
       Enhance and streamline your organization’s financial operations by managing your cash flow and spends better. Achieve accounting accuracy, meet financial regulations, and generate multiple financial reports. With <strong>OfficeGaps</strong>, you can revolutionize the way you manage financial data in your organization.
   </p>
</article>
<picture class="fromSides">
   <img src="../../assets/img/Financial-services.jpg" alt="">
</picture>
       </div>
       <ul class="checklist-lg twocol">
           <li>Unified view of your financial data in real time which enables you to make better decisions and achieve business goals</li>
<li>Provides an organization with the ability to predict, analyze, and manage its financial risks</li>
           <li>Extend clear financial information to vendors and customers</li>
<li>Quickly and accurately produce financial documents, including cash flow statements, balance sheets, etc</li>
</ul>
       <div class="justflex center">
           <a class="large-btn" [routerLink]="['/industries/financial-services']">Learn More</a>
       </div>
   </div>
</section>
<section class="sections light-gray" id="Realestate">
   <div class="containers">
       <div class="industry-details picleft">
           <picture class="fromSides">
               <img src="../../assets/img/Real-estate.jpg" alt="">
           </picture>
<article class="fromSides">
   <h2>
       Real estate
   </h2>
<p>
   Property Developers, Real Estate companies, and Lease Management firms can streamline business processes with Property Sales Management, Leasing Management, Subcontractor Management, and Facility Management functions.
</p>
</article>

       </div>
       <ul class="checklist-lg twocol">
           <li>Providing agents/companies with an in-depth understanding of their clients and their preferences</li>
<li>Provides tools that allows salespeople to keep track of prospects and gauge lead generation progress from anywhere</li>
<li>Streamline the coordination and management of planned maintenance requests for organizations and facilities</li>
<li>Manages Leasing status, Payments plans, Collections, and Agents</li>

       </ul>
       <div class="justflex center">
           <a class="large-btn" [routerLink]="['/industries/real-estate']">Learn More</a>
       </div>
   </div>
</section>

<section class="sections" id="Hospitality">
   <div class="containers">
       <div class="industry-details">
<article class="fromSides">
   <h2>
       Travel and hospitality
   </h2>
   <p>
       The application helps you manage all important functions of your travel and hospitality business, such as human resource management, financial management, maintenance, stocking, projects, investments, risk, decision-making, payoff analyzing, region planning, business in the region, etc.
   </p>
</article>
<picture class="fromSides">
   <img src="../../assets/img/Travel-and-hospitality.jpg" alt="">
</picture>
       </div>
       <ul class="checklist-lg twocol">
           <li>With accurate reports on all major metrics such as sales averages and conversion rates, travel agencies have access to a wide range of data all in one place</li>
<li>Provides a comprehensive view of customer data on a single dashboard, making it easy to get information like names, email addresses, previous interactions, and more</li>
<li>Manage revenue, pay suppliers, and invoice customers and clients on a single platform</li>
<li>It is possible to segment data for discovery of trends among lucrative demographics, such as frequent flyers. These data enable tour agencies to manage their cash flow, create marketing campaigns and reduce their costs</li>

</ul>
       <div class="justflex center">
           <a class="large-btn" [routerLink]="['/industries/travel-and-hospitality']">Learn More</a>
       </div>
   </div>
</section>
<section class="sections light-gray" id="Ites">
   <div class="containers">
       <div class="industry-details picleft">
           <picture class="fromSides">
               <img src="../../assets/img/Real-estate.jpg" alt="">
           </picture>
<article class="fromSides">
   <h2>
       IT and ITES
   </h2>
<p>
   Deliver faster by transforming how IT teams work together and with the business. Facilitating quick and easy collaboration between cross-functional teams so issues can be resolved more quickly. Helps your IT Teams improve their resolution, escalation, and change management processes.
</p>
</article>

       </div>
       <ul class="checklist-lg twocol">
           <li>Aligning IT teams with business priorities and tracking success metrics</li>
           <li>Increase service efficiency by better coordinating requests</li>
           <li>Facilitate knowledge sharing and continuous improvement among IT teams</li>
           <li>Improve customer service and processes to promote customer centricity</li>
       </ul>
       <div class="justflex center">
           <a class="large-btn" [routerLink]="['/industries/IT-and-ITES']">Learn More</a>
       </div>
   </div>
</section>

<app-pricingbluebar></app-pricingbluebar>

<app-blogslider></app-blogslider>
