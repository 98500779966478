import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricingbluebar',
  templateUrl: './pricingbluebar.component.html',
  styleUrls: ['./pricingbluebar.component.scss']
})
export class PricingbluebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
