<div class="hero-inner cookies">
  <div class="containers">
  <h1>
    <small>
       Cookies Policy
    </small>
    We Know the secret of your success
  </h1>
 </div>
</div>

<div class="containers">
  <div class="floated-title centered">
    <h2>
     Limitations of liability
    </h2>
    <p>
     The information provided on the site is provided for informational purposes only, and we will not be liable <br> for any loss or damage you may suffer as a result of the use of the site
    </p>
  </div>
</div>

<section class="sections nptop">
   <div class="container">
       <div class="commonContent">
          <p>
           We have outlined our Cookie Policy below, which explains what cookies are and how they are used. It is recommended that you read through this policy to understand what cookies are, how they are used, what types of cookies we use, what information they collect, and how you can control them. You can also visit our Privacy Policy to find out more about how we use, store, and keep your personal data secure. Changing or withdrawing your consent to the Cookie Declaration can be done at any time on our website.
          </p>

    <h4>
     What are cookies?
    </h4>
    <p>
     Generally, cookies are small text files that hold small bits of information. Your browser stores cookies when you visit a website. As a result of these cookies, the website functions correctly, is more secure, provides a better user experience, and we are able to analyze how the website performs and improve it.
    </p>
    <h4>
     How do we use cookies?
    </h4>
    <p>
     Our website uses cookies for a variety of purposes, like most online services. Most of the first-party cookies are necessary for the website to function properly, and they do not collect any of your personally identifiable information.
   </p><p>
     Third-party cookies on our websites are used mainly to provide you with a better and improved user experience and speed up future interactions with our websites, as well as to maintain the security of our services and provide you with advertisements that are relevant to you.
   </p><p>
     In order to improve the functionality of our website, we use cookies to distinguish you from other users. Our website and services can be improved as a result, allowing you to have a better user experience.
    </p>
    <h4>
     What types of cookies do we use?
    </h4>
    <h5>
     Strictly Necessary Cookies
    </h5>
    <p>
     To ensure that the website functions properly, these cookies are essential. Cookies in this category are only used to ensure basic functionality and security on the website. These cookies do not store any personal information. They are used for the following purposes:
    </p>
    <ul class="arrowlist">
     <li>The purpose of this website is to improve public access to general information about OfficeGaps software. Our efforts are to ensure that the information is current and accurate. In the event that errors are pointed out, we will correct them as soon as possible. We do not, however, assume any responsibility or liability for the content on this site.</li>
     <li>Information contained on this site is of a general nature only and is not intended to address specific circumstances of any individual or entity. This information is not necessarily comprehensive, complete, accurate or current. It may contain links to external sites over which we have no control and which we do not assume responsibility for . It is not a professional or legal opinion.</li>
     <li>We strive to minimize disruptions caused by technical errors. However, some data or information on our site might have been created or structured in a way that is not error-free, and we cannot guarantee that our service will not be interrupted. If you encounter such problems while using this site or any link to an external site, we accept no responsibility.</li>
    </ul>
           <h5>
             User Conduct
           </h5>
           <p>
             This site may only be used for lawful purposes. Users are prohibited from posting any material on this site that violates or infringes upon the rights of others, or which is unlawful, threatening, abusive, defamatory, infringing upon privacy, obscene, profane, or otherwise objectionable, that encourages conduct that could lead to criminal prosecution, civil liability, or otherwise violate the law, or that contains advertisements or solicitations with respect to products or services without the Company's express prior approval.
           </p>
           <h5>
             Prohibited Conduct
           </h5>
           <p>
             These activities are strictly prohibited:
           </p>
           <ul class="arrowlist">
             <li>Provide security measures and other features</li>
           <li>Detect software on your device that enables our website's content</li>
         </ul>
<p>
 We do not use these cookies to market to you. Our website cannot function properly or guarantee your security if you choose not to accept these cookies.
</p>
<h5>
 Performance Cookies
</h5>
<p>
 The purpose of performance cookies is to analyze how visitors interact with the website. Using these cookies, we collect information about traffic metrics, bounce rates, traffic sources, and so on. to improve the user experience. They serve the following purposes:
</p>
<ul class="arrowlist">
 <li>See which pages you visit on the web by obtaining web usage statistics</li>
<li>Analyze website performance and identify any errors</li>
</ul>
<h5>
 Functional Cookies
</h5>
<p>
 Cookies that enable certain functionalities such as sharing the content of a website on social media platforms, collecting feedback, and other third-party features are functional cookies. Several of these services may not work properly if you don't allow these cookies.
</p>
<h5>
 Advertising Cookies
</h5>
<p>
 Advertising cookies enable relevant ads and marketing campaigns to be displayed to visitors. By tracking visitors across various websites and collecting information, customized ads can be displayed. Generally, these cookies do not store personal information, but rather uniquely identify you and your browser. Disabling these cookies will result in less targeted advertising.
</p>
           <h4>
             How can I manage my cookie preferences?
           </h4>
           <p>
             Cookie’s preferences can be managed by clicking on the "Settings" button and enabling or disabling the cookie categories from the popup according to your preferences.
           </p><p>
             In case you want to change your preferences during the course of your browsing session, you can select the "Cookie Policy" tab on your screen. Clicking this will give you the option to change your preferences or withdraw your consent altogether.
           </p><p>
             Furthermore, different browsers provide different methods of blocking and deleting cookies. Changing the settings of your browser will allow you to block or delete cookies.
            </p>
       </div>
   </div>
</section>
