import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trailform',
  templateUrl: './trailform.component.html',
  styleUrls: ['./trailform.component.scss']
})
export class TrailformComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
