<section class="sections light-pink-2">
  <div class="sectitle_01">
    <h2>
      Getting started is easy
    </h2>
  </div>

  <div class="containers">
    <div class="lgbtn-group">
    <a href="#" class="exlarge-btn">
      Free Product Tour
    </a>
    <a href="#" class="exlarge-btn exlarge-btn-line">
   Talk to Concierge
    </a>
  </div>
</div>
  </section>
