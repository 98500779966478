import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { HomeComponent } from './home/home.component';
import { SolutionsComponent } from './solutions/solutions/solutions.component';
import { DeployableComponent } from './solutions/deployable/deployable.component';
import { CustomizableComponent } from './solutions/customizable/customizable.component';
import { IndustriesComponent } from './industries/industries/industries.component';
import { GovernmentComponent } from './industries/government/government.component';
import { EducationComponent } from './industries/education/education.component';
import { HealthcareComponent } from './industries/healthcare/healthcare.component';
import { ProfessionalComponent } from './industries/professional/professional.component';
import { FinancialComponent } from './industries/financial/financial.component';
import { RealestateComponent } from './industries/realestate/realestate.component';
import { HospitalityComponent } from './industries/hospitality/hospitality.component';
import { ItesComponent } from './industries/ites/ites.component';
import { FeaturesComponent } from './features/features.component';
import { PricingComponent } from './pricing/pricing.component';
import { ContactComponent } from './contact/contact.component';
import { FaqComponent } from './faq/faq.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { DesclaimerComponent } from './desclaimer/desclaimer.component';
import { BlogComponent } from './blog/blog/blog.component';
import { SupportComponent } from './support/support/support.component';
import { BlogcontentsComponent } from './blogcontents/blogcontents.component';
import { CookiepolicyComponent } from './cookiepolicy/cookiepolicy.component';
import { HelpcenterComponent } from './helpcenter/helpcenter/helpcenter.component';
import { AccountsComponent } from './helpcenter/accounts/accounts.component';
import { IntegrationComponent } from './helpcenter/integration/integration.component';
import { ManageteamComponent } from './helpcenter/manageteam/manageteam.component';
import { VerificationComponent } from './helpcenter/verification/verification.component';
import { UnleashedComponent } from './helpcenter/unleashed/unleashed.component';
import { GettingstartedComponent } from './helpcenter/gettingstarted/gettingstarted.component';
import { TrailformComponent } from './trailform/trailform.component';

const routes: Routes = [

  {path: '',
  component: LayoutComponent,
  children:[
    {path: '', component: HomeComponent},
    {path: 'features', component: FeaturesComponent},
    {path: 'pricing', component: PricingComponent},
    {path: 'contact-us', component: ContactComponent},
    {path: 'frequently-asked-questions', component: FaqComponent},
    {path: 'privacy-policy', component: PrivacyComponent},
    {path: 'disclaimer', component: DesclaimerComponent},
    {path: 'cookies-policy', component:CookiepolicyComponent}
  ]
},
{path: 'solutions',
component: LayoutComponent,
children:[
  {path: '', pathMatch:'full', redirectTo:'solutions'},
  {path: '', component:SolutionsComponent},
  {path: 'deployable-solutions', component:DeployableComponent},
  {path: 'customizable-solutions', component:CustomizableComponent}
]
},
{path: 'industries',
component: LayoutComponent,
children:[
  {path: '', pathMatch:'full', redirectTo:'industries'},
  {path: '', component:IndustriesComponent},
  {path: 'government', component:GovernmentComponent},
  {path: 'education', component:EducationComponent},
  {path: 'healthcare', component:HealthcareComponent},
  {path: 'professional-services', component:ProfessionalComponent},
  {path: 'financial-services', component:FinancialComponent},
  {path: 'real-estate', component:RealestateComponent},
  {path: 'travel-and-hospitality', component:HospitalityComponent},
  {path: 'IT-and-ITES', component:ItesComponent}
]
},
{path: 'blog',
component: LayoutComponent,
children:[
  {path: '', pathMatch:'full', redirectTo:'blog'},
  {path: '', component:BlogComponent},
  {path: 'blog-content', component:BlogcontentsComponent}
]
},
{path: 'support',
component: LayoutComponent,
children:[
  {path: '', pathMatch:'full', redirectTo:'support'},
  {path: '', component:SupportComponent}
]
},
{path: 'help-center',
component: LayoutComponent,
children:[
  {path: '', pathMatch:'full', redirectTo:'help-center'},
  {path: '', component:HelpcenterComponent,
  children:[
    {path: 'getting-started', component:GettingstartedComponent},
    {path: 'account-settings', component:AccountsComponent},
    {path: 'integrations', component:IntegrationComponent},
    {path: 'manage-your-team', component:ManageteamComponent},
    {path: 'verification', component:VerificationComponent},
    {path: 'unleashed', component:UnleashedComponent}
  ]
}
]
},
{path: 'free-trail', pathMatch:'full', component:TrailformComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration:'enabled',
    anchorScrolling: "enabled",
    scrollOffset: [0, 10]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
