<div class="helcenterContent">
  <h4>Security</h4>
  <h5>Password Settings</h5>
  <p>
  Manage your account password settings.
</p>
  <h5>Captcha Verification settings</h5>
  <p>
  OfficeGaps uses CAPTCHA verification to protect against spam or other unauthorized account access.
</p>
  <h5>Facial Recognition settings</h5>
  <p>
  Here's how to set up a facial recognition login features to further secure your business data.
</p>
  <h5>Activity log/ Audit trail</h5>
  <p>
  Make use of Audit trails to record key details about transactions and processes for review in the future.
</p>
  <h5>Version Updates</h5>
  <p>
  Keep track of ongoing updates, management, and upkeep of your technology and tools.
</p>
  <h5>Customer Data protection</h5>
  <p>
  Know your data security status. Be familiar with your privacy policies.
</p>
<h4>
  Infrastructure Security
</h4>
<p>
The information contained in a data center should be protected from loss, malicious manipulation, and theft by business organizations that use it for all or even part of their operations.
</p>
<h4>
GDPR Compliance
</h4>
<p>
We continue to invest in privacy, security, and operational processes necessary to comply with GDPR and other applicable regulations as part of our ongoing compliance effort.
</p>
<h4>
Organizational security
</h4>
<p>
Managing how users interact with data and data processing systems is crucial to achieving organizational security.
</p>
<h4>
Compliance and Privacy
</h4>
<p>
The law of privacy compliance governs how our organization collects, processes, or maintains personal information according to legal and regulatory requirements.
</p>
<h4>
Standard SSL Certificate
</h4>
<p>
Using SSL certificates to protect your website's sensitive data is a smart move. OfficeGaps provides standard SSL certificates, protecting sensitive information, affirming your company's identity, and increasing customer trust.
</p>
<h4>
Two-factor authentication
</h4>
<p>
With OfficeGaps, users can set up two-factor authentication to ensure enhanced security for their accounts thus preventing unauthorized access and system breaches.
</p>
<h4>
Custom Domain Security Settings
</h4>
<p>
Learn how to configure your custom domain settings in OfficeGaps and enable security settings on each individual webpage.
</p>
<h4>
Security Audits and Reports
</h4>
<p>
Our security, privacy, compliance, and certifications are regularly verified by independent third parties, and we disclose audit reports as proof of compliance.
</p>

</div>
