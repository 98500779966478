import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blogcontents',
  templateUrl: './blogcontents.component.html',
  styleUrls: ['./blogcontents.component.scss']
})
export class BlogcontentsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
