<div class="helcenterContent">
  <h4>API Overview</h4>
 <h5>Welcome to OfficeGaps API</h5>
 <p>This API documentation describes the usage and resources needed for integrating with OfficeGaps API.</p>
  <h5>Getting listed on our Marketplace</h5>
  <p>Follow these steps to submit an app for listing on OfficeGaps App Marketplace.</p>
  <h5>Rate Limits and Accounts</h5>
  <p>Manage network traffic and API usage by configuring rate limiting on APIs and Plans.</p>
  <h5>Authentication</h5>
  <p>Users usually need to register for API keys or learn how to authenticate their requests before they can make requests with your API.</p>
  <h5>Testing</h5>
  <p>In this API testing article, you'll learn about the basics, and how you can successfully test your own APIs.</p>
  <h5>Client Libraries</h5>
  <p>Let's take a look at client libraries: what they are, whether or not you should automate the creation process, and how to get started.</p>
  <h5>API Usage Guidelines</h5>
  <p>APIs enable a wide range of applications, and we're excited to see what you build. Please refer to our Usage Guidelines for assistance as you begin your integration.</p>
  <h4>Listing your app</h4>

 <h5>Overview</h5>
 <h6>API developer guides & resources</h6>
 <p>Build solutions that bring your ideas to life and help your team do better work faster.</p>
<h6>Usage guidelines & rate limits</h6>
<p>With Rate Limiting, you can limit the number of API requests each API can receive in a given timeframe, thus controlling the traffic to the API.</p>
<h6>Breaking change guidelines</h6>
<p>Here are best practices to avoid and mitigate breaking changes for your API designs.</p>
<h6>Account Types</h6>
<p>According to the endpoint types you work with and the way you want data to be passed to and from the endpoint, you choose an API integration type.</p>

<h5>App Marketplace</h5>
<h6>Listing your app</h6>
<p>It is best to add your app to the apps marketplace after developing it to make it available to other OfficeGaps users.</p>
<h6>App listing requirements</h6>
<p>Listings on OfficeGaps Marketplace must meet the requirements outlined on this page before being published.</p>
<h6>App certification requirements</h6>
<p>Office Gaps certifies apps by reviewing and confirming they meet the requirements of reliability, performance, usability, accessibility, and security.</p>
<h6>Applying for app certification</h6>
<p>Learn how to apply for an API certification, submit a retest or reschedule request, and renew an existing API certification.</p>
<h6>Measuring app performance</h6>
<p>Measuring performance provides an important metric to help you asses the success of your app.</p>
<h5>OfficeGaps API rate limits</h5>
<p>Incorporating rate limiting into your API can help to protect it from being overburdened with requests and improve its overall performance.</p>
<h5>Check daily API usage</h5>
<p>This page describes how to use API metrics to track and understand your usage of OfficeGaps APIs.</p>
<h5>Get account details</h5>
<p>Learn how to retrieve API account information and to see the different account types and subtypes by following the reference guide.</p>
<h5>Developer preview</h5>
<p>Developer preview is a way for developers to get access to new OfficeGaps API products before they are more widely available.</p>
<h5>Authentication overview</h5>
<p>Here's an overview of the primary authentication types you'll encounter with OfficeGaps APIs.</p>
<h5>Developer tools overview</h5>
<p>The OfficeGaps  Cloud developer tools provide you a powerful set of capabilities for exploring the API and making it more efficient.</p>
<h4>Third party Integrations</h4>
<h5>3rd party website Integrations</h5>
<p>
Learn why 3rd website integrations are beneficial and the different ways to perform one.
</p>
<ul class="arrowlist">
  <li>Documentation</li>
<li>Features</li>
<li>Configuration</li>
<li>Support</li>
<li>Reliability</li>
<li>Security</li>
</ul>
<h5>Microsoft Face API</h5>
<p>
The Microsoft Face API uses state-of-the-art cloud-based face algorithms to detect and recognize human faces in images.
</p>
<ul class="arrowlist">
  <li>Documentation</li>
<li>Features</li>
<li>Configuration</li>
<li>Support</li>
<li>Reliability</li>
<li>Security</li>
</ul>
<h5>Power BI Report API</h5>
<p>
Detailed overview for the Power BI REST APIs which can be used for Power BI embedded analytics and automation.
</p>
<ul class="arrowlist">
  <li>Documentation</li>
<li>Features</li>
<li>Configuration</li>
<li>Support</li>
<li>Reliability</li>
<li>Security</li>
</ul>
<h5>UPS API Integration</h5>
<p>
Find out all you need to know about UPS Application Programming Interfaces (APIs) and how to integrate them into your systems.
</p>
<ul class="arrowlist">
<li>Documentation</li>
<li>Features</li>
<li>Configuration</li>
<li>Support</li>
<li>Reliability</li>
<li>Security</li>
</ul>
<h5>FedEx API Integration</h5>
<p>
Easily integrate FedEx services into your system to give customers a seamless experience.
</p>
<ul class="arrowlist">
  <li>Documentation</li>
<li>Features</li>
<li>Configuration</li>
<li>Support</li>
<li>Reliability</li>
<li>Security</li>
</ul>
<h5>QB API Integration</h5>
<p>
Integrate QB online with sales tracking, create and send invoices and manage accounting data on the cloud.
</p>
<ul class="arrowlist">
<li>Documentation</li>
<li>Features</li>
<li>Configuration</li>
<li>Support</li>
<li>Reliability</li>
<li>Security</li>
</ul>
<h5>DocuSign API Integration</h5>
<p>
The DocuSign REST API provides you with a powerful, convenient, and simple Web services API for interacting with DocuSign.
</p>
<ul class="arrowlist">
<li>Documentation</li>
<li>Features</li>
<li>Configuration</li>
<li>Support</li>
<li>Reliability</li>
<li>Security</li>
</ul>
</div>
