<div class="helcenterContent">
  <h4>Solutions</h4>
   <h5>Ready to deploy Solutions</h5>
  <p>Get your business ready on schedule and on budget with our ready-to-deploy solutions. Faster to implement, it is a cost-effective and reliable approach.</p>
  <h5>Customized Solutions</h5>
  <p>Our business software solutions help you meet your business needs by customizing existing business solutions or even creating new business module from scratch to fit your needs.</p>
   <h4>Organizations and Teams</h4>
   <h5>Organization -overview</h5>
   <p>Create an organization. Manage all features in your organization.</p>
   <h5>Teams – overview</h5>
   <p>Managing your staff effectively can be the key to success not just of your department, but of the company as a whole.</p>
   <h5>Team tabs and pages </h5>
   <p>Allow team members to access services and content in a dedicated space within a team or in a project. </p>
   <h4>Dashboards</h4>
   <h5>Admin dashboard settings</h5>
<p>Easily add users, manage devices, and configure security and settings so your data stays safe.</p>
<h5>User profile Dashboard settings</h5>
<p>Learn how to set up dashboard for team and users</p>
<h5>Create Custom Dashboards</h5>
<p>Create a custom dashboard from scratch. Select a dashboard layout and widgets that will give you what you need to visualize your data.</p>
<h4>Proposals</h4>
<h5>Create and manage Proposals</h5>
<p>Create a quote when you want to give your customer a quote, bid, or proposal for work you plan to do.</p>
<h5>Proposal status</h5>
<p>Learn how to set up and manage different statuses for your proposals.</p>
<h5>Proposal  approval</h5>
<p>Have a system in place to ensure that requests are reviewed, approved, or denied in a timely manner.</p>
<h5>Adding products and services (rate cards)</h5>
<p>Explore creating products and services, adding, and using them on work orders.</p>
<h5>Proposal Reporting</h5>
<p>Analyze and generate a report to track the efficiency of the business in processing proposals.</p>
<h4>
Orders
</h4>
<h5>Create and manage orders</h5>
<p>Learn how to manage processes of receiving, tracking, and fulfilling customer orders.</p>
<h5>Order approval</h5>
<p>Ensure that requests are reviewed, approved, or denied in a timely manner.</p>
<h5>Order status</h5>
<p>share data on orders, checks stock availability, and tracks order delivery, all of which can help ensure orders are filled accurately and on time.</p>
<h5>Integrations</h5>
<p>Make it easy for relevant business departments to access real-time order information at any given moment.</p>
<h5>Order reporting</h5>
<p>Analyze and generate a report to track the efficiency of the business in processing orders.</p>
<h4>
  Invoices
  </h4>
<h5>Create and manage Invoices</h5>
<p>Customize invoices to impress your clients-choose an invoice template, add your company logo, and modify colors and fonts.</p>
<h5>Invoice approval</h5>
<p>Simplify the invoice approval process by presenting the invoice information in digital format for easy review by the approver.</p>
<h5>Price lists settings</h5>
<p>Create a price list and link it to an item type and a list type, as well as how to make a price list the default price list for its item type/list type combination.</p>
<h5>Scheduled invoices</h5>
<p>You can schedule invoices to be paid, either on the next pay run or at a future date.</p>
<h5>Partial invoicing</h5>
<p>Makes it effortless to invoice the customer for a proportion of work and charge them for the rest when the project is finished.</p>
<h5>Invoice reminders</h5>
<p>Let customers know when they've missed paying an invoice by its due date.</p>
<h5>Time billing</h5>
<p>Calculate the time worked for a client, and use this information to compile and send invoices.</p>
<h5>Invoice reporting</h5>
<p>Analyze and generate a report to track the efficiency of the business in processing Invoices.</p>
<h4>
  Expenses
</h4>
<h5>Create and manage Expense </h5>
<p>Simplify your business's expense entry, streamline review, eliminate paper trail, and reduce administrative effort.</p>
<h5>Upload documents</h5>
<p>Learn how to Upload and attach required receipts and supporting documentation to your expense reports.</p>
<h5>Expense reporting</h5>
<p>Analyze and generate a report to track the efficiency of the business in managing expenses.</p>
<h4>
  Bills
</h4>
<h5>Add and modify Bills</h5>
<p>Add or edit bills to fix typos in the bill name, edit the description, extend the due date or change the amount.</p>
<h5>Bill Scheduling</h5>
<p>Schedule bills for future, recurring or partial payments.</p>
<h5>Bill  reporting</h5>
<p>Analyze and generate a report to track the efficiency of the business in processing bills.</p>
<h4>
  Budget
</h4>
<h5>Adding, modifying, and deleting a budget</h5>
<p>Get a clear overview in real time about product, project, department, etc. related cost and income.</p>
<h5>Budget types</h5>
<p>Set up and manage various budgeting processes for your business processes, products and services.</p>
<h5>Budget reporting</h5>
<p>Analyze and generate budget reports to track the efficiency of the business.</p>
<h4>
  Bulletin/ News feed
</h4>
<h5>Team updates</h5>
<p>Learn how Bulletin boards impart information and facilitate communication. Communicate policies and procedures to employees.</p>
<h5>Company updates</h5>
<p>It is essential to keep employees informed about the company, their jobs, and technological advances.</p>
<h4>
  Contact
</h4>
<h5>Create and manage contacts</h5>
<p>Create, view, and edit contacts, contact lists, and groups.</p>
<h5>Contact Fields</h5>
<p><p>You can create your own custom contact fields to meet your organization's needs. </p>
<h5>Contact Status</h5>
<p>Set up custom status for contacts depending upon their position in the pipeline.</p>
<h5>Contact tags and categories</h5>
<p>You can keep your contacts organized and make them easier to manage by assigning them to tags and categories.</p>
<h5>Assign contacts</h5>
<p>Learn how to assign contacts to departments or individuals based on the business needs.</p>
<h4>
  Help Desk
</h4>
<h5>Issue Tracker</h5>
<p>Utilize the inbuilt ticketing system to track customer service, IT support, and development inquiries.</p>
<h5>FAQ</h5>
<p>Learn how to utilize the FAQs section to find answers to most common questions.</p>

</div>
