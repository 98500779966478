import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unleashed',
  templateUrl: './unleashed.component.html',
  styleUrls: ['./unleashed.component.scss']
})
export class UnleashedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
