
<footer>

  <div class="containers">
    <div class="footcolumns">
      <div class="footaddress">
          <img src="../../assets/img/OG-logo.svg" alt="">
        <ul>
          <li>Johnson Technology Systems Inc.</li>
          <li>11490 Commerce Park Dr. #410, Reston, Virginia 20191</li>
          <!-- <li>IN Tel: <a href="tel:+914844070368"> +91 484 407-0368 </a> |  IN Tel: <a href="tel:+918041114111">+91 80 4111-4111</a></li> -->
          <li>US Tel: <a href="tel:+12024645874">+1(202)464-JTSi(5874)</a></li>
          <li>Email: <a href="mailto:bd@jtsusa.com">bd@jtsusa.com</a> | Support: <a href="mailto:support@jtsusa.com">support@jtsusa.com</a></li>
        </ul>
      </div>
      <div class="footlinks">
        <h3>
          Quick Links
        </h3>
        <ul>
          <li><a [routerLink]="['/solutions']">Solutions</a></li>
          <li><a [routerLink]="['/industries']">Industries </a></li>
          <li><a [routerLink]="['/features']">Features </a></li>
          <li><a [routerLink]="['/pricing']">Pricing </a></li>
          <li><a [routerLink]="['/contact-us']">Contact</a></li>
        </ul>
      </div>
      <div class="footletter">
        <h3>
          Newsletter
        </h3>
        <form class="footform">
    <input type="email"  id="newsletter" placeholder="Email address*">
    <button type="submit">Subscribe</button>
        </form>
        </div>
    </div>
    <div class="copyrights">
      <div class="footmedias">
          <ul class="socialinks">
            <li>
              <a href="#">
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#fboutline"></use>
                </svg>
              </a>
            </li>
            <li>
              <a href="#">
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#mail"></use>
                </svg>
              </a>
            </li>
            <li>
              <a href="#">
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#twitteroutline"></use>
                </svg>
              </a>
            </li>
            <li>
              <a href="#">
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#instaGram"></use>
                </svg>
              </a>
            </li>

            <li>
              <a href="#">
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#youtube"></use>
                </svg>
              </a>
            </li>
            <li>
              <a href="#">
                <svg>
                  <use xlink:href="../../assets/img/sprite.svg#linkedoutline"></use>
                </svg>
              </a>
            </li>
          </ul>
            </div>
    <p> &copy; 2021, <a [routerLink]="['']">OfficeGaps.</a> All Rights Reserved. </p>
    <p>
      <a [routerLink]="['/frequently-asked-questions']">FAQ</a> |
      <a [routerLink]="['/privacy-policy']">Privacy Policy</a> |
      <a [routerLink]="['/disclaimer']">Disclaimer</a>
    </p>
    </div>
  </div>
  </footer>

<!-- Modal -->
<div class="modal fade" id="producTour" tabindex="-1" aria-labelledby="producTourLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">

      <div class="modal-body">
        <div class="modalcolumns">
          <picture>
            <!-- <img src="../../assets/img/popup-bg.jpg" alt=""> -->
          </picture>
          <article>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <h3>
              Your Business Simplified
            </h3>
            <ul class="nav nav-pills" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="pills-form-tab" data-bs-toggle="pill" data-bs-target="#pills-form" type="button" role="tab" aria-controls="pills-form" aria-selected="true">Form</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-address-tab" data-bs-toggle="pill" data-bs-target="#pills-address" type="button" role="tab" aria-controls="pills-address" aria-selected="false">Address</button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="pills-form" role="tabpanel" aria-labelledby="pills-form-tab">
                <form action="">

                  <div class="splitform">
                    <input type="text" placeholder="Name*">
                    <input type="email" placeholder="Email*">
                </div>
                <input type="number" placeholder="Phone number">
                <textarea id="exampleFormControlTextarea1" rows="3"></textarea>
                <button class="btn btn-dark"  type="submit">Submit</button>

                </form>
              </div>
              <div class="tab-pane fade" id="pills-address" role="tabpanel" aria-labelledby="pills-address-tab">
                <h4>
                  Phone
                </h4>
                <p>
                  Washington DC Tel: 703.949.9100
                  <br>
                  New Jersey Tel: 973.328.8000
                </p>
                <h4>
                  Address
                </h4>
                <p>
                    Johnson Technology Systems Inc.
                    <br>
                    11490 Commerce Park Dr. #410, Reston, Virginia 20191
                </p>

                  <h4>
                    E-mail
                  </h4>
                  <p>
                    Business Development: bd@jtsusa.com
                    <br>
                    Support: support@jtsusa.com
                  </p>
              </div>
            </div>

          </article>
        </div>

      </div>

    </div>
  </div>
</div>

<!-- Modal -->

<div class="loginModal modal fade" id="loginModal" tabindex="-1" aria-labelledby="loginModal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header text-center">
        <h2 class="modal-title w-100" id="loginModal">Welcome to <span>OfficeGaps</span></h2>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <label for="recipient-name" class="col-form-label">Email Address</label>
          <input type="text" placeholder="Enter email address" class="form-control" id="loginEmailaddress">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary"><a [routerLink]="['/trailform']">Free Trial</a></button>
        <button type="button" class="btn btn-primary"><a [routerLink]="['/pricing']">Plans</a> </button>
      </div>
    </div>
  </div>
</div>

<button type="button" class="scrollToTopBtn">
  <i class="bi bi-chevron-up"></i>
</button>

<div class="cookies-infobar">
      <div class="cookies-infobar_content">
        Welcome to <a [routerLink]="['']">OfficeGaps</a>, We use cookies to enhance your experience. By entering you agree to the
  <a [routerLink]="['/cookies-policy']">cookie policy</a>

  <!-- <form class="cookieOptions" action="">
  <div class="form-check form-check-inline">
    <input class="form-check-input" type="checkbox" id="Necessary" value="Necessary" checked>
    <label class="form-check-label" for="Necessary">Necessary</label>
  </div>
  <div class="form-check form-check-inline">
    <input class="form-check-input" type="checkbox" id="Functional" value="Functional">
    <label class="form-check-label" for="Functional">Functional</label>
  </div>
  <div class="form-check form-check-inline">
    <input class="form-check-input" type="checkbox" id="Marketing" value="Marketing">
    <label class="form-check-label" for="Marketing">Marketing</label>
  </div>
</form> -->
</div>
  <div class="cookies-infobar_wrapper">
    <button  id="cookies-infobar-accept" class="cookies-infobar_btn">Accept</button>
    <!-- <button  class="cookies-infobar_btn">Reject</button> -->
  </div>
</div>
