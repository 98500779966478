<a [routerLink]="['/blog/blog-content']" class="list">
  <picture>
  <img src="../../assets/img/b_sub1.jpg" alt="">
</picture>
  <div class="txts">
    <p>21 Oct 2021</p>
    <h4>How to choose a Business Management Software?</h4>
    <p>By Mark Twain</p>
  </div>
</a>

<a [routerLink]="['/blog/blog-content']" class="list">
  <picture>
  <img src="../../assets/img/b_sub2.jpg" alt="">
</picture>
  <div class="txts">
    <p>21 Oct 2021</p>
    <h4>A guide to OfficeGaps software in Business Management</h4>
    <p>By Mark Twain</p>
  </div>
</a>

<a [routerLink]="['/blog/blog-content']" class="list">
  <picture>
  <img src="../../assets/img/b_sub3.jpg" alt="">
</picture>
  <div class="txts">
    <p>21 Oct 2021</p>
    <h4>Business Management tips for SMEs</h4>
    <p>By Mark Twain</p>
  </div>
</a>

<a [routerLink]="['/blog/blog-content']" class="list">
  <picture>
  <img src="../../assets/img/b_sub4.jpg" alt="">
</picture>
  <div class="txts">
    <p>21 Oct 2021</p>
    <h4>Benefits of Cloud based SaaS solutions for your business</h4>
    <p>By Mark Twain</p>
  </div>
</a>
