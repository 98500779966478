<div class="hero-inner industry_travel">
  <div class="containers">
   <h1>
       <small>
        <a [routerLink]="['/industries']">Industries</a> <i class="bi bi-arrow-right"></i> Travel and hospitality
       </small>
       Meet all the demands of the <br> modern tourism sector
     </h1>
 </div>
</div>

<section class="sections">
<div class="containers">
   <div class="indus_details">
       <picture>
           <img src="../../assets/img/bg_travel.jpg" alt="">
       </picture>
       <article>
           <h2>
               Travel and hospitality
           </h2>
           <p>
               OfficeGaps covers all important functions of your travel business such as human resource, management of finance, maintenance, stocking, projects, investment, risk, decision-making, payoff analyzing, region planning, region business, intelligent traffic, etc. Our solution comprises of easy-to-use business modules, which simplifies the challenges hospitality managers come across.
           </p><p>
ERP system tackles the issues of a distributed architecture by offering a travel business with an integrated suite of applications for the front office, mid-office and back-office business processes. Workflows are also highly scalable, and management not only has more accurate operational information, but also has more visibility into, and control over, activities taking place in the business.
</p><p>
Easily streamline all inquiries and follow-up processes effectively. Customer lifecycles can be tracked, so any gaps in the system can be identified and rectified as soon as possible. By automating repeated processes like generating invoices, receipts, profit and loss statements, and more through OfficeGaps, tourism companies can unload complex financial functions from the shoulders of travel agents and make them focus more on customer satisfaction.
           </p>
       </article>
   </div>

   <div class="bordered_columns">
       <div class="box">
         <div class="numb">
             <span>1</span>
           </div>
           <h4>
               Improved Client Supplier Relations
           </h4>
          <p>
           Automate the creation of associated travel and fiscal documents using third-party systems; manage quotes, requests, and sales across channels
          </p>
       </div>
       <div class="box">
           <div class="numb">
               <span>2</span>
             </div>
             <h4>
               Third-party Management
             </h4>
            <p>
               Role-based access facilitates information sharing among departments and external entities. It also enhances communication across multiple channels and increases ROI
            </p>
         </div>
         <div class="box">
           <div class="numb">
               <span>3</span>
             </div>
             <h4>
               Integrated Business Processes
             </h4>
            <p>
               Multiple business processes can be integrated into a centralized application, which simplifies data analysis
            </p>
         </div>
   </div>
</div>
</section>
<app-weeklyupdate></app-weeklyupdate>

<section class="sections">
 <div class="sectitle_01">
   <h2>
       Get to know us better
   </h2>
   <p>
       We'll do everything we can to make our next best project!
   </p>
 </div>

 <div class="containers">

   <div class="accordion accordion-flush" id="industrial">
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingOne">
         <button class="accordion-button collapse" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseOne" aria-expanded="false" aria-controls="faq-collapseOne">
           Manage revenue, pay suppliers, and invoice clients, all on a single platform
         </button>
       </h2>
       <div id="faq-collapseOne" class="accordion-collapse collapse show" aria-labelledby="faq-headingOne" data-bs-parent="#industrial">
         <div class="accordion-body">
      <p>
       OfficeGaps provides the user with a quick snapshot of transactions, budgeting, customer relationship, and facilitate communication with suppliers, vendors, and other third-party agents seamlessly.
      </p>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingTwo">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseTwo" aria-expanded="false" aria-controls="faq-collapseTwo">
           Provides a complete overview of customer data on a single dashboard, allowing for easy access
         </button>
       </h2>
       <div id="faq-collapseTwo" class="accordion-collapse collapse" aria-labelledby="faq-headingTwo" data-bs-parent="#industrial">
         <div class="accordion-body">
          <p>
           In addition to displaying an abundance of information on a single dashboard, this software allows you to access customer data - names, addresses, previous contacts, etc. - quickly and easily. Users can solve queries and problems effectively too, boosting customer engagement and loyalty.
          </p>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingThree">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseThree" aria-expanded="false" aria-controls="faq-collapseThree">
           Travel agencies have access to reliable reports on all major metrics in one place
         </button>
       </h2>
       <div id="faq-collapseThree" class="accordion-collapse collapse" aria-labelledby="faq-headingThree" data-bs-parent="#industrial">
         <div class="accordion-body">
         <p>
           OfficeGaps provides agencies with  powerful data and reporting tools to help managers plan for these disruptions and create failsafe strategies to deal with them when they occur. These reporting and data analysis capabilities also help TMCs estimate and forecast; organize, plan and control; make highly informed decisions to improve operations; and increase revenues and elevate profitability.
         </p>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingFour">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseFour" aria-expanded="false" aria-controls="faq-collapseFour">
           Segmented data sets can be used to discover trends among lucrative demographics, like frequent flyers
         </button>
       </h2>
       <div id="faq-collapseFour" class="accordion-collapse collapse" aria-labelledby="faq-headingFour" data-bs-parent="#industrial">
         <div class="accordion-body">
          <p>
           From sales averages to conversions rates, travel agencies at access to a wide range of data at their fingertips. It also allows us to segment data to discover trends among lucrative demographics, like frequent flyers. These data enable tour agencies to manage cash flow, create marketing campaigns and cut costs across their organizations.
          </p>
         </div>
       </div>
     </div>

   </div>

   </div>
   </section>
