<div class="hero-inner privacy">
  <div class="containers">
  <h1>
    <small>
       Privacy Policy
    </small>
    Information about our privacy practices <br> and security measures
  </h1>
 </div>
</div>

<div class="containers">
  <div class="floated-title centered">
    <h2>
     Privacy is of utmost importance to us
    </h2>
    <p>
     Providing us with your personal information is a great act of trust and we promise to <br> only use that information in a fair and ethical manner
    </p>
  </div>
</div>

<section class="sections nptop">
   <div class="container">
       <div class="commonContent">
           <p>
             We at OfficeGaps respect and protect your privacy. This Privacy Policy describes OfficeGaps ' policies and practices regarding the use and disclosure of your personal information. We may revise and update this Privacy Policy at any time without prior notice. It is therefore important that you review this Privacy Policy periodically to understand how OfficeGaps collects and uses personal information. When using our solutions, you agree to the collection, use, and disclosure of your personal information by OfficeGaps as explained below.
        </p>
           <h4>
             Changes to our Policy
           </h4>
           <p>
             We may make changes and updates to our Policy from time to time, so please check it back frequently for any updates.  In the event of a material change to the Policy, we will notify you by posting the update on the Site, sending you an email, or otherwise notifying you.  The Site, the Service, the Terms and/or this Policy may be updated by us at any time without prior notice to you, and at our sole and absolute discretion.  You acknowledge any changes to the Policy by continuing to use the Service after the effective date of any modifications thereto, either by registering or by simple use.
           </p>
           <h4>
             Personal Information We May Collect
           </h4>
           <p>
             In the context of interaction with us, we may collect or receive the following types of information from or about you:
           </p>
           <ul class="arrowlist">
             <li>You may provide us with your name, title, company name, address, phone number, and e-mail address (such as when registering for whitepapers or requesting other information, services, or support from us) when posting materials or inquiries on our website.</li>
<li>In the event that you correspond with us via email, we may collect the Personal Information contained in the email.</li>
<li>Our business partners may provide us with Personal Information, such as information about prospects and sales leads.</li>
<li>Our website may collect Personal Information you submit in our forms or via chat.</li>
<li>As with most other websites, we use “cookies” to collect visitor information. Based on your actions on our website, we may also use this collected data to provide you with information we believe will be of interest to you.</li>

           </ul>
    <h4>
     How We May Use Personal Information
    </h4>
    <p>
     The information we collect about you or that you provide to us, including Personal Information, may be used in any of the following ways:
    </p>
    <ul class="arrowlist">
     <li>The Website and Software Services we offer are made possible using data we collect. Operation, maintenance, and access to the Website and Software Services are part of this responsibility.</li>
     <li>We will use your information to keep you informed about OfficeGaps and our solutions, services, and partners, including to send you marketing promotions.</li>
     <li>To identify and resolve issues on our website, or to further improve our software services.</li>
     <li>To maintain the safety of our services, employees, and users, to detect and prevent fraud, and to resolve disputes.</li>
     <li>For administrative purposes, such as information related to the services and changes to our terms, conditions, and policies regarding our Websites and Software Services.</li>
     <li>For the performance of our obligations and the enforcement of our rights under any agreements we may have with you.</li>
     <li>In order to analyze the patterns of usage on the Website and Software Solutions.</li>
     <li>To configure OfficeGaps solutions for organizations and individuals.</li>

    </ul>

            <h4>
             How We May Share Personal Information
            </h4>
            <p>
             Following are possible disclosures of your Personal Information
            </p>
            <ul class="arrowlist">
             <li>To our affiliates and subsidiaries as necessary for the provision of our products and services.</li>
             <li>To our service providers who support our business. Except for the purposes of performing certain services on our behalf or complying with legal requirements, we do not authorize these service providers to use or disclose your Personal Information. We require our service providers to protect the privacy and security of the personal information they handle on our behalf.</li>
             <li>Furthermore, we reserve the right to disclose any Personal Information that we believe, in good faith, is appropriate or necessary for any of the purposes listed below.
               <ul>
                 <li>Avoid liability</li>
<li>Prevent fraudulent, abusive, or unlawful activities</li>
<li>Investigate and defend third-party claims and allegations</li>
<li>Ensure the security and integrity of our systems</li>
<li>Protect our rights or those of others</li>

               </ul>
             </li>

            </ul>
            <h4>
             Data Security
            </h4>
            <p>
             As part of our engagement to provide you with services, we take reasonable steps to protect your Personal Information from loss, misuse, interference, unauthorized access, disclosure, alteration, and destruction. Information transmitted through the Internet cannot be guaranteed to be secure, and we have no complete control over its security. In the event that you receive a password for access to certain parts of our website or the Software Services, it is your responsibility to keep this information confidential. Be sure not to share your password with anyone.
            </p>
            <h4>
             Cross-Border Data Transfers
            </h4>
            <p>
             Depending on the nature of the service, we may transfer your Personal Information outside of the country from which it was originally collected. This Privacy Policy describes how we will protect your Personal Information when it is transferred to other countries.
           </p><p>
             As a condition of using the Software Services or submitting Personal Information to us, you consent to the transfer of Personal Information to countries outside of your country of residence, including the U.S. When you submit Personal Information from the EU, we comply with applicable legal requirements that provide adequate protection to personal data sent abroad.
            </p>
            <h4>
             Get in touch with us
            </h4>
            <p>
             Please feel free to contact us at to ask questions about this Policy, the Website, or the Software Services, to opt out from certain services, or to exercise your rights.
            </p>
            <ol>
             <li>Via Email: <a href="mailto:support@Officegaps.com">support@Officegaps.com</a></li>
             <li>Via Contact numbers provided on our <a href="contact-us.html">Contact Us</a> page</li>
            </ol>
       </div>
   </div>
</section>
