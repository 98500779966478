<div class="helcenterContent">
  <h4>Projects</h4>
  <h5>Create and Manage Projects</h5>
  <p>Create a new project file and then get your project going. Set the start date, project title, add tasks, and show the project summary task.</p>
<h5>Project list views</h5>
<p>You can customize the default project tasks list view to include different columns and unique layouts to meet your needs.</p>
<h5>Assign Projects</h5>
<p>Team members are assigned at the organization level and can be shared across multiple projects.</p>
<h5>Project Workflow</h5>
<p>A project workflow is a carefully planned sequence of the tasks and activities you need to do to complete a specific project.</p>
<h5>Project Approval</h5>
<p>The project approval process allows administrators to set up rules for when surveys require approval before they are published.</p>
<h5>Project Dashboard</h5>
<p>A project dashboard is one that offers a 360° overview of a project's status, insights, and data points.</p>
<h5>Project progress </h5>
<p>Keep your project progress organized by breaking it into as many achievable tasks as possible.</p>
<h5>Project Templates</h5>
<p>Save time and scale your best practices with project templates.</p>
<h5>Project Estimated Duration</h5>
<p>Project managers can rely on estimated duration reliability to conduct their projects and deliver in accordance with allocated costs and duration.</p>
<h5>Project Budget</h5>
<p>The budget for a project is the combined costs of all activities, tasks, and milestones that the project must fulfill.</p>
<h5>Status update</h5>
<p>Project status reports are timely updates on the progress of your projects.</p>
<h4>
  Tasks/ To do Lists
</h4>
<h5>Create and Manage Tasks</h5>
<p>Learn how to create and manage tasks in OfficeGaps with tips, examples, and best practices.</p>
<h5>Assign Tasks</h5>
<p>Assign tasks to other collaborators in your project to delegate responsibilities.</p>
<h5>Task Status</h5>
<p>A Task Status specifies the stages through which your tasks progress within a work project.</p>
<h5>Task Comments</h5>
<p>Comment on a task to ask questions, provide extra information, or offer insight.</p>
<h5>Task Attachments</h5>
<p>Attach files to any task to quickly share relevant documents with your project collaborators.</p>
<h5>Task Reporting</h5>
<p>Report on task and project progress by using task-related reports or create your own custom task reports.</p>
<h5>Linking tasks to Project</h5>
<p>After tasks are created in a project, learn how to link tasks so they represent your project.</p>
<h4>
  Time sheet
</h4>
<h5>Time sheet views</h5>
<p>With the Timesheet view, your team can quickly look at their weekly time-use in a straightforward grid view, and easily add new or modify existing time entries.</p>
<h5>Time sheet entry</h5>
<p>Time sheet entry allows users to enter time and expenses against projects and provide managers visibility into project and resource performance.</p>
<h5>Time Tracking</h5>
<p>Simple yet powerful employee work tracker with screenshots, timesheets, billing, in-depth reports, and more.</p>
<h5>Time sheet approval</h5>
<p>Timesheet Approvals allow you to easily review and approve hours to run a payroll, when your employees have already submitted their time.</p>
<h5>Time sheet reports</h5>
<p>You'll learn how to create a robust timesheet report and analyze your team's logged time, billable hours, as well as overtime.</p>
<h5>Linking activity to timesheet</h5>
<p>Track extra details on timesheets, like mileage, equipment, tasks, and more!</p>
<h4>
  Reports
</h4>
<h5>Work Summary Report</h5>
<p>Work reports are typically used to explain your progress on a work project or provide your conclusions and recommendations regarding a workplace issue.</p>
<h5>Timesheet Report</h5>
<p>Generate Timesheet Reports for any time range, which may easily be exported to generate a report for payroll records.</p>
<h5>Sales Report</h5>
<p>Analyze and generate a report to track the efficiency of the business in Sales and marketing.</p>
<h5>Financial report</h5>
<p>Automate financial statements and generate Balance Sheets, Income Statements, Cash Flow Statements, ratios and KPIs for financial and management accounting.</p>
<h5>Budget Report</h5>
<p>Enable planning out how much profit you intend to make based on your projected revenue, expenses, and cost of goods sold.</p>
<h5>Expense Report</h5>
<p>Track every purchase your enterprise makes, or every financial obligation incurred by your business.</p>
<h4>
  Workflows
</h4>
<h5>Create a workflow</h5>
<p>Create a workflow to automate your marketing, sales, and service processes and make your team more efficient.</p>
<h5>Customize workflow</h5>
<p>Custom workflows let everyone within your company structure their work processes to move faster and know where things stand.</p>
<h5>Set up automation</h5>
<p>OfficeGaps makes it easier for your organization to streamline its workflows and identify areas that can be automated to increase efficiency.</p>
<h5>User access and rights</h5>
<p>Understand user accounts and permissions. Create and manage user rights.</p>
<h4>
  Approvals
</h4>
<h5>Task Approvals </h5>
<p>Define approvals for all tasks and associate users or groups to a task to approve or reject them.</p>
<h5>Project Approvals</h5>
<p>The Project Approval Process is a defined Approval Process that automates, regulates, and tracks approving/denying Projects.</p>
<h5>Timesheet Approvals</h5>
<p>The Timesheet Approval feature allows project managers to approve time submitted by users on their timesheets.</p>
<h5>Approval workflow</h5>
<p>Create specific approval processes or workflows that dictate how work should be approved (or rejected) every time to save time and ensure standardization.</p>

</div>
