<div class="hero-inner industry_professional">
  <div class="containers">
   <h1>
       <small>
        <a [routerLink]="['/industries']">Industries</a> <i class="bi bi-arrow-right"></i> Professional Services
       </small>
       Improved process control at all stages
     </h1>
 </div>
</div>

<section class="sections">
<div class="containers">
   <div class="indus_details">
       <picture>
           <img src="../../assets/img/bg_professional.jpg" alt="">
       </picture>
       <article>
           <h2>
               Professional Services
           </h2>
           <p>
               OfficeGaps solutions provide modern features and benefits that bring new opportunities to professional services firms. Built specifically for service-oriented businesses to manage and optimize the project or service lifecycle. As a result, services firms are able to cater to the diverse needs of clients.
           </p><p>
               A more project-driven than traditional product-based ERP and provides greater flexibility and automation in features like project management, resource management and billing. It provides more detailed knowledge of project schedules, budgets, and finances. Achieve greater clarity of your staff’s schedules, availability and assigned work to plan projects quickly and nimbly.
           </p><p>
               Delivers superior value as an ERP for the professional services industry by allowing for flexible deployment, enhanced visibility and streamlined functionality that enables businesses to navigate their industry trends and pain points more easily.

           </p>
       </article>
   </div>

   <div class="bordered_columns">
       <div class="box">
         <div class="numb">
             <span>1</span>
           </div>
           <h4>
               Better Customer Service
           </h4>
           <p>
               Conduct lead generation, field activity planning, RFP and quote responses, and account management support by defining customers, negotiating contracts, etc.
           </p>
       </div>
       <div class="box">
           <div class="numb">
               <span>2</span>
             </div>
             <h4>
               Enhanced Collaboration
             </h4>
             <p>
               Conduct a comprehensive lifecycle of a contract, including activities like project initiation, timesheet management, project expense management, and invoicing
             </p>
         </div>
         <div class="box">
           <div class="numb">
               <span>3</span>
             </div>
             <h4>
               Greater Financial Visibility
             </h4>
             <p>
               Tools such as Revenue Recognition, Project Budgeting, Project Revenue and Profitability help you to track and manage financial performance of your projects in real-time
             </p>
         </div>
   </div>
</div>
</section>
<app-weeklyupdate></app-weeklyupdate>
<section class="sections">
 <div class="sectitle_01">
   <h2>
       Get to know us better
   </h2>
   <p>
       We'll do everything we can to make our next best project!
   </p>
 </div>

 <div class="containers">

   <div class="accordion accordion-flush" id="industrial">
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingOne">
         <button class="accordion-button collapse" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseOne" aria-expanded="false" aria-controls="faq-collapseOne">
           Provide services that are managed consistently from planning through monitoring
         </button>
       </h2>
       <div id="faq-collapseOne" class="accordion-collapse collapse show" aria-labelledby="faq-headingOne" data-bs-parent="#industrial">
         <div class="accordion-body">
          <p>
           Our SaaS solutions enable you to optimize both your back-end processes and front-end procedures to create a fully integrated, coordinated system. Comprehensive reporting functionality delivers an integrated view of all of your front-end touchpoints to enable complete management of customer lifecycles.
          </p>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingTwo">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseTwo" aria-expanded="false" aria-controls="faq-collapseTwo">
           A range of tools are available for managing tasks, invoicing, resources, reporting, and collaboration
         </button>
       </h2>
       <div id="faq-collapseTwo" class="accordion-collapse collapse" aria-labelledby="faq-headingTwo" data-bs-parent="#industrial">
         <div class="accordion-body">
           <p>
               Additional automation features can be deployed, all of which empower you to align the functions of each business unit with core organizational objectives.  By integrating the various applications involved into one system, our comprehensive SaaS solution enables you to capture and analyze information from all segments of your business.
           </p>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingThree">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseThree" aria-expanded="false" aria-controls="faq-collapseThree">
           Improve your ability to plan projects quickly and nimbly with greater visibility
         </button>
       </h2>
       <div id="faq-collapseThree" class="accordion-collapse collapse" aria-labelledby="faq-headingThree" data-bs-parent="#industrial">
         <div class="accordion-body">
          <p>
           OfficeGaps for professional services presents the ability to scale your solution according to your growth and without allowing your software to dictate the rate of your expansion. Consistently keep track of your costs, revenue, and human resources for more precise and effective operational management.
          </p>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingFour">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseFour" aria-expanded="false" aria-controls="faq-collapseFour">
           Create invoices, quotes, timesheets, reports, resource matching, and financial insight on the fly
         </button>
       </h2>
       <div id="faq-collapseFour" class="accordion-collapse collapse" aria-labelledby="faq-headingFour" data-bs-parent="#industrial">
         <div class="accordion-body">
           <p>
               A consistent, holistic view of all your financial data that is captured and delivered on demand ensures accurate revenue management and cost management across your value chain. Keep track of your deliverables and produce detailed reports using user-defined data fields.
           </p>
         </div>
       </div>
     </div>

   </div>

   </div>
   </section>

