import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ites',
  templateUrl: './ites.component.html',
  styleUrls: ['./ites.component.scss']
})
export class ItesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
