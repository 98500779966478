<div class="hero-inner disclaimer">
  <div class="containers">
  <h1>
    <small>
       Disclaimer
    </small>
    Policy governing the information about <br> our products and services
  </h1>
 </div>
</div>

<div class="containers">
  <div class="floated-title centered">
    <h2>
     Limitations of liability
    </h2>
    <p>
     The information provided on the site is provided for informational purposes only, and we will not be liable <br> for any loss or damage you may suffer as a result of the use of the site
    </p>
  </div>
</div>

<section class="sections nptop">
   <div class="container">
       <div class="commonContent">
          <p>
           The following Agreement outlines the terms and conditions that apply to the use of this site and its sub-sites.
          </p>

    <h4>
     General Terms & Conditions
    </h4>
    <ul class="arrowlist">
     <li>The purpose of this website is to improve public access to general information about OfficeGaps software. Our efforts are to ensure that the information is current and accurate. In the event that errors are pointed out, we will correct them as soon as possible. We do not, however, assume any responsibility or liability for the content on this site.</li>
     <li>Information contained on this site is of a general nature only and is not intended to address specific circumstances of any individual or entity. This information is not necessarily comprehensive, complete, accurate or current. It may contain links to external sites over which we have no control and which we do not assume responsibility for . It is not a professional or legal opinion.</li>
     <li>We strive to minimize disruptions caused by technical errors. However, some data or information on our site might have been created or structured in a way that is not error-free, and we cannot guarantee that our service will not be interrupted. If you encounter such problems while using this site or any link to an external site, we accept no responsibility.</li>
    </ul>
           <h4>
             User Conduct
           </h4>
           <p>
             This site may only be used for lawful purposes. Users are prohibited from posting any material on this site that violates or infringes upon the rights of others, or which is unlawful, threatening, abusive, defamatory, infringing upon privacy, obscene, profane, or otherwise objectionable, that encourages conduct that could lead to criminal prosecution, civil liability, or otherwise violate the law, or that contains advertisements or solicitations with respect to products or services without the Company's express prior approval.
           </p>
           <h4>
             Prohibited Conduct
           </h4>
           <p>
             These activities are strictly prohibited:
           </p>
           <ul class="arrowlist">
             <li>Spam, advertising, solicitation, or political campaigning</li>
<li>Abuse of any members, moderators, or administrators of a forum</li>
<li>Any unlawful or fraudulent postings (including links)</li>
<li>Posting of personal information </li>
<li>Phishing</li>
<li>Posting materials containing viruses or malware</li>
<li>Posting of offensive content, including profanity, obscenity, racism, or nudity</li>
<li>Posting material that is defamatory or infringes on anyone's rights</li>
<li>Unauthorized attempts to access any part of the site</li>

           </ul>
           <h4>
             External links
           </h4>
           <p>
             It is possible for the Site to contain links to other websites or third-party content, or banners or other advertising that contains links to other websites. Neither we, nor any other third parties, investigate, monitor, or verify the reliability, validity, accessibility, or completeness of external links.
           </p><p>
The site does not guarantee, endorse, or warrant the accuracy or reliability of any information provided by third-party websites listed on the site or any banner or other form of advertising that links to a third-party website. It will not be our responsibility to monitor or be a party to any transaction between you and third-party providers.
           </p>
           <h4>
             Subscription Services
           </h4>
           <p>
             Despite the fact that the Company offers services across the world, certain services may not be available in or suitable for certain countries or locales. All of our services are subject to discontinuation or change at any time without prior notification.
           </p>
           <h4>
             Testimonials
           </h4>
           <p>
             User testimonials may be found on the Site regarding our products or services. Such testimonials reflect actual experiences and opinions of users. The experiences, however, are unique to those users, and may not be representative of all users of our products and/or services. It is not our intention, and you shouldn't assume, that all users will have the same experience. Results will vary for each user. For the sake of brevity, some testimonials have been shortened when the full testimonial contained unnecessary details.
           </p>
           <p>
             Views and opinions expressed in testimonials are solely those of the individual users, and do not reflect our own.
           </p>
           <h4>
             Copyright Notice
           </h4>
           <p>
             All reproductions of the website, including the copying of any information, text, or image, are strictly forbidden. For any use of content specified on this website, written permission must be obtained from us. The permission is granted only upon your agreement to acknowledge the source and provide a link to this site (without rel="nofollow" attribute) at the destination. Textual and multimedia information (sound, images, videos, software, etc.) must be reproduced or used with prior authorization.
           </p>
       </div>
   </div>
</section>
