<p>pricing works!</p>
<div class="hero-inner pricing">
  <div class="containers">
  <h1>
    <small>
      Pricing
    </small>
   ERP Software for Fast-Growing Companies
  </h1>
 </div>
</div>

<div class="containers">
  <div class="floated-title centered">
    <h2>
     We Strive to Grow Your Business
    </h2>
    <p>
     Cloud-based ERP Software providing complete end-to-end functionality and ensuring maximum cost effectiveness.
    </p>
    <p>
     Plans for businesses and teams of all sizes and stages.
    </p>
  </div>
  <div class="price-table-new">

   <div class="pricePlans">
     <div class="planheading">
       <h3 class="orange">
        Features
       </h3>

       <p>
        The most comprehensive mobile attribution <br> and analytics for app marketers
       </p>

     </div>
     <div class="pricebody">
       <ul class="thick">
        <li>Option to Add controls for interfaces</li>
        <li>Forgot password and Reset through Email notification</li>
        <li>User configurable pages and workflow</li>
        <li>Email Notification configurable through Admin module</li>
        <li>Aduit Report for all Transactions</li>
        <li>Menu/Field level user rights configurations</li>
        <li>Transactions Export feature ( Export to excel/pdf )</li>
        <li>Transactions Export feature ( Export to excel/pdf )</li>
        <li>Scan and Upload documents</li>
        <li>Document manangement</li>
        <li>QR code scan Integration</li>
        <li>Google search integration from application</li>
        <li>Access to Admin Configuration module</li>
        <li>Option to Create New Interfaces</li>
        <li>Login with reCaptcha</li>
        <li>Login with Social Media ( Gmail / Facebook)</li>
        <li>SMS Notification cofigurable through Admin module</li>
        <li>Upload feature with drag and drop / Bulk upload</li>
        <li>Bulk Upload</li>
        <li>Document Editor from application ( word/excel/txt files )</li>
        <li>eFile Module</li>
        <li>Mail merge feature / Email sending feature from application</li>
        <li>Zoom meeting API integration to schedule meeting</li>
        <li>Chat feature for application users</li>
        <li>Option to Create New Modules</li>
        <li>Login with FACE ID</li>
        <li>Module Specific user Dsahboard</li>
        <li>Notification based on To-do Activity</li>
        <li>Define workflow for Approval Process</li>
        <li>Data level user access settings ( private and public data )</li>
        <li>Image Capture and upload
        <li>Outlook API integration to show email/events from outlook365</li>
        <li>AI Interface for data Analysis</li>
        <li>Power BI Analytical  Reports</li>
        </ul>
     </div>
   </div>

   <div class="pricePlans silver">
     <div class="planheading">
       <h3>
         Silver
       </h3>
       <h2>
        29<sup>.99</sup> USD
      </h2>
       <p>
         /month
         <span>
           billed annually
         </span>
       </p>
    <a class="subscribe-btn" href="#">Subscribe</a>

     </div>
     <div class="pricebody">
       <ul>
        <li>Yes</li>
        <li>Yes</li>
        <li>Yes</li>
        <li>Yes</li>
        <li>Yes</li>
        <li>Yes</li>
        <li>Yes</li>
        <li>Yes</li>
        <li>Yes</li>
        <li>Yes</li>
        <li>Yes</li>

<li>-</li>
<li>-</li>
<li>-</li>
<li>-</li>
<li>-</li>
<li>-</li>
<li>-</li>
<li>-</li>
<li>-</li>
<li>-</li>
<li>-</li>
<li>-</li>
<li>-</li>
<li>-</li>
<li>-</li>
<li>-</li>
<li>-</li>
<li>-</li>
<li>-</li>
<li>-</li>
<li>-</li>
<li>-</li>
<li>-</li>
       </ul>
     </div>
   </div>

    <div class="pricePlans gold">
      <div class="planheading">
        <h3>
          Gold
        </h3>
        <h2>
          49<sup>.99</sup> USD
        </h2>
        <p>
          /month
          <span>
            billed annually
          </span>
        </p>
     <a class="subscribe-btn" href="#">Subscribe</a>

      </div>
      <div class="pricebody">
        <ul>
          <li>Yes</li>
          <li>Yes</li>
          <li>Yes</li>
          <li>Yes</li>
          <li>Yes</li>
          <li>Yes</li>
          <li>Yes</li>
          <li>Yes</li>
          <li>Yes</li>
          <li>Yes</li>
          <li>Yes</li>

  <li>Yes</li>
  <li>Yes</li>
  <li>Yes</li>
  <li>Yes</li>
  <li>Yes</li>
  <li>Yes</li>
  <li>Yes</li>
  <li>Yes</li>
  <li>Yes</li>
  <li>Yes</li>
  <li>Yes</li>
  <li>Yes</li>
  <li>Yes</li>

  <li>-</li>
  <li>-</li>
  <li>-</li>
  <li>-</li>
  <li>-</li>
  <li>-</li>
  <li>-</li>
  <li>-</li>
  <li>-</li>
  <li>-</li>
         </ul>
     </div>
    </div>

    <div class="pricePlans platinum">
     <div class="planheading">
       <h3>
         Platinum
       </h3>
       <h2>
         69<sup>.99</sup> USD
       </h2>
       <p>
         /month
         <span>
           billed annually
         </span>
       </p>
       <a class="subscribe-btn" href="#">Subscribe</a>

     </div>
     <div class="pricebody">
      <ul>
        <li>Yes</li>
        <li>Yes</li>
        <li>Yes</li>
        <li>Yes</li>
        <li>Yes</li>
        <li>Yes</li>
        <li>Yes</li>
        <li>Yes</li>
        <li>Yes</li>
        <li>Yes</li>
        <li>Yes</li>

<li>Yes</li>
<li>Yes</li>
<li>Yes</li>
<li>Yes</li>
<li>Yes</li>
<li>Yes</li>
<li>Yes</li>
<li>Yes</li>
<li>Yes</li>
<li>Yes</li>
<li>Yes</li>
<li>Yes</li>
<li>Yes</li>
<li>Yes</li>
<li>Yes</li>
<li>Yes</li>
<li>Yes</li>
<li>Yes</li>
<li>Yes</li>
<li>Yes</li>
<li>Yes</li>
<li>Yes</li>
<li>Yes</li>
       </ul>
     </div>
   </div>
  </div>
</div>

<section class="sections">
 <div class="sectitle_01">
   <h2>
     Frequently Asked Questions
   </h2>
 </div>

 <div class="containers">

   <div class="accordion accordion-flush" id="faquestions">
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingOne">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseOne" aria-expanded="false" aria-controls="faq-collapseOne">
           Do you offer a free trial?
         </button>
       </h2>
       <div id="faq-collapseOne" class="accordion-collapse collapse" aria-labelledby="faq-headingOne" data-bs-parent="#faquestions">
         <div class="accordion-body">
           <p>
             Yes, without a doubt! There are no charges incurred during the free trial. A charge will be applied once you begin a paid subscription. If you haven't entered your payment method yet, you will not be charged. If you already have a subscription, we'll charge you after your trial expires. Check out our pricing plans for more information.
           </p>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingTwo">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseTwo" aria-expanded="false" aria-controls="faq-collapseTwo">
           What happens when I hit my usage limits?
         </button>
       </h2>
       <div id="faq-collapseTwo" class="accordion-collapse collapse" aria-labelledby="faq-headingTwo" data-bs-parent="#faquestions">
         <div class="accordion-body">
           <p>
             Users of OfficeGaps Cloud Platform will receive alerts when they approach or exceed their plan limits. All additional usage will be calculated based on the current rate card and billed monthly in arrears.
           </p><p>
As an alternative, you can upgrade your subscription to cover the remainder of your contract. If you anticipate further overages in the future, OfficeGaps recommends upgrading your subscription. In the event you upgrade your subscription, OfficeGaps will waive any overage fees incurred before the upgrade.
           </p>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingThree">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseThree" aria-expanded="false" aria-controls="faq-collapseThree">
           Is it easy to get started?
         </button>
       </h2>
       <div id="faq-collapseThree" class="accordion-collapse collapse" aria-labelledby="faq-headingThree" data-bs-parent="#faquestions">
         <div class="accordion-body">
           <p>
             OfficeGaps can be set up on your own, for free. A lot of customers do just that. Despite OfficeGaps' powerful, flexible nature, it can be a challenging process for companies to move all of their data to it.
           </p><p>
In addition, there are so many options that it can be confusing to navigate. With OfficeGaps, numerous integrations are possible. Large companies can hire OfficeGaps specialists to assist with setting up OfficeGaps and its relevant modules for their business.
           </p>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingFour">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseFour" aria-expanded="false" aria-controls="faq-collapseFour">
           Will my data be private and safe? Who owns my data?
         </button>
       </h2>
       <div id="faq-collapseFour" class="accordion-collapse collapse" aria-labelledby="faq-headingFour" data-bs-parent="#faquestions">
         <div class="accordion-body">
           <p>
             At OfficeGaps, we take reasonable steps to keep your information secure. All data in transit is encrypted using industry-standard security measures. Transport layer security ("TLS") or secure socket layer ("SSL") technology is commonly used to secure Internet traffic.
           </p><p>
As our servers are located in the United States, your information may be transferred to, stored in, or processed in the United States. We recognize the importance of maintaining the security and confidentiality of personal information. The access to your data is restricted to a few individuals on a "Need-to-know" basis.
</p><p>
With OfficeGaps, federal, state, and local government agencies have access to a dedicated cloud-based platform that is FedRAMP compliant.
           </p>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingFive">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseFive" aria-expanded="false" aria-controls="faq-collapseFive">
           What payment methods do you accept?
         </button>
       </h2>
       <div id="faq-collapseFive" class="accordion-collapse collapse" aria-labelledby="faq-headingFive" data-bs-parent="#faquestions">
         <div class="accordion-body">
           <p>
             JTSi accepts the following payment methods:
           </p>
           <ul>
             <li> <strong>Credit card</strong>   — (MasterCard, Visa, Discovery or American Express) via our secure Quote & Order Form</li>
<li><strong>PayPal</strong>   — Automatically renew our cloud products and other payments</li>
<li><strong>Bank transfer </strong>  — Clearance of bank transfers can take up to 14 business days. You can email your remittance advice to support@officegaps.com</li>
<li><strong>Mailed check</strong>  — Payments can take between 7-14 business days to be received and processed by our financial department</li>
           </ul>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingSix">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseSix" aria-expanded="false" aria-controls="faq-collapseSix">
           Can I get a demo of OfficeGaps?
         </button>
       </h2>
       <div id="faq-collapseSix" class="accordion-collapse collapse" aria-labelledby="faq-headingSix" data-bs-parent="#faquestions">
         <div class="accordion-body">
          <p>
           Yes. There are so many business applications available today that it can be hard to choose the one that's best for your needs. We know that business applications are an expensive and time-consuming investment. Our aim is to ensure all of our clients are happy with their decision.
          </p>
          <p>
           To be sure you make the right decision, we recommend participating in at least one software demo. The demo will serve as an introduction to the software. Seeing how it looks and works in person is a great experience. This will enable you to envision how it will work for you before buying it.
          </p>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingSeven">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseSeven" aria-expanded="false" aria-controls="faq-collapseSeven">
           Are there any setup fees?
         </button>
       </h2>
       <div id="faq-collapseSeven" class="accordion-collapse collapse" aria-labelledby="faq-headingSeven" data-bs-parent="#faquestions">
         <div class="accordion-body">
           <p>
             Yes. Setting up your account will cost you one-time setup charges.
           </p>
             <p>
The complexity and functionality of each client's account must be determined first before we can provide a quotation for setup. Most standard accounts are completed in under 45 days.
           </p>
         </div>
       </div>
     </div>
     <div class="accordion-item">
       <h2 class="accordion-header" id="faq-headingEight">
         <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-collapseEight" aria-expanded="false" aria-controls="faq-collapseEight">
           Can I upgrade or downgrade / switch plans at any time?
         </button>
       </h2>
       <div id="faq-collapseEight" class="accordion-collapse collapse" aria-labelledby="faq-headingEight" data-bs-parent="#faquestions">
         <div class="accordion-body">
           <p>
             Yes. If you upgrade, an automatic charge for the new subscription will be made, and you'll receive a refill of credits and access to any new features. This upgrade will also reset your billing cycle to the date the subscription was upgraded.
           </p>
           <p>
Upon downgrading your account, you won't be charged. We will instead bill you the new subscription price on your renewal date, which will remain the same as before. Until your plan is renewed at the end of the billing period, you won't lose access to any software or features on the plan you're currently subscribed to.
           </p>
         </div>
       </div>
     </div>
   </div>

   </div>
   </section>

               <section class="sections light-pink-2">
                 <div class="sectitle_01">
                   <h2>
                     Getting started is easy
                   </h2>
                 </div>

                 <div class="containers">
                   <div class="lgbtn-group">
                   <a href="#" class="exlarge-btn">
                     Free Product Tour
                   </a>
                   <a href="#" class="exlarge-btn exlarge-btn-line">
                  Talk to Concierge
                   </a>
                 </div>
               </div>
                 </section>
