<div class="hero-inner blogs">
  <div class="containers">
  <h1>
    <small>
       Blog
    </small>
    Knowledge leads to better action
  </h1>
 </div>
</div>

<div class="containers">
   <div class="floated-title centered">
       <h2>
           A sneak peek into topics that may interest you
       </h2>
     <p>
       Our team loves to share! Gain insights into what we have been working on and also read about the latest <br> news & updates that can help in improving your business
     </p>
     </div>
</div>
<section class="sections nptop">
 <!-- <div class="sectitle_01">
   <h2>
     Most popular topics
   </h2>
 </div> -->
 <div class="containers">
   <div class="bloglayout">
     <article>
       <figure class="titlepic">
         <img src="../../assets/img/b_main.jpg" alt="">
         <figcaption>
           02 November 2021
         </figcaption>
       </figure>
       <h2 class="blogtitles center">
        <a [routerLink]="['/blog/blog-content']">JTSi introduces OfficeGaps - Cloud based SaaS Business management solution</a>
       </h2>
       <p>
         We at JTSi are pleased to present to you OfficeGaps, our easy to configure Cloud based SaaS ERP Solution. It is an affordable and transformative back-office software solution for small and medium sized businesses, as well as large enterprises.
       </p><p>
         OfficeGaps is a subscription-based solution that supports multiple modules for managing, automating, and connecting key business processes. Also integrate all your processes and manage your resources efficiently with our API features.
       </p>
     </article>

     <aside>
      <app-bloglinks></app-bloglinks>

     </aside>
   </div>
 </div>
 </section>

 <div class="blognews">
   <picture>
     <!-- <img src="../../assets/img/newsletter_bg.jpg" alt=""> -->
   </picture>
   <form class="blogform_01" action="#">
     <h2>
       Keep up with our latest news and updates by subscribing to our newsletter
     </h2>
     <input type="email" placeholder="Your email address">
     <button class="darkbtn" type="submit">Subscribe newsletter</button>
   </form>
 </div>
 <section class="sections">
   <div class="containers">
     <div class="sectitle_01">
       <h3>
         Recent blog posts
       </h3>
   <h2>
     Recently added news
   </h2>
 </div>

 <div class="blogcards threecards">
   <div class="bigcard">
       <picture>
           <img src="../../assets/img/re_b1.jpg" alt="">
       </picture>
       <article>
           <p>02 November 2021</p>
           <h4>
           <a href="#">Signs you need a business management software</a>
           </h4>
           <p>
             Businesses are struggling to access and make sense of their data, there's a lot to keep track of.
           </p>
           <h5>
             <img src="../../assets/img/author1.jpg" alt="">
             By Winston Churchill
           </h5>
       </article>
   </div>
   <div class="bigcard">
     <picture>
         <img src="../../assets/img/re_b2.jpg" alt="">
     </picture>
     <article>
       <p>02 November 2021</p>
       <h4>
       <a href="#">Benefits of Cloud based SaaS solutions for your business</a>
       </h4>
       <p>
         Find out the advantages of cloud computing and its benefits you can bring to your business.
       </p>
       <h5>
         <img src="../../assets/img/author2.jpg" alt="">
         By Charles Dickens
       </h5>
   </article>
 </div>
   <div class="bigcard">
       <picture>
           <img src="../../assets/img/re_b3.jpg" alt="">
       </picture>
       <article>
         <p>02 November 2021</p>
         <h4>
         <a href="#">How to choose a Business Management Software?</a>
         </h4>
         <p>
           There are a lot of options out there, so choosing the right one for your company can be challenging.
         </p>
         <h5>
           <img src="../../assets/img/author3.jpg" alt="">
           By Agatha Christie
         </h5>
     </article>
   </div>
</div>

</div>
   </section>
