<div class="helcenterContent">
<h4>
  Basic
</h4>
<h5>Account configuration</h5>
<p>Configuration deals with handling of numerous usage controls, which can be switched off or switched on, so as to balance its functionalities to extant needs.</p>
<h5>Plans and pricing</h5>
<p>Access and manage your subscription plans, monitor bandwidth and user-based pricing. Upgrade your subscription and no. of users as an when required.</p>
<h5>Billing</h5>
<p>Learn how to access billing info and make payments, request refunds, etc.</p>
<h5>Set up your domain</h5>
<p>Learn how to register your website domain name with OfficeGaps.</p>
<h5>Admin Console</h5>
<p>Gather a basic understanding of what all of the applications and modules in the system do and how they integrate with each other.</p>
<h5>User Profiles and access rights</h5>
<p>Understand user accounts and permissions. Create and manage user accounts.</p>
<h5>Notification settings</h5>
<p>You can configure various notifications in your system to remind you of important activities.</p>
<h5>Data & Storage</h5>
<p>Manage your business data usage and storage capabilities.</p>
<h5>Customer Portal</h5>
<p>Check out the benefits of a customer portal and learn how you can create your own with OfficeGaps.</p>
<h4>
  Advanced
</h4>
<h5>Custom Fields</h5>
<p>Custom Fields allows you to add custom data to your posts and pages.</p>
<h5>Custom Modules</h5>
<p>Gives you the flexibility to create new features and/or tweak existing features to accommodate a business’ unique and growing aspirations.</p>
<h5>Forms</h5>
<p>Get started with our easy-to-use templates to build a unique set of configurable forms.</p>
<h5>Menu</h5>
<p>Easily pick and choose the items you wish to see on the menu. </p>
<h5>Pages</h5>
  <p>Build custom pages from scratch and configure page links and other settings.</p>
<h5>Tabs</h5>
<p>Create and add custom tabs to easily access business data and mark your favorites.</p>
<h5>Search settings</h5>
<p>Learn how to configure the search capabilities available inside OfficeGaps.</p>
<h5>User Groups</h5>
<p>Define groups of users and assign to each group the relevant set of authorizations.</p>
<h5>Client Profiles</h5>
<p>Setup unique personalized portals for your clients to enable easy and secure data acess.</p>
<h5>Tax Rates</h5>
<p>Enable organizations with centralized tax configuration, management, and reporting.</p>
<h5>Currencies</h5>
<p>Stay in control of your finances as you do business in multiple countries.</p>
<h5>Regions</h5>
<p>Create custom settings for multiple business locations.</p>
<h5>Pricing Lists</h5>
<p>Use price lists and price list items for defining price for a product. Create multiple price lists and set default price list for a product/service.</p>
<h5>Mandatory and Unique Fields</h5>
<p>System-wide mandatory and unique fields can be created for modules and individual workspaces, which must be filled in when saving data and other content.</p>
<h5>Text Box Control</h5>
<p>Create and define text box and its parameters.</p>
<h5>Text Area Control</h5>
<p>Setup text area control parameters.</p>
<h5>Radio Button</h5>
<p>Manage radio button settings and checkbox parameters.</p>
<h5>Custom PDF templates</h5>
<p>Personalize PDF templates to look exactly the way you want.</p>
<h4>
  Notifications
</h4>
<h5>Admin Announcements</h5>
<p>Configure admin announcements to circulate information quickly to a wide set of users at one go.</p>
<h5>To do List Notifications</h5>
<p>Setup notifications for ongoing and pending to do lists.</p>
<h5>Status change Notifications</h5>
<p>Configure Status change alerts for various process and tasks.</p>
<h5>Approval Notifications</h5>
<p>Users can receive approval requests as notifications.</p>
<h5>Email Notifications</h5>
<p>Configure notifications for email communications. Automatically stay up to date with any key changes in your business data.</p>
<h5>SMS notifications</h5>
<p>Automatically create and distribute SMS alerts.</p>
<h4>
  Files and Storage
</h4>
<h5>Image Capture and Upload</h5>
<p>Upload images to your database using your device camera or directly from device storage.</p>
<h5>Document capture </h5>
<p>Upload documents so that that you correlate documents with their relevant data to help find them easily.</p>
<h5>File grouping</h5>
<p>Allow bundling and consolidation of documents. together of the same business functionality.</p>
<h5>Document versioning</h5>
<p>Manage version history of your business files that allows you to track all the changes made.</p>
<h5>File Structure</h5>
<p>Defining a strong folder and file naming system creates good habits, reduces the time and effort required to manage your electronic records.</p>
<h5>Setup Digital Signatures</h5>
<p>Sign PDFs or other document quickly and securely by setting up your digital signature.</p>
<h4>
  Email
</h4>
<h5>Setup Email</h5>
<p>Learn how to configure your email client within OfficeGaps to send and receive emails.</p>
<h5>Schedule Automatic Email</h5>
<p>Schedule sales emails directly from OfficeGaps. · Reach your prospect's inbox at exactly the right time.</p>
<h5>Custom Email settings</h5>
<p>Customize the look and feel of emails that you send to end users.</p>
<h4>
  Import/Export
</h4>
<h5>Import data</h5>
<p>How-to instructions on how to import data into OfficeGaps and learn the essential actions before, during and after the data import.</p>
<h5>Import Contacts</h5>
<p>To import new contacts to existing customer records, please follow those steps.</p>
<h5>Export data</h5>
<p>Learn the steps for extracting and transferring the business data from OfficeGaps.</p>

</div>
