<section class="sections">
  <div class="containers">
    <div class="bottomnews">

      <div class="newstitle">

          <h2>
              Stay upto date with our latest news and updates.
          </h2>
          <p>
              We regularly publish in-depth articles about technology relevant to our industry, giving you an honest look at best business management practices.
          </p>
      </div>

      <div class="newslider">

          <div id="carouselExampleControls" class="carousel carousel-dark slide" data-bs-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="blogcards">
                      <div class="blogcard">
                          <picture>
                              <img src="../../assets/img/blog_1.jpg" alt="">
                          </picture>
                          <article>
                              <ul class="dates">
                                  <li>
                                      <i class="bi bi-person"></i> Admin
                                  </li>

                                  <li>
                                      <i class="bi bi-calendar3"></i> 22 Oct 2021
                                  </li>
                              </ul>
                              <p>
                                  JTSi Introducing <strong>OfficeGaps</strong> Cloud Based SaaS Solution
                              </p>
                              <a class="textlink-2" [routerLink]="['/blog']">Discover More »</a>
                          </article>
                      </div>

                      <div class="blogcard">
                          <picture>
                              <img src="../../assets/img/blog_2.jpg" alt="">
                          </picture>
                          <article>
                              <ul class="dates">
                                  <li>
                                      <i class="bi bi-person"></i> Admin
                                  </li>

                                  <li>
                                      <i class="bi bi-calendar3"></i> 22 Oct 2021
                                  </li>
                              </ul>
                              <p>
                                  A Guide to <strong>OfficeGaps</strong> software in Business Management
                              </p>
                              <a class="textlink-2" [routerLink]="['/blog']">Discover More »</a>
                          </article>
                      </div>
                  </div>
                </div>
                <div class="carousel-item">

                  <div class="blogcards">
                      <div class="blogcard">
                          <picture>
                              <img src="../../assets/img/blog_1.jpg" alt="">
                          </picture>
                          <article>
                              <ul class="dates">
                                  <li>
                                      <i class="bi bi-person"></i> Admin
                                  </li>

                                  <li>
                                      <i class="bi bi-calendar3"></i> 22 Oct 2021
                                  </li>
                              </ul>
                              <p>
                                  JTSi Introducing <strong>OfficeGaps</strong> Cloud Based SaaS Solution
                              </p>
                              <a class="textlink-2" [routerLink]="['/blog']">Discover More »</a>
                          </article>
                      </div>

                      <div class="blogcard">
                          <picture>
                              <img src="../../assets/img/blog_2.jpg" alt="">
                          </picture>
                          <article>
                              <ul class="dates">
                                  <li>
                                      <i class="bi bi-person"></i> Admin
                                  </li>

                                  <li>
                                      <i class="bi bi-calendar3"></i> 22 Oct 2021
                                  </li>
                              </ul>
                              <p>
                                  A Guide to <strong>OfficeGaps</strong> software in Business Management
                              </p>
                              <a class="textlink-2" [routerLink]="['/blog']">Discover More »</a>
                          </article>
                      </div>
                  </div>

                </div>

              </div>
              <div class="slidecontrols">
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
          </div>
            </div>

      </div>

    </div>
    </div>
</section>
